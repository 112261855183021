import { Tag } from '@flash-tecnologia/hros-web-ui-v2';
import { DocumentStatusEnum, documentStatusNameMap } from '../../constants';
import { ComponentProps } from 'react';

type DocumentStatusTagProps = {
  status: DocumentStatusEnum;
};

const documentStatusTagVariantMap = {
  [DocumentStatusEnum.ACTIVE]: 'success',
  [DocumentStatusEnum.EXPIRED]: 'gray',
  [DocumentStatusEnum.AWAITING_SIGNATURE]: 'error',
};

type TagVariants = ComponentProps<typeof Tag>['variant'];

export const DocumentStatusTag = ({ status }: DocumentStatusTagProps) => (
  <Tag as="span" variant={documentStatusTagVariantMap[status] as TagVariants}>
    {documentStatusNameMap[status]}
  </Tag>
);
