import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledCellContainer,
  StyledIconContainer,
  StyledTextContainer,
} from './styled';
import { useTheme } from 'styled-components';

type DocumentTypeCellProps = {
  name: string;
  description?: string;
  onClick: () => void;
};

export const DocumentTypeCell = ({
  name,
  description,
  onClick,
}: DocumentTypeCellProps) => {
  const theme = useTheme();
  return (
    <StyledCellContainer onClick={onClick}>
      <StyledIconContainer>
        <ShapeIcon
          style={{ cursor: 'pointer' }}
          name="IconFolder"
          variant="default"
          size={40}
          color={theme.colors.primary}
        />
      </StyledIconContainer>
      <StyledTextContainer>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[40]}
          weight={600}
        >
          {name}
        </Typography>
        {description && (
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral[60]}
            weight={400}
          >
            {description}
          </Typography>
        )}
      </StyledTextContainer>
    </StyledCellContainer>
  );
};
