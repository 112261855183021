import styled from 'styled-components';

export const StyledButton = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  padding: ${({ theme }) => theme.spacings.xs3}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs2};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.secondary[95] : 'none'};
`;
