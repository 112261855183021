import { Icons, Modal } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledAnimatedButton,
  StyledButton,
  StyledFooterContainer,
  StyledLinkButton,
  StyledSpace,
} from '../../styled';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

type FooterProps = {
  isActionsDisabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  showSubmitButton: boolean;
};

export const Footer = ({
  isActionsDisabled,
  onClose,
  onSubmit,
  loading,
  showSubmitButton,
}: FooterProps) => {
  const { trackEvent } = useEventTracking();
  return (
    <Modal.Footer>
      <StyledFooterContainer>
        {showSubmitButton && (
          <StyledLinkButton
            disabled={isActionsDisabled}
            variant="default"
            onClick={onClose}
          >
            Cancelar
          </StyledLinkButton>
        )}
        <StyledSpace />
        {showSubmitButton && (
          <StyledAnimatedButton
            animate={loading}
            disabled={isActionsDisabled}
            size="medium"
            variant="primary"
            onClick={() => {
              trackEvent({
                name: ActionTrackingEventEnum.DOCUMENT_SIGNATURE_MODAL_CTA_CLICKED,
              });
              onSubmit();
            }}
            loading={loading}
          >
            <Icons name="IconSignature" />
            Assinar documento
          </StyledAnimatedButton>
        )}
        {!showSubmitButton && (
          <StyledButton
            disabled={isActionsDisabled}
            size="medium"
            variant="primary"
            onClick={onClose}
            loading={loading}
          >
            Fechar
          </StyledButton>
        )}
      </StyledFooterContainer>
    </Modal.Footer>
  );
};
