import { Unstable } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledDrawer = styled(Unstable.Drawer)`
  &.MuiModal-root {
    div {
      &.MuiPaper-root {
        width: 45%;
        padding: 40px 40px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.xs};
`;
