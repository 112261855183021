import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledFooter, StyledLinkButtonWrapper } from './styled';
import { useNavigate } from 'react-router-dom';
import { useAddDocument } from '../../context/add-document-form-context';
import { useUploadDocument } from '../../../../hooks/useUploadDocument';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useMemo } from 'react';

export const Footer = () => {
  const { selectedCompany } = useSelectedCompany();
  const { validateFields, document, errors } = useAddDocument();
  const navigate = useNavigate();
  const { upload, loading } = useUploadDocument();

  const uploadDocument = async () => {
    const areFieldsValid = validateFields();

    if (!areFieldsValid) {
      return;
    }

    await upload({ ...document, companyId: selectedCompany.id });
  };

  const enableSending = useMemo(
    () => Object.values(errors).every((error) => error === undefined),
    [errors],
  );

  return (
    <StyledFooter>
      <StyledLinkButtonWrapper>
        <LinkButton variant="neutral" onClick={() => navigate(-1)}>
          Sair sem salvar
        </LinkButton>
      </StyledLinkButtonWrapper>
      <Button
        onClick={() => uploadDocument()}
        size="large"
        variant="primary"
        variantType="default"
        disabled={!enableSending || loading}
        loading={loading}
      >
        {loading ? 'Enviando documento...' : 'Enviar documento'}{' '}
        <Icons name="IconArrowRight" />
      </Button>
    </StyledFooter>
  );
};
