import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledContainer } from './styled';

import { useTableQueryParams } from '../../../../hooks/useTableQueryParams';
import { DocumentOwnerEnum } from '../../../../constants';
import { useEffect } from 'react';
import { DocumentTableQueryParams } from 'src/components/DocumentTable/context/DocumentTableContext';
import { DocumentTable } from 'src/components/DocumentTable';
import { useParams } from 'react-router-dom';

export const Table = () => {
  const { employeeId } = useParams();
  const { tableQueryParams, setTableQueryParams } =
    useTableQueryParams<DocumentTableQueryParams>();

  useEffect(() => {
    if (!tableQueryParams.tab) setTableQueryParams({ tab: '0' });
  });

  useEffect(() => {
    if (tableQueryParams.tab === '0')
      setTableQueryParams({
        documentOwners: DocumentOwnerEnum.EMPLOYEE,
        signerEmployeeIds: undefined,
      });
    if (tableQueryParams.tab === '1')
      setTableQueryParams({
        documentOwners: DocumentOwnerEnum.DEPENDENT,
        signerEmployeeIds: undefined,
      });
    if (tableQueryParams.tab === '2')
      setTableQueryParams({
        documentOwners: undefined,
        signerEmployeeIds: `${employeeId}`,
      });
  }, [tableQueryParams.tab]);

  return (
    <StyledContainer>
      <Tab
        onTabChanged={(idx) => setTableQueryParams({ tab: `${idx}` })}
        selected={tableQueryParams.tab ? Number(tableQueryParams.tab) : 0}
        tabItens={[
          { label: 'Documentos da pessoa', component: <></> },
          { label: 'Documentos de dependentes', component: <></> },
          { label: 'Assinaturas', component: <></> },
        ]}
      />
      <DocumentTable />
    </StyledContainer>
  );
};
