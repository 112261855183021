import {
  Button,
  Icons,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ContentCard } from 'src/pages/AddMultipleDocumentsPage/components/ContentCard';
import { useTheme } from 'styled-components';
import { FileList } from '../../../FileList';
import {
  StyledButtonContainer,
  StyledContentContainer,
  StyledDescriptionTypography,
  StyledFileListContainer,
  StyledHeaderContainer,
  StyledHorizontalLine,
} from './styled';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';
import { useEffect } from 'react';
import { showToast } from 'src/utils/showToast';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const MainContent = () => {
  const theme = useTheme();
  const { resetSteps, documentList, validUniqueDocumentNumbers } =
    useMultipleDocumentUploadContext();
  const { trackEvent } = useEventTracking();

  const documentsSuccessfullyUploaded = documentList.filter(
    ({ progress, errorMessage }) => progress === 100 && !errorMessage,
  );

  const documentsNotUploaded = documentList.filter(
    ({ progress, errorMessage }) => progress === 100 && !!errorMessage,
  );

  const uniqueSuccessfullyUploadedDocumentNumbers = [
    ...new Set(
      documentsSuccessfullyUploaded.map(({ documentNumber }) => documentNumber),
    ),
  ];

  const toastPrefix = documentsSuccessfullyUploaded.length
    ? `${documentsSuccessfullyUploaded.length} documentos enviados e associados a ${uniqueSuccessfullyUploadedDocumentNumbers.length} pessoas com sucesso, mas `
    : '';

  useEffect(() => {
    showToast({
      type: 'error',
      title: 'Envio finalizado com erros',
      description: `${toastPrefix}${documentsNotUploaded.length} documentos não foram associados a nenhuma pessoa.`,
    });
  }, []);

  const downloadErrorFile = () => {
    const errorFileContent = documentsNotUploaded
      .map(({ file, errorMessage }) => `${file.name}   ${errorMessage}\n`)
      .join('');
    const errorFile = new Blob([errorFileContent], { type: 'text/plain' });
    const errorFileUrl = URL.createObjectURL(errorFile);
    const link = document.createElement('a');
    link.download = 'Lista-de-erros.txt';
    link.href = errorFileUrl;
    link.click();
    link.remove();
  };

  return (
    <ContentCard>
      <StyledContentContainer>
        <StyledHeaderContainer>
          <ShapeIcon
            variant="error"
            name="IconAlertTriangle"
            color={theme.colors.feedback.error[50]}
            size={48}
          />
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
            weight={700}
          >
            Envio finalizado com erros
          </Typography>
          <StyledDescriptionTypography
            variant="body4"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            <Icons name="IconPhotoOff" size={16} />{' '}
            {documentsNotUploaded.length} documentos não foram associados a
            nenhuma pessoa.
          </StyledDescriptionTypography>
          <StyledDescriptionTypography
            variant="body4"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            Confira a lista de erros no arquivo abaixo e envie novamente os
            documentos necessários.
          </StyledDescriptionTypography>
          <StyledButtonContainer>
            <Button
              onClick={() => {
                trackEvent({
                  name: ActionTrackingEventEnum.DOWNLOAD_MULTIPLE_DOCUMENT_UPLOAD_ERROR_FILE_BUTTON_CLICKED,
                });
                downloadErrorFile();
              }}
              size="large"
              variant="secondary"
              variantType="default"
            >
              Lista-de-erros.txt <Icons name="IconDownload" />
            </Button>
            <Button
              onClick={() => {
                trackEvent({
                  name: ActionTrackingEventEnum.RESET_MULTIPLE_DOCUMENT_FLOW_BUTTON_CLICKED,
                });
                resetSteps();
              }}
              size="large"
              variant="primary"
              variantType="default"
            >
              Novo envio de documentos <Icons name="IconPlus" />
            </Button>
          </StyledButtonContainer>
        </StyledHeaderContainer>

        <StyledHorizontalLine />

        <StyledFileListContainer>
          {!!documentsSuccessfullyUploaded.length && (
            <StyledDescriptionTypography
              variant="body4"
              variantColor={theme.colors.neutral[50]}
              weight={400}
            >
              <Icons name="IconPhotoPlus" size={16} />{' '}
              {documentsSuccessfullyUploaded.length} documentos enviados e
              associados a {validUniqueDocumentNumbers.length} pessoas com
              sucesso.
            </StyledDescriptionTypography>
          )}
          <FileList />
        </StyledFileListContainer>
      </StyledContentContainer>
    </ContentCard>
  );
};
