import {
  Button,
  Icons,
  Menu,
  MenuOption,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledButtonSection,
  StyledContainer,
  StyledTitleSection,
} from './styled';
import { useNavigate } from 'react-router-dom';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { trackEvent } = useEventTracking();
  return (
    <StyledContainer>
      <StyledTitleSection>
        <Typography
          variant="headline6"
          variantColor={theme.colors.neutral[20]}
          weight={700}
        >
          Gestão de documentos
        </Typography>
      </StyledTitleSection>
      <StyledButtonSection>
        <Menu
          type="default"
          options={[
            {
              selected: false,
              children: (
                <MenuOption
                  label="Adicionar apenas um documento"
                  icon="IconPhotoPlus"
                  onClick={() => {
                    trackEvent({
                      name: ActionTrackingEventEnum.GO_TO_ADD_DOCUMENT_PAGE_BUTTON_CLICKED,
                    });
                    navigate('/document-management/add-document');
                  }}
                />
              ),
            },
            {
              selected: false,
              children: (
                <MenuOption
                  label="Adicionar vários documentos"
                  icon="IconBoxMultiple"
                  onClick={() => {
                    trackEvent({
                      name: ActionTrackingEventEnum.GO_TO_ADD_MULTIPLE_DOCUMENTS_PAGE_BUTTON_CLICKED,
                    });
                    navigate('/document-management/add-multiple-documents');
                  }}
                />
              ),
            },
          ]}
        >
          <Button
            variant="primary"
            size="large"
            data-pendo="document-management-add-document"
          >
            <Icons name="IconPlus" fill="transparent" />
            Adicionar documento
          </Button>
        </Menu>
      </StyledButtonSection>
    </StyledContainer>
  );
};
