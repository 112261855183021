import {
  Modal,
  PDFViewer,
  Skeleton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledContentContainer,
  StyledLine,
  StyledModalContent,
  StyledTextContainer,
} from './styled';
import { trpc } from '../../../../../../api/client';
import { showToast } from 'src/utils/showToast';
import { DocumentImageDisplay } from '../../../../../DocumentImageDisplay';
import { DocumentInformationSection } from './components/DocumentInformationSection';
import { useDocumentTableContext } from '../../../../context/DocumentTableContext';
import { DocumentContentTypeEnum, DocumentStatusEnum } from 'src/constants';
import { DocumentAlreadySignedAlert } from './components/DocumentAlreadySignedAlert';
import { Footer } from './components/Footer';
import { useMemo } from 'react';
import { getAccessTokenPayloadSync } from '@flash-tecnologia/hros-web-utility';

type DocumentSignatureModalProps = {
  onClose: () => void;
  onSuccess: () => void;
};

export const DocumentSignatureModal = ({
  onClose,
  onSuccess,
}: DocumentSignatureModalProps) => {
  const theme = useTheme();
  const { employeeId: userEmployeeId } = getAccessTokenPayloadSync();
  const {
    selectedDocumentId,
    isFindDocumentByIdLoading,
    findDocumentByIdData,
  } = useDocumentTableContext();

  const { mutate: signDocument, isLoading: isSignDocumentLoading } =
    trpc.signDocument.useMutation({
      onError: () => {
        showToast({
          type: 'error',
          title: 'Não foi possível assinar o documento!',
          description:
            'Ocorreu um erro ao assinar o documento. Por favor, tente novamente.',
        });
      },
      onSuccess: () => {
        showToast({
          type: 'success',
          title: 'Documento assinado com sucesso!',
        });
        onClose();
        onSuccess();
      },
    });

  const signature = useMemo(() => {
    return findDocumentByIdData?.signers.find(
      ({ employeeId: signerEmployeeId }) => signerEmployeeId === userEmployeeId,
    )?.signature;
  }, [userEmployeeId, findDocumentByIdData]);

  const isActionsDisabled =
    isFindDocumentByIdLoading || isSignDocumentLoading || !findDocumentByIdData;

  return (
    <Modal.Root open={true} onClose={onClose} size="md">
      <>
        <StyledModalContent>
          <StyledContentContainer>
            <StyledTextContainer>
              <Typography
                variant="headline8"
                weight={700}
                variantColor={theme.colors.neutral[10]}
              >
                Assinar documento
              </Typography>
              <Typography
                variant="body4"
                weight={400}
                variantColor={theme.colors.neutral[40]}
              >
                Por favor, assine o documento, verificando a sua autenticidade,
                clicando no botão de assinatura abaixo.
              </Typography>
            </StyledTextContainer>
            {findDocumentByIdData && signature?.signedAt && (
              <DocumentAlreadySignedAlert
                signatureDate={signature?.signedAt}
                employeeId={findDocumentByIdData.employeeId}
              />
            )}
            {findDocumentByIdData && !isFindDocumentByIdLoading ? (
              <>
                {findDocumentByIdData.contentType ===
                DocumentContentTypeEnum.PDF ? (
                  <PDFViewer
                    width="100%"
                    height="350px"
                    src={findDocumentByIdData.documentFileSignedUrl}
                    options={{
                      showToolbar: true,
                      fit: 'height',
                    }}
                  />
                ) : (
                  <DocumentImageDisplay
                    fileSignedUrl={findDocumentByIdData.documentFileSignedUrl}
                  />
                )}
                <StyledLine />
                <DocumentInformationSection />
              </>
            ) : (
              <>
                <Skeleton height={200} width="100%" />
                <Skeleton height={150} width="100%" />
              </>
            )}
          </StyledContentContainer>
        </StyledModalContent>
        <Footer
          showSubmitButton={
            !signature && !!findDocumentByIdData?.permissions.sign
          }
          isActionsDisabled={isActionsDisabled}
          onClose={onClose}
          onSubmit={() => {
            if (findDocumentByIdData?.status === DocumentStatusEnum.EXPIRED) {
              showToast({
                type: 'error',
                title: 'Não foi possível assinar o documento!',
                description: 'O documento está vencido.',
              });
              return;
            }
            signDocument({ documentId: selectedDocumentId! });
          }}
          loading={isSignDocumentLoading}
        />
      </>
    </Modal.Root>
  );
};
