import { Tag } from '@flash-tecnologia/hros-web-ui-v2';

import { ComponentProps } from 'react';
import {
  EmployeeStatusEnum,
  employeeStatusNameMap,
  employeeStatusTagVariantMap,
} from '../../constants';

type EmployeeStatusTagProps = {
  status: EmployeeStatusEnum;
  hasLeftDotIcon?: boolean;
};

type TagVariants = ComponentProps<typeof Tag>['variant'];

export const EmployeeStatusTag = ({
  status,
  hasLeftDotIcon,
}: EmployeeStatusTagProps) => (
  <Tag
    disabled={false}
    as="span"
    hasLeftDotIcon={hasLeftDotIcon}
    variant={employeeStatusTagVariantMap[status] as TagVariants}
  >
    {employeeStatusNameMap[status]}
  </Tag>
);
