import {
  IconButton,
  Menu,
  MenuOption,
  Tag,
  Tooltip,
  Typography,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledCardContainer,
  StyledCardHeaderContainer,
  StyledCardHeaderContainerLeft,
  StyledCardHeaderContainerRight,
  StyledCardInformationContainer,
  StyledDocumentOwnerContainer,
  StyledDocumentSignersContainer,
  StyledDocumentTagContainer,
  StyledFileName,
  StyledIcon,
} from './styled';

import { useTheme } from 'styled-components';
import {
  ActionTrackingEventEnum,
  DocumentOwnerEnum,
} from '../../../../../../../../constants';
import { EmployeeIdentityDisplay } from 'src/components/EmployeeIdentityDisplay';
import { DocumentStatusTag } from 'src/components/DocumentStatusTag';
import { CaptionField } from 'src/components/LabeledField';
import { formatCompetenceDate } from 'src/utils/formatCompetenceDate';
import {
  DocumentActionEnum,
  useDocumentTableContext,
} from 'src/components/DocumentTable/context/DocumentTableContext';
import { DeleteDocumentConfirmationModal } from '../../../DeleteDocumentConfirmationModal';
import { RequestDocumentSignatureModal } from '../../../RequestDocumentSignatureModal';
import { DocumentSignatureModal } from '../../../DocumentSignatureModal';
import { useState } from 'react';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { SignerDisplay } from './components/SignerDisplay';

const documentOwnerTextMap = {
  [DocumentOwnerEnum.DEPENDENT]: 'de dependente',
  [DocumentOwnerEnum.EMPLOYEE]: 'do titular',
};

type FormattedField = {
  label: string;
  value: string;
};

export const DocumentInformationCard = () => {
  const theme = useTheme();

  const {
    findDocumentByIdData,
    checkDocumentActionPermission,
    downloadDocument,
    selectedCompany,
    refetchDocuments,
    refetchFindDocumentById,
    setIsDocumentDrawerVisible,
    refetchGetBigNumbers,
  } = useDocumentTableContext();

  const [
    isDeleteDocumentConfirmationModalVisible,
    setIsDeleteDocumentConfirmationModalVisible,
  ] = useState(false);

  const [
    isRequestDocumentSignatureModalVisible,
    setIsRequestDocumentSignatureModalVisible,
  ] = useState(false);

  const [isSignDocumentModalVisible, setIsSignDocumentModalVisible] =
    useState(false);

  const { trackEvent } = useEventTracking();

  if (!findDocumentByIdData) return <></>;

  const {
    id: documentId,
    expirationDate,
    createdAt,
    competenceDate,
    updatedAt,
    observations,
    uploadedByEmployeeData,
    status,
    documentOwner,
    fileName,
    documentTypeName,
    employeeId,
    signers,
  } = findDocumentByIdData;

  const canRequestDocumentSignature = checkDocumentActionPermission(
    findDocumentByIdData,
    DocumentActionEnum.REQUEST_DOCUMENT_SIGNATURE,
  );

  const canSignDocument = checkDocumentActionPermission(
    findDocumentByIdData,
    DocumentActionEnum.SIGN_DOCUMENT,
  );

  const canDeleteDocument = checkDocumentActionPermission(
    findDocumentByIdData,
    DocumentActionEnum.DELETE_DOCUMENT,
  );

  const formatDate = (dateString: string) =>
    dayjs(dateString).format('DD/MM/YYYY');

  const formattedFields = [
    {
      label: 'Data de vencimento',
      value: expirationDate ? formatDate(expirationDate) : undefined,
    },
    {
      label: 'Competência do arquivo',
      value: competenceDate ? formatCompetenceDate(competenceDate) : undefined,
    },
    {
      label: 'Data de criação',
      value: createdAt ? formatDate(createdAt) : undefined,
    },
    {
      label: 'Data de modificação',
      value: updatedAt ? formatDate(updatedAt) : undefined,
    },
    {
      label: 'Observações',
      value: observations,
    },
    ...(signers.length
      ? [
          {
            label: 'Assinaturas no documento',
            value: (
              <StyledDocumentSignersContainer>
                {signers.map(
                  (
                    {
                      employeeId,
                      name,
                      profilePictureSignedUrl,
                      signature,
                      signatureRequestedAt,
                    },
                    idx,
                  ) => (
                    <SignerDisplay
                      key={idx}
                      employeeId={employeeId}
                      name={name}
                      requestedAt={signatureRequestedAt}
                      profilePictureSignedUrl={profilePictureSignedUrl}
                      signature={signature}
                    />
                  ),
                )}
              </StyledDocumentSignersContainer>
            ),
          },
        ]
      : []),
    ...(uploadedByEmployeeData
      ? [
          {
            label: 'Enviado por',
            value: (
              <EmployeeIdentityDisplay
                name={uploadedByEmployeeData.name}
                email={uploadedByEmployeeData.email}
                profilePictureSignedUrl={
                  uploadedByEmployeeData.profilePictureSignedUrl
                }
              />
            ),
          },
        ]
      : []),
  ].filter(({ value }) => value) as FormattedField[];

  return (
    <>
      <StyledCardContainer>
        <StyledCardHeaderContainer>
          <StyledCardHeaderContainerLeft>
            <StyledDocumentTagContainer>
              <DocumentStatusTag status={status} />
              <Tag variant="primary" as="span">
                {documentTypeName}
              </Tag>
            </StyledDocumentTagContainer>
            <StyledFileName variant="headline7" weight={700}>
              <Tooltip title={fileName} arrow={false}>
                <span>{fileName}</span>
              </Tooltip>
            </StyledFileName>
            <StyledDocumentOwnerContainer>
              <StyledIcon name="IconFolder" />
              <Typography
                variant="body4"
                weight={600}
                variantColor={theme.colors.neutral[40]}
              >
                Documento {documentOwnerTextMap[documentOwner]}
              </Typography>
            </StyledDocumentOwnerContainer>
          </StyledCardHeaderContainerLeft>
          <StyledCardHeaderContainerRight>
            <Menu
              type="default"
              options={[
                {
                  selected: false,
                  children: (
                    <MenuOption
                      label="Baixar documento"
                      icon="IconFileZip"
                      onClick={() => {
                        trackEvent({
                          name: ActionTrackingEventEnum.DRAWER_DOWNLOAD_DOCUMENT_CLICKED,
                        });
                        downloadDocument({
                          companyId: selectedCompany.id,
                          documentId,
                        });
                      }}
                    />
                  ),
                },
                ...(canRequestDocumentSignature
                  ? [
                      {
                        selected: false,
                        children: (
                          <MenuOption
                            label="Solicitar assinatura"
                            icon="IconSignature"
                            onClick={() => {
                              trackEvent({
                                name: ActionTrackingEventEnum.DRAWER_REQUEST_DOCUMENT_SIGNATURE_CLICKED,
                              });
                              setIsRequestDocumentSignatureModalVisible(true);
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                ...(canSignDocument
                  ? [
                      {
                        selected: false,
                        children: (
                          <MenuOption
                            label="Assinar o documento"
                            icon="IconSignature"
                            onClick={() => {
                              trackEvent({
                                name: ActionTrackingEventEnum.DRAWER_SIGN_DOCUMENT_BUTTON_CLICKED,
                              });
                              setIsSignDocumentModalVisible(true);
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                ...(canDeleteDocument
                  ? [
                      {
                        selected: false,
                        children: (
                          <MenuOption
                            label="Excluir"
                            icon="IconTrash"
                            onClick={() => {
                              trackEvent({
                                name: ActionTrackingEventEnum.DRAWER_DELETE_DOCUMENT_CLICKED,
                              });
                              setIsDeleteDocumentConfirmationModalVisible(true);
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
              ]}
            >
              <IconButton size="large" variant="line" icon="IconDotsVertical" />
            </Menu>
          </StyledCardHeaderContainerRight>
        </StyledCardHeaderContainer>

        <StyledCardInformationContainer>
          <Typography
            variant="headline8"
            weight={700}
            variantColor={theme.colors.neutral[30]}
          >
            Informações do documento
          </Typography>
          {formattedFields.map(({ label, value }, idx) => (
            <CaptionField label={label} value={value} key={idx} />
          ))}
        </StyledCardInformationContainer>
      </StyledCardContainer>

      {isDeleteDocumentConfirmationModalVisible && (
        <DeleteDocumentConfirmationModal
          onSuccess={() => {
            refetchDocuments();
            refetchGetBigNumbers();
            setIsDeleteDocumentConfirmationModalVisible(false);
            setIsDocumentDrawerVisible(false);
          }}
          onClose={() => {
            setIsDeleteDocumentConfirmationModalVisible(false);
          }}
        />
      )}
      {isRequestDocumentSignatureModalVisible && (
        <RequestDocumentSignatureModal
          onSuccess={() => {
            refetchDocuments();
            refetchGetBigNumbers();
            refetchFindDocumentById();
            setIsRequestDocumentSignatureModalVisible(false);
          }}
          employeeId={employeeId}
          onClose={() => {
            setIsRequestDocumentSignatureModalVisible(false);
          }}
        />
      )}
      {isSignDocumentModalVisible && (
        <DocumentSignatureModal
          onSuccess={() => {
            refetchDocuments();
            refetchGetBigNumbers();
            refetchFindDocumentById();
            setIsSignDocumentModalVisible(false);
          }}
          onClose={() => {
            setIsSignDocumentModalVisible(false);
          }}
        />
      )}
    </>
  );
};
