import { StyledContainer, StyledDrawer } from './styled';
import { DocumentInformationCard } from './components/DocumentInformationCard';
import { Skeleton } from '@mui/material';
import { DocumentFileDisplay } from './components/DocumentFileDisplay';
import { DocumentSignatureWarningCard } from './components/DocumentSignatureWarningCard';
import {
  DocumentActionEnum,
  useDocumentTableContext,
} from 'src/components/DocumentTable/context/DocumentTableContext';

type DrawerProps = {
  onClose: () => void;
};

export const DocumentDrawer = ({ onClose }: DrawerProps) => {
  const {
    findDocumentByIdData,
    isFindDocumentByIdLoading,
    checkDocumentActionPermission,
  } = useDocumentTableContext();

  return (
    <StyledDrawer
      open={true}
      title={'Visualizar documento'}
      onClose={onClose}
      anchor="right"
    >
      <StyledContainer>
        {!findDocumentByIdData || isFindDocumentByIdLoading ? (
          <>
            <Skeleton height={'200px'} />
            <Skeleton height={'500px'} />
          </>
        ) : (
          <>
            {checkDocumentActionPermission(
              findDocumentByIdData,
              DocumentActionEnum.SIGN_DOCUMENT,
            ) && <DocumentSignatureWarningCard />}
            <DocumentFileDisplay />
            <DocumentInformationCard />
          </>
        )}
      </StyledContainer>
    </StyledDrawer>
  );
};
