import { TagFilter } from '@flash-tecnologia/hros-web-ui-v2';
import { useTableQueryParams } from '../../../../../../../../../hooks/useTableQueryParams';
import { EmployeeTableQueryParams } from '../../..';
import {
  ActionTrackingEventEnum,
  EmployeeStatusEnum,
  employeeStatusNameMap,
} from '../../../../../../../../../constants';
import { useEventTracking } from 'src/hooks/useEventTracking';

export const EmployeeStatusFilter = () => {
  const { tableQueryParams, setTableQueryParams } =
    useTableQueryParams<EmployeeTableQueryParams>();
  const { trackEvent } = useEventTracking();

  const employeeStatuses = tableQueryParams.employeeStatuses?.split(',');

  return (
    <TagFilter
      variant="secondary"
      filterLabel="Status da pessoa"
      hasLeftIcon={false}
      options={Object.values(EmployeeStatusEnum).map((status) => ({
        label: employeeStatusNameMap[status],
        value: status,
      }))}
      onApply={(values) => {
        const value = values.length ? values.join(',') : undefined;
        if (value) {
          trackEvent({
            name: ActionTrackingEventEnum.TABLE_FILTER_USED,
            params: {
              filterName: 'Status da pessoa',
              value,
            },
          });
        }
        setTableQueryParams({
          employeeStatuses: value,
        });
      }}
      onClear={() => setTableQueryParams({ employeeStatuses: undefined })}
      selectedOptions={employeeStatuses}
      badgeNumber={employeeStatuses?.length}
      optionIconType="checkbox"
    />
  );
};
