import {
  Button,
  Icons,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledContentContainer,
  StyledFooterContainer,
  StyledLinkButton,
} from './styled';
import { trpc } from '../../../../../../api/client';
import { showToast } from 'src/utils/showToast';
import { useDocumentTableContext } from '../../../../context/DocumentTableContext';

type ActionConfirmationModalProps = {
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteDocumentConfirmationModal = ({
  onClose,
  onSuccess,
}: ActionConfirmationModalProps) => {
  const theme = useTheme();
  const { selectedCompany, selectedDocumentId } = useDocumentTableContext();

  const { mutate: deleteDocument, isLoading: isDeleteDocumentLoading } =
    trpc.deleteDocument.useMutation({
      onError: () => {
        showToast({
          type: 'error',
          title: 'Não foi possível excluir o documento!',
          description:
            'Ocorreu um erro ao excluir o documento. Por favor, tente novamente.',
        });
      },
      onSuccess: () => {
        showToast({
          type: 'success',
          title: 'Documento excluído com sucesso!',
        });
        onClose();
        onSuccess();
      },
    });

  return (
    <Modal.Root open={true} onClose={onClose}>
      <>
        <Modal.Content>
          <StyledContentContainer>
            <ShapeIcon
              style={{ marginBottom: theme.spacings.xs2 }}
              variant={'error'}
              size={72}
              color={theme.colors.feedback.error[40]}
              name={'IconAlertCircle'}
            />
            <Typography
              style={{ marginBottom: theme.spacings.xs5 }}
              variant="body3"
              weight={700}
              variantColor={theme.colors.feedback.error[40]}
            >
              Atenção
            </Typography>
            <Typography
              style={{ marginBottom: theme.spacings.xs2 }}
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              Tem certeza que deseja excluir este documento?
            </Typography>
            <Typography
              variant="body3"
              weight={400}
              variantColor={theme.colors.neutral[50]}
            >
              Esta ação afetará o cadastro desse pessoa em todos os módulos que
              este documento aparece. Você não poderá desfazer esta ação.
            </Typography>
          </StyledContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <StyledFooterContainer>
            <StyledLinkButton
              disabled={isDeleteDocumentLoading}
              variant="neutral"
              onClick={onClose}
            >
              Cancelar
            </StyledLinkButton>
            <Button
              size="medium"
              variant="primary"
              variantType={'error'}
              loading={isDeleteDocumentLoading}
              onClick={() =>
                deleteDocument({
                  companyId: selectedCompany.id,
                  documentId: selectedDocumentId!,
                })
              }
            >
              Confirmar exclusão{' '}
              <Icons
                color={theme.colors.feedback.error[40]}
                name={'IconTrash'}
              />
            </Button>
          </StyledFooterContainer>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
