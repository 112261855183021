import {
  uploadFileToS3,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { trpc } from 'src/api/client';
import { DocumentOwnerEnum } from 'src/constants';
import BluebirdPromise from 'bluebird';
import { isValidCPF } from 'src/utils/isValidCPF';
import {
  checkIfPdfIsPasswordEncrypted,
  PDF_FILE_EXTENSION,
} from 'src/components/Dropzone/utils';

BluebirdPromise.config({ cancellation: true });

type DocumentInfoState = {
  canBeSigned: boolean;
  documentTypeId: string | undefined;
  documentOwner: DocumentOwnerEnum | undefined;
};

type DropzoneDocument = {
  id: string;
  file: File;
  progress: number;
  documentNumber: string;
  errorMessage?: string;
  successMessage?: string;
};

export enum UploadMultipleDocumentsStepEnum {
  INPUT = 'INPUT',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const sleepForMs = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

const useMultipleDocumentUploadContextValue = () => {
  const { selectedCompany } = useSelectedCompany();
  const [step, setStep] = useState<UploadMultipleDocumentsStepEnum>(
    UploadMultipleDocumentsStepEnum.INPUT,
  );
  const [documentInfo, setDocumentInfo] = useState<DocumentInfoState>({
    canBeSigned: false,
    documentTypeId: undefined,
    documentOwner: undefined,
  });
  const [documents, setDocuments] = useState<Record<string, DropzoneDocument>>(
    {},
  );
  const [inputValidationErrors, setInputValidationErrors] = useState<{
    documentOwner?: string;
    documentTypeId?: string;
    documents?: string;
  }>({});

  const documentList = useMemo(() => Object.values(documents), [documents]);

  const validateInput = () => {
    const validationErrors: {
      documentOwner?: string;
      documentTypeId?: string;
      documents?: string;
    } = {};
    if (!documentInfo.documentOwner)
      validationErrors.documentOwner = 'Titularidade é obrigatória';
    if (!documentInfo.documentTypeId)
      validationErrors.documentTypeId = 'Tipo do documento é obrigatório';
    if (!documentList.length)
      validationErrors.documents = 'Arquivos são obrigatórios';

    setInputValidationErrors(validationErrors);

    return Object.values(validationErrors).every(
      (errorMessage) => !errorMessage,
    );
  };

  useEffect(() => {
    setInputValidationErrors({});
  }, [documentInfo]);

  const validUniqueDocumentNumbers = useMemo(
    () => [
      ...new Set(
        documentList
          .filter(({ documentNumber }) => isValidCPF(documentNumber))
          .map(({ documentNumber }) => documentNumber),
      ),
    ],
    [documentList],
  );

  const uploadPromiseRef = useRef<undefined | BluebirdPromise<any>>(undefined);

  const [
    isCancelUploadConfirmationModalVisible,
    setIsCancelUploadConfirmationModalVisible,
  ] = useState(false);

  const { mutateAsync: createDocument } = trpc.createDocument.useMutation();
  const { mutateAsync: validateDocument } = trpc.validateDocument.useMutation();
  const { data: findEmployeesByDocumentNumbersData } =
    trpc.findEmployeesByDocumentNumbers.useQuery(
      {
        documentNumbers: validUniqueDocumentNumbers,
        companyId: selectedCompany.id,
      },
      { refetchOnWindowFocus: false, cacheTime: 0 },
    );

  const employeeInfoMap = useMemo<Record<string, { id: string; name: string }>>(
    () =>
      findEmployeesByDocumentNumbersData
        ? findEmployeesByDocumentNumbersData.reduce(
            (acc, { documentNumber, id, name }) => ({
              ...acc,
              [documentNumber]: {
                id,
                name,
              },
            }),
            {},
          )
        : {},
    [findEmployeesByDocumentNumbersData],
  );

  const getRandomNumber = (min: number, max: number) =>
    Math.round(Math.random() * (max - min) + min);

  const updateDocument = (updatedDocument: DropzoneDocument) =>
    setDocuments((prev) => ({
      ...prev,
      [updatedDocument.id]: updatedDocument,
    }));

  const createDocumentFlow = async (document: DropzoneDocument) => {
    try {
      const relatedEmployee = employeeInfoMap[document.documentNumber];
      const isPdf = document.file.name.split('.').at(-1) === PDF_FILE_EXTENSION;
      const isPasswordEncryptedPdf =
        isPdf && (await checkIfPdfIsPasswordEncrypted(document.file));

      if (isPasswordEncryptedPdf) {
        updateDocument({
          ...document,
          progress: 100,
          errorMessage: `PDF possui senha`,
        });
        await sleepForMs(500);
        return;
      }

      if (!relatedEmployee) {
        updateDocument({
          ...document,
          progress: 100,
          errorMessage: `Nenhum colaborador encontrado com o CPF informado no nome do arquivo`,
        });
        await sleepForMs(500);
        return;
      }

      updateDocument({
        ...document,
        progress: getRandomNumber(15, 25),
      });

      const { url, documentId } = await createDocument({
        signerEmployeeIds: [relatedEmployee.id],
        documentOwner: documentInfo.documentOwner!,
        documentTypeId: documentInfo.documentTypeId!,
        companyId: selectedCompany.id,
        employeeId: relatedEmployee.id,
        fileName: document.file.name,
      });

      updateDocument({
        ...document,
        progress: getRandomNumber(25, 50),
      });

      await uploadFileToS3({
        file: document.file,
        url,
      });

      updateDocument({
        ...document,
        progress: getRandomNumber(50, 75),
      });

      await validateDocument({ documentId });

      updateDocument({
        ...document,
        progress: 100,
        successMessage: `Vinculado com sucesso à ${relatedEmployee.name}`,
      });
    } catch (error) {
      updateDocument({
        ...document,
        progress: 100,
        errorMessage: 'Erro ao criar o documento',
      });
    }
  };

  const startUpload = async () => {
    if (!validateInput()) return;

    setStep(UploadMultipleDocumentsStepEnum.PROCESSING);

    uploadPromiseRef.current = BluebirdPromise.map(
      [...documentList].reverse(),
      createDocumentFlow,
      {
        concurrency: 2,
      },
    );

    await uploadPromiseRef.current;
  };

  useEffect(() => {
    const finishedProcessing =
      documentList.length &&
      documentList.every(({ progress }) => progress === 100);
    if (finishedProcessing) {
      const hasErrors = documentList.some(({ errorMessage }) => !!errorMessage);
      if (hasErrors) {
        setStep(UploadMultipleDocumentsStepEnum.ERROR);
      } else {
        setStep(UploadMultipleDocumentsStepEnum.SUCCESS);
      }
    }
  }, [documentList]);

  const cancelUpload = () => {
    if (uploadPromiseRef.current) uploadPromiseRef.current.cancel();
  };

  const resetSteps = () => {
    uploadPromiseRef.current = undefined;
    setDocuments({});
    setDocumentInfo({
      canBeSigned: false,
      documentOwner: undefined,
      documentTypeId: undefined,
    });
    setStep(UploadMultipleDocumentsStepEnum.INPUT);
  };

  return {
    documentInfo,
    setDocumentInfo,
    setDocuments,
    documentList,
    step,
    startUpload,
    cancelUpload,
    setStep,
    isCancelUploadConfirmationModalVisible,
    setIsCancelUploadConfirmationModalVisible,
    resetSteps,
    validUniqueDocumentNumbers,
    findEmployeesByDocumentNumbersData,
    inputValidationErrors,
  };
};

const MultipleDocumentUploadContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <MultipleDocumentUploadContext.Provider
    value={useMultipleDocumentUploadContextValue()}
  >
    {children}
  </MultipleDocumentUploadContext.Provider>
);

const MultipleDocumentUploadContext = createContext<
  ReturnType<typeof useMultipleDocumentUploadContextValue> | undefined
>(undefined);

const useMultipleDocumentUploadContext = () => {
  const context = useContext(MultipleDocumentUploadContext);
  if (context === undefined) {
    throw new Error(
      'useMultipleDocumentUploadContext must be used within an MultipleDocumentUploadContextProvider',
    );
  }
  return context;
};

export {
  MultipleDocumentUploadContextProvider,
  useMultipleDocumentUploadContext,
};
