import { useMemo } from 'react';
import {
  UploadMultipleDocumentsStepEnum,
  useMultipleDocumentUploadContext,
} from '../../context/MultipleDocumentUploadContext';
import { InputStep } from './components/InputStep';
import { ProcessingStep } from './components/ProcessingStep';
import { SuccessStep } from './components/SuccessStep';
import { ErrorStep } from './components/ErrorStep';

export const Steps = () => {
  const { step } = useMultipleDocumentUploadContext();

  const currentStepComponent = useMemo(() => {
    const stepComponentMap = {
      [UploadMultipleDocumentsStepEnum.INPUT]: <InputStep />,
      [UploadMultipleDocumentsStepEnum.PROCESSING]: <ProcessingStep />,
      [UploadMultipleDocumentsStepEnum.SUCCESS]: <SuccessStep />,
      [UploadMultipleDocumentsStepEnum.ERROR]: <ErrorStep />,
    };

    return stepComponentMap[step];
  }, [step]);

  return currentStepComponent;
};
