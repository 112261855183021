import { Typography, dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledContainer, StyledFieldsContainer } from './styled';
import { useTheme } from 'styled-components';
import { CaptionField } from 'src/components/LabeledField';
import { documentOwnerNameMap } from 'src/constants';
import { formatCompetenceDate } from 'src/utils/formatCompetenceDate';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';

export const DocumentInformationSection = () => {
  const theme = useTheme();

  const { findDocumentByIdData } = useDocumentTableContext();

  if (!findDocumentByIdData) return <></>;

  const fields = [
    {
      field: 'Nome do arquivo',
      value: findDocumentByIdData.fileName,
      maxWidth: 200,
    },
    { field: 'Tipo de arquivo', value: findDocumentByIdData.documentTypeName },
    {
      field: 'Titularidade',
      value: documentOwnerNameMap[findDocumentByIdData.documentOwner],
    },
    {
      field: 'Data de vencimento',
      value: findDocumentByIdData.expirationDate
        ? dayjs(findDocumentByIdData.expirationDate).format('DD/MM/YYYY')
        : undefined,
    },
    {
      field: 'Competência do arquivo',
      value: findDocumentByIdData.competenceDate
        ? formatCompetenceDate(findDocumentByIdData.competenceDate)
        : undefined,
    },
    {
      field: 'Data de criação',
      value: dayjs(findDocumentByIdData.createdAt).format('DD/MM/YYYY'),
    },
    {
      field: 'Data de modificação',
      value: dayjs(findDocumentByIdData.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'Enviado por',
      value: findDocumentByIdData.uploadedByEmployeeData?.name,
      maxWidth: 200,
    },
  ].filter(({ value }) => !!value) as {
    field: string;
    value: string;
    maxWidth?: number;
  }[];

  return (
    <StyledContainer>
      <Typography
        variant="headline9"
        weight={700}
        variantColor={theme.colors.neutral[10]}
      >
        Informações do documento
      </Typography>
      <StyledFieldsContainer>
        {fields.map(({ field, value, maxWidth }, idx) => (
          <CaptionField
            label={field}
            value={value}
            maxWidth={maxWidth}
            key={idx}
          />
        ))}
      </StyledFieldsContainer>
    </StyledContainer>
  );
};
