import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { FormGroup } from '../FormGroup';
import { StyledInputGroup } from '../OwnerForm/styled';
import { trpc } from 'src/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useAddDocument } from 'src/pages/AddDocumentPage/context/add-document-form-context';
import { Dropzone, Extensions } from 'src/components/Dropzone';
import { ChangeEvent } from 'react';
import { showToast } from 'src/utils/showToast';
import { nanoid } from 'nanoid';
import {
  checkFilesForPasswordEncryptedPdf,
  checkIfAllInputFilesAreValid,
} from 'src/components/Dropzone/utils';

const SINGLE_FILE_TYPE_UPLOAD_LIMIT = 5;

export const DocumentForm = () => {
  const { selectedCompany } = useSelectedCompany();
  const { setField, errors, document } = useAddDocument();
  const acceptFields: Extensions[] = ['png', 'jpg', 'jpeg', 'pdf'];

  const { data } = trpc.findDocumentTypes.useQuery({
    companyId: selectedCompany.id,
  });

  return (
    <FormGroup
      title="Foto do documento"
      description="Selecione o tipo do documento e carregue sua imagem."
      required
    >
      <StyledInputGroup>
        <SelectField
          label="Tipo do documento"
          error={!!errors?.documentTypeId}
          helperText={errors?.documentTypeId}
          searchable
          fullWidth
          onSelectChange={(_, e) => setField('documentTypeId', e?.value)}
          options={data?.documentTypes.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
        />
        <Dropzone
          accept={acceptFields}
          errorMessage={errors.files}
          files={document.files}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            const inputFiles = Array.from(e?.target?.files || []);
            if (
              inputFiles.length + document.files.length >
              SINGLE_FILE_TYPE_UPLOAD_LIMIT
            ) {
              showToast({
                type: 'error',
                title: 'Limite de arquivos atingido',
                description: `É permitido enviar no máximo ${SINGLE_FILE_TYPE_UPLOAD_LIMIT} documentos por vez.`,
              });
              return;
            }
            if (!checkIfAllInputFilesAreValid(inputFiles)) return;
            if (await checkFilesForPasswordEncryptedPdf(inputFiles)) {
              showToast({
                type: 'error',
                title: 'PDF com senha detectado',
                description:
                  'Ao menos um dos PDFs possui senha, por favor remova a senha do PDF antes de realizar o upload',
              });
              return;
            }
            setField('files', [
              ...document.files,
              ...inputFiles.map((file) => ({
                file,
                id: nanoid(),
              })),
            ]);
          }}
          onRemove={(removedFileId: string) => {
            setField(
              'files',
              document.files.filter(({ id }) => id !== removedFileId),
            );

            showToast({
              type: 'success',
              title: 'Arquivo removido com sucesso.',
            });
          }}
        />
      </StyledInputGroup>
    </FormGroup>
  );
};
