import {
  Button,
  Icons,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledContentContainer,
  StyledFooterContainer,
  StyledLinkButton,
} from './styled';
import { showToast } from 'src/utils/showToast';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';
import { trpc } from 'src/api/client';

type ResendDocumentSignatureEmailConfirmationModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  signerEmployeeId: string;
};

export const ResendDocumentSignatureEmailConfirmationModal = ({
  onClose,
  onSuccess,
  signerEmployeeId,
}: ResendDocumentSignatureEmailConfirmationModalProps) => {
  const theme = useTheme();
  const { selectedDocumentId } = useDocumentTableContext();

  const {
    mutate: resendDocumentSignatureEmail,
    isLoading: isResendDocumentSignatureEmailLoading,
  } = trpc.resendDocumentSignatureEmail.useMutation({
    onError: () => {
      showToast({
        type: 'error',
        title: 'Não foi possível reenviar o email de assinatura!',
        description:
          'Ocorreu um erro ao reenviar o email de assinatura. Por favor, tente novamente.',
      });
    },
    onSuccess: () => {
      showToast({
        type: 'success',
        title: 'Email de assinatura reenviado com sucesso!',
      });
      onSuccess();
    },
  });

  return (
    <Modal.Root open={true} onClose={onClose}>
      <>
        <Modal.Content>
          <StyledContentContainer>
            <ShapeIcon
              style={{ marginBottom: theme.spacings.xs2 }}
              variant={'error'}
              size={72}
              color={theme.colors.feedback.error[40]}
              name={'IconAlertCircle'}
            />
            <Typography
              style={{ marginBottom: theme.spacings.xs5 }}
              variant="body3"
              weight={700}
              variantColor={theme.colors.feedback.error[40]}
            >
              Atenção!
            </Typography>
            <Typography
              style={{ marginBottom: theme.spacings.xs2 }}
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              Tem certeza que deseja reenviar o email de assinatura?
            </Typography>
          </StyledContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <StyledFooterContainer>
            <StyledLinkButton
              disabled={isResendDocumentSignatureEmailLoading}
              variant="neutral"
              onClick={onClose}
            >
              Cancelar
            </StyledLinkButton>
            <Button
              size="medium"
              variant="primary"
              variantType="error"
              loading={isResendDocumentSignatureEmailLoading}
              onClick={() =>
                resendDocumentSignatureEmail({
                  documentId: selectedDocumentId!,
                  signerEmployeeId,
                })
              }
            >
              Confirmar reenvio <Icons name={'IconMail'} />
            </Button>
          </StyledFooterContainer>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
