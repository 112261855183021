import { SimpleTable, SimpleTableColumns } from '../../../SimpleTable';
import {
  Checkbox,
  IconsProps,
  Tooltip,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';

import { useEffect, useMemo, useState } from 'react';
import {
  ActionTrackingEventEnum,
  DocumentContentTypeEnum,
  DocumentOwnerEnum,
  DocumentStatusEnum,
  dayjsMonthNumberMap,
  documentOwnerNameMap,
} from '../../../../constants';
import { DocumentDrawer } from './components/DocumentDrawer';
import { EmployeeIdentityDisplay } from '../../../EmployeeIdentityDisplay';
import { DocumentSignatureModal } from './components/DocumentSignatureModal';
import { DocumentTypeCell } from './components/tableCells/DocumentTypeCell';
import { DocumentStatusCell } from './components/tableCells/DocumentStatusCell';
import { DocumentContentTypeCell } from './components/tableCells/DocumentContentTypeCell';
import { DocumentTypeFilter } from './components/tableFilters/DocumentTypeFilter';
import { DocumentStatusFilter } from './components/tableFilters/DocumentStatusFilter';
import { DocumentCreationDateFilter } from './components/tableFilters/DocumentCreationDateFilter';
import { DocumentUpdateDateFilter } from './components/tableFilters/DocumentUpdateDateFilter';
import { DocumentOwnerFilter } from './components/tableFilters/DocumentOwnerFilter';
import { DocumentCompetenceDateFilter } from './components/tableFilters/DocumentCompetenceDateFilter';
import { DocumentExpirationDateFilter } from './components/tableFilters/DocumentExpirationDateFilter';
import { DocumentContentTypeFilter } from './components/tableFilters/DocumentContentTypeFilter';
import { useDocumentTableContext } from '../../context/DocumentTableContext';
import { StyledFileNameCell } from './styled';
import { DocumentTableEmptyState } from './components/DocumentTableEmptyState';
import { useMultiDocumentDownloadContext } from 'src/components/MultiDocumentDownloadMenu/context/MultiDocumentDownloadContext';
import { DeleteMultipleDocumentsConfirmationModal } from './components/DeleteMultipleDocumentsConfirmationModal';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { useIsAdmin } from 'src/hooks/useIsAdmin';

type FormattedDocument = {
  id: string;
  employeeId: string;
  fileName: string;
  documentTypeName: string;
  status: DocumentStatusEnum;
  expirationDate?: string;
  employeeName: string;
  employeeEmail?: string;
  employeeProfilePictureSignedUrl?: string;
  documentOwner: DocumentOwnerEnum;
  competenceDate?: string;
  createdAt: string;
  updatedAt: string;
  contentType: DocumentContentTypeEnum;
  signatureRequestedAt?: string;
  isSigned: boolean;
  uploadedBy?: string;
  signers?: {
    employeeId: string;
    signatureRequestedAt: string;
    signature?: { signedAt: string; ip: string };
  }[];
};

export const Table = () => {
  const {
    downloadDocument,
    selectedCompany,
    isEmployeeDocumentsTable,
    pagination,
    setPagination,
    isFindDocumentsLoading,
    findDocumentByIdError,
    setSelectedDocumentId,
    setTableQueryParams,
    tableQueryParams,
    selectedDocumentId,
    findDocumentsData,
    isDocumentDrawerVisible,
    setIsDocumentDrawerVisible,
    refetchDocuments,
    refetchGetBigNumbers,
  } = useDocumentTableContext();
  const { createDocumentBundle, isDocumentBundleProcessing } =
    useMultiDocumentDownloadContext();

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  const isAdmin = useIsAdmin();

  const { trackEvent } = useEventTracking();

  const [
    isDeleteMultipleDocumentsConfirmationModalVisible,
    setIsDeleteMultipleDocumentsConfirmationModalVisible,
  ] = useState(false);

  const [isSignDocumentModalVisible, setIsSignDocumentModalVisible] =
    useState(false);

  useEffect(() => {
    if (tableQueryParams.documentIdToSign) {
      setSelectedDocumentId(tableQueryParams.documentIdToSign);
      setIsSignDocumentModalVisible(true);
      setTableQueryParams({ documentIdToSign: undefined });
    }
  });

  useEffect(() => {
    if (!!findDocumentByIdError) {
      setSelectedDocumentId(undefined);
      setIsSignDocumentModalVisible(false);
    }
  }, [findDocumentByIdError]);

  const allSelected =
    selectedDocumentIds.length === findDocumentsData?.documents.length;

  const selectHeaderCheckBox = (
    <Checkbox
      onChange={() => {
        if (findDocumentsData) {
          if (allSelected) {
            setSelectedDocumentIds([]);
          } else {
            setSelectedDocumentIds(
              findDocumentsData.documents.map(({ id }) => id),
            );
          }
        }
      }}
      checked={allSelected}
      indeterminate={
        selectedDocumentIds.length > 0 &&
        selectedDocumentIds.length !== findDocumentsData?.documents.length
      }
    />
  );

  useEffect(() => {
    setSelectedDocumentIds([]);
  }, [isFindDocumentsLoading]);

  const tableColumns = useMemo(
    () =>
      (
        [
          {
            header: selectHeaderCheckBox,
            accessorKey: 'id',
            cell: (context) => (
              <Checkbox
                onChange={() =>
                  setSelectedDocumentIds((prev) => {
                    const documentId = context.row.original.id;
                    const hasDocumentId = prev.includes(documentId);
                    if (hasDocumentId) {
                      return prev.filter((id) => id !== documentId);
                    } else {
                      return [...prev, documentId];
                    }
                  })
                }
                checked={selectedDocumentIds.includes(context.row.original.id)}
              />
            ),
          },
          {
            header: 'Tipo do documento',
            minSize: 250,
            accessorKey: 'documentTypeName',
            cell: (context) => (
              <DocumentTypeCell name={context.row.original.documentTypeName} />
            ),
          },
          {
            header: 'Status do documento',
            minSize: 240,
            accessorKey: 'status',
            cell: (context) => (
              <DocumentStatusCell
                expirationDate={context.row.original.expirationDate}
                signatureRequestedAt={context.row.original.signatureRequestedAt}
                status={context.row.original.status as DocumentStatusEnum}
              />
            ),
          },
          {
            header: 'Pessoa',
            accessorKey: 'employeeName',
            cell: (context) => (
              <EmployeeIdentityDisplay
                email={context.row.original.employeeEmail}
                name={context.row.original.employeeName}
                profilePictureSignedUrl={
                  context.row.original.employeeProfilePictureSignedUrl
                }
              />
            ),
          },
          {
            header: 'Titularidade',
            accessorKey: 'id',
            accessorFn: ({ documentOwner }) =>
              documentOwnerNameMap[documentOwner],
          },
          {
            header: 'Nome do arquivo',
            accessorKey: 'fileName',
            minSize: 180,
            maxSize: 250,
            cell: (context) => (
              <Tooltip title={context.row.original.fileName} arrow={false}>
                <StyledFileNameCell>
                  {context.row.original.fileName}
                </StyledFileNameCell>
              </Tooltip>
            ),
          },
          {
            header: 'Data de criação',
            minSize: 170,
            accessorKey: 'createdAt',
            accessorFn: ({ createdAt }) =>
              dayjs(createdAt).format('DD/MM/YYYY'),
          },
          {
            header: 'Data de modificação',
            minSize: 200,
            accessorKey: 'updatedAt',
            accessorFn: ({ updatedAt }) =>
              dayjs(updatedAt).format('DD/MM/YYYY'),
          },
          {
            header: 'Data de vencimento',
            minSize: 200,
            accessorKey: 'expirationDate',
            accessorFn: ({ expirationDate }) =>
              expirationDate
                ? dayjs(expirationDate).format('DD/MM/YYYY')
                : 'Indeterminado',
          },
          {
            header: 'Dia de competência',
            minSize: 240,
            accessorKey: 'competenceDate',
            accessorFn: ({ competenceDate }) => {
              if (!competenceDate) return 'Indeterminado';
              const date = dayjs(competenceDate);
              return `${
                dayjsMonthNumberMap[
                  date.month() as keyof typeof dayjsMonthNumberMap
                ]
              }, ${date.format('YYYY')}`;
            },
          },
          {
            header: 'Assinado',
            minSize: 100,
            accessorKey: 'isSigned',
            accessorFn: ({ isSigned }) => (!!isSigned ? 'Sim' : 'Não'),
          },
          {
            header: 'Formato do arquivo',
            minSize: 200,
            accessorKey: 'contentType',
            cell: (context) => (
              <DocumentContentTypeCell
                contentType={
                  context.row.original.contentType as DocumentContentTypeEnum
                }
              />
            ),
          },
        ] as SimpleTableColumns<FormattedDocument>
      ).filter(
        ({ accessorKey }) =>
          !isEmployeeDocumentsTable ||
          [
            'documentTypeName',
            'status',
            'isSigned',
            'createdAt',
            'updatedAt',
            'fileName',
            'id',
          ].includes(accessorKey || ''),
      ),
    [isEmployeeDocumentsTable, selectedDocumentIds, findDocumentsData],
  );

  return (
    <>
      <SimpleTable<FormattedDocument>
        showSearch={!isEmployeeDocumentsTable}
        isLoading={isFindDocumentsLoading}
        selectedTableHeaderActions={[
          {
            label: 'Baixar documentos',
            disabled: isDocumentBundleProcessing,
            onClick: () => {
              trackEvent({
                name: ActionTrackingEventEnum.TABLE_DOWNLOAD_SELECTED_DOCUMENTS_BUTTON_CLICKED,
              });
              createDocumentBundle({ documentIds: selectedDocumentIds });
            },
            iconName: 'IconDownload',
          },
          ...(isAdmin
            ? [
                {
                  label: 'Excluir documentos',
                  onClick: () => {
                    setIsDeleteMultipleDocumentsConfirmationModalVisible(true);
                  },
                  iconName: 'IconTrash' as IconsProps['name'],
                },
              ]
            : []),
        ]}
        selectCheckboxHeader={selectHeaderCheckBox}
        totalSelectedRows={selectedDocumentIds.length}
        filters={
          isEmployeeDocumentsTable
            ? [
                <DocumentTypeFilter />,
                <DocumentStatusFilter />,
                <DocumentCreationDateFilter />,
                <DocumentUpdateDateFilter />,
              ]
            : [
                <DocumentTypeFilter />,
                <DocumentStatusFilter />,
                <DocumentOwnerFilter />,
                <DocumentCreationDateFilter />,
                <DocumentUpdateDateFilter />,
                <DocumentCompetenceDateFilter />,
                <DocumentExpirationDateFilter />,
                <DocumentContentTypeFilter />,
              ]
        }
        columns={tableColumns}
        options={{
          actions: (context) => [
            {
              label: 'Ver documento',
              icon: 'IconEye',
              key: 'view',
              onClick: () => {
                trackEvent({
                  name: ActionTrackingEventEnum.VIEW_DOCUMENT_CLICKED,
                });
                setIsDocumentDrawerVisible(true);
                setSelectedDocumentId(context.row.original.id);
              },
            },
            {
              label: 'Baixar documento',
              icon: 'IconFileZip',
              key: 'download',
              onClick: () => {
                trackEvent({
                  name: ActionTrackingEventEnum.DOWNLOAD_DOCUMENT_CLICKED,
                });
                downloadDocument({
                  companyId: selectedCompany.id,
                  documentId: context.row.original.id,
                });
              },
            },
          ],
        }}
        onSearchChange={(e) =>
          setTableQueryParams({
            ...tableQueryParams,
            searchTerm: e?.target.value,
          })
        }
        searchPlaceholder="Buscar por pessoa"
        searchValue={tableQueryParams.searchTerm}
        data={findDocumentsData?.documents}
        pagination={{ ...pagination, total: findDocumentsData?.total || 0 }}
        onPaginationChange={({ pageNumber, pageSize }) =>
          setPagination((prev) => ({ ...prev, pageNumber, pageSize }))
        }
        showPagination={true}
        emptyState={<DocumentTableEmptyState />}
      />
      {selectedDocumentId && isDocumentDrawerVisible && (
        <DocumentDrawer
          onClose={() => {
            setIsDocumentDrawerVisible(false);
            setSelectedDocumentId(undefined);
          }}
        />
      )}
      {selectedDocumentId && isSignDocumentModalVisible && (
        <DocumentSignatureModal
          onSuccess={() => {
            refetchDocuments();
            refetchGetBigNumbers();
            setIsSignDocumentModalVisible(false);
          }}
          onClose={() => {
            setIsSignDocumentModalVisible(false);
            setSelectedDocumentId(undefined);
          }}
        />
      )}
      {!!selectedDocumentIds.length &&
        isDeleteMultipleDocumentsConfirmationModalVisible && (
          <DeleteMultipleDocumentsConfirmationModal
            selectedDocumentIds={selectedDocumentIds}
            onSuccess={() => {
              refetchDocuments();
              refetchGetBigNumbers();
              setSelectedDocumentIds([]);
              setPagination((p) => ({
                ...p,
                pageNumber: p.pageNumber > 1 ? p.pageNumber - 1 : 1,
              }));
              setIsDeleteMultipleDocumentsConfirmationModalVisible(false);
            }}
            onClose={() => {
              setIsDeleteMultipleDocumentsConfirmationModalVisible(false);
            }}
          />
        )}
    </>
  );
};
