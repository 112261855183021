import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: fixed;
  width: 400px;
  right: ${({ theme }) => theme.spacings.xs1};
  bottom: 0px;
  transition: margin-bottom 100ms;
  box-shadow: 0px 8px 32px 0px #00000029;
`;

export const StyledSpace = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacings.xs3}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs1};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

export const StyledButtonContainer = styled.div`
  min-width: 32px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.xs2};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-style: solid;
  border-width: 0 ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[95]};
`;
