import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledSelectedHeaderContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  align-items: center;
`;

export const StyledSelectedHeaderLinkButton = styled(LinkButton)`
  align-self: center !important;
`;
