import { Accordion, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    cursor: default !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    display: none;
  }

  .MuiAccordionSummary-contentGutters div {
    padding-right: 0;
  }
`;

export const StyledSignatureTitleContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledSignatureTitleLeftContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledSignatureTitleRightContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: end;
  justify-content: end;
`;

export const StyledExtraSignersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
`;

export const StyledLinkButton = styled(LinkButton)`
  align-self: center !important;
`;
