import {
  Accordion,
  DatePicker,
  TextArea,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StyledInputGroup } from './styled';
import { useAddDocument } from 'src/pages/AddDocumentPage/context/add-document-form-context';

export const AdditionalInformationForm = () => {
  const { setField, errors } = useAddDocument();

  return (
    <Accordion
      variant="default"
      title="Informações complementares"
      description="Esses dados definem como e por quanto tempo o arquivo será válido e acessível."
      tagName="Preenchimento não obrigatório"
      tagVariant="disabled"
    >
      <StyledInputGroup>
        <DatePicker
          error={!!errors?.expirationDate}
          helperText={
            !!errors?.expirationDate
              ? errors.expirationDate
              : 'Data que o arquivo deixará de estar disponível.'
          }
          label="Data de vencimento"
          onDateChange={(date) =>
            setField('expirationDate', date?.endOf('day').toISOString())
          }
        />
        <DatePicker
          helperText="A qual dia este arquivo é referente."
          label="Dia de competência"
          onDateChange={(date) =>
            setField('competenceDate', date?.toISOString())
          }
        />
        <TextArea
          placeholder="Observações"
          maxLength={100}
          onBlur={(e) => setField('description', e.target.value)}
        />
      </StyledInputGroup>
    </Accordion>
  );
};
