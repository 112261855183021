import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { createContext, useContext, ReactNode } from 'react';
import { trpc } from 'src/api/client';

const useBigNumbersContextValue = () => {
  const { selectedCompany } = useSelectedCompany();

  const {
    data: getBigNumbersData,
    isFetching: isGetBigNumbersLoading,
    refetch: refetchGetBigNumbers,
  } = trpc.getBigNumbersData.useQuery(
    {
      companyId: selectedCompany.id,
    },
    { refetchOnWindowFocus: false, cacheTime: 0 },
  );

  return {
    getBigNumbersData,
    isGetBigNumbersLoading,
    refetchGetBigNumbers,
  };
};

const BigNumbersContextProvider = ({ children }: { children: ReactNode }) => (
  <BigNumbersContext.Provider value={useBigNumbersContextValue()}>
    {children}
  </BigNumbersContext.Provider>
);

const BigNumbersContext = createContext<
  ReturnType<typeof useBigNumbersContextValue> | undefined
>(undefined);

const useBigNumbersContext = () => useContext(BigNumbersContext);

export { BigNumbersContextProvider, useBigNumbersContext };
