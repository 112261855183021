import styled from 'styled-components';

export const StyledCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledTagText = styled.span`
  white-space: nowrap;
`;
