import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs3};
  width: 100%;
`;

export const StyledEmailContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledEmailTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const StyledNameTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  gap: ${({ theme }) => theme.spacings.xs5};
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;
