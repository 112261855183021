import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledTitleSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledButtonSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;
