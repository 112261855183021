import { useState } from 'react';

interface UseImageResizer {
  resizedImageUrl: string | null;
  resizeImage: (
    file: File,
    maxWidth: number,
    maxHeight: number,
    quality?: number,
  ) => Promise<string>;
}

export const useImageResize = (): UseImageResizer => {
  const [resizedImageUrl, setResizedImageUrl] = useState<string | null>(null);

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number,
    quality = 0.7,
  ): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
        if (!readerEvent.target) {
          reject('Failed to read the file');
          return;
        }

        const image = new Image();
        image.onload = () => {
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            reject('Failed to get canvas context');
            return;
          }

          ctx.drawImage(image, 0, 0, width, height);

          const resizedUrl = canvas.toDataURL('image/jpeg', quality);
          setResizedImageUrl(resizedUrl);
          resolve(resizedUrl);
        };

        image.onerror = (error) => {
          reject(error);
        };

        image.src = readerEvent.target.result as string;
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  return { resizedImageUrl, resizeImage };
};
