import { useEffect } from 'react';
import { MultiButtonSelect } from '../../../../../../components/MultiButtonSelect';
import { TableViewEnum } from '../../../../constants';
import { useTableQueryParams } from '../../../../../../hooks/useTableQueryParams';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const TableViews = () => {
  const { setTableView, tableQueryParams } = useTableQueryParams();
  const { trackEvent } = useEventTracking();

  useEffect(() => {
    if (!Object.values(TableViewEnum).includes(tableQueryParams.tableView)) {
      setTableView(TableViewEnum.EMPLOYEE);
    }
  }, [tableQueryParams]);

  return (
    <MultiButtonSelect
      label="Visualizar por"
      buttons={[
        {
          iconName: 'IconUser',
          onClick: () => {
            trackEvent({
              name: ActionTrackingEventEnum.EMPLOYEE_TABLE_VIEW_CLICKED,
            });
            setTableView(TableViewEnum.EMPLOYEE);
          },
          isSelected: tableQueryParams.tableView === TableViewEnum.EMPLOYEE,
          text: 'Pessoa',
        },
        {
          iconName: 'IconFolder',
          onClick: () => {
            trackEvent({
              name: ActionTrackingEventEnum.DOCUMENT_TYPE_TABLE_VIEW_CLICKED,
            });
            setTableView(TableViewEnum.DOCUMENT_TYPE);
          },
          isSelected:
            tableQueryParams.tableView === TableViewEnum.DOCUMENT_TYPE,
          text: 'Tipo do documento',
        },
        {
          iconName: 'IconList',
          onClick: () => {
            trackEvent({
              name: ActionTrackingEventEnum.DOCUMENTS_TABLE_VIEW_CLICKED,
            });
            setTableView(TableViewEnum.DOCUMENT);
          },
          isSelected: tableQueryParams.tableView === TableViewEnum.DOCUMENT,
          text: 'Lista de documentos',
        },
      ]}
    />
  );
};
