import { Button, LinkButton, Modal } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { css, keyframes } from 'styled-components';

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: start;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledFooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export const StyledSpace = styled.div`
  width: 100%;
  flex: auto;
`;

export const StyledLinkButton = styled(LinkButton)`
  align-self: center !important;
`;

export const StyledLine = styled.div`
  width: 100%;
  border-style: solid;
  height: 1px;
  border-top: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;

const draw = keyframes`
  from {
    stroke-dasharray: 0, 100;
  }
  to {
    stroke-dasharray: 100, 0;
  }
`;

export const StyledAnimatedButton = styled(Button)<{ animate?: boolean }>`
  white-space: nowrap;
  svg {
    ${(props) =>
      props.animate &&
      css`
        animation: ${draw} 3s ease-in-out forwards;
      `}
  }
`;

export const StyledModalContent = styled(Modal.Content)`
  overflow-y: auto;
  max-height: 450px;
`;
