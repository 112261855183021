import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledShapeIcon = styled(ShapeIcon)`
  animation: ${rotate} 2s linear infinite;
`;
