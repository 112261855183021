import { TagFilter } from '@flash-tecnologia/hros-web-ui-v2';
import { trpc } from 'src/api/client';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';
import { ActionTrackingEventEnum } from 'src/constants';
import { useEventTracking } from 'src/hooks/useEventTracking';

export const DocumentTypeFilter = () => {
  const { tableQueryParams, setTableQueryParams, selectedCompany } =
    useDocumentTableContext();
  const { trackEvent } = useEventTracking();

  const selectedDocumentTypeIds = tableQueryParams.documentTypeIds?.split(',');

  const { data: documentTypesData, isLoading: isDocumentTypesLoading } =
    trpc.findDocumentTypes.useQuery(
      { companyId: selectedCompany.id },
      { refetchOnWindowFocus: false, cacheTime: 0 },
    );

  return (
    <TagFilter
      variant="secondary"
      filterLabel="Tipo do documento"
      hasLeftIcon={false}
      options={documentTypesData?.documentTypes.map(({ name, id }) => ({
        label: name,
        value: id,
      }))}
      onApply={(values) => {
        const value = values.length ? values.join(',') : undefined;
        if (value) {
          trackEvent({
            name: ActionTrackingEventEnum.TABLE_FILTER_USED,
            params: {
              filterName: 'Tipo do documento',
              value,
            },
          });
        }
        setTableQueryParams({
          documentTypeIds: value,
        });
      }}
      onClear={() => setTableQueryParams({ documentTypeIds: undefined })}
      selectedOptions={selectedDocumentTypeIds}
      badgeNumber={selectedDocumentTypeIds?.length}
      loading={isDocumentTypesLoading}
      optionIconType="checkbox"
    />
  );
};
