import { TagDateFilter, dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';
import { ActionTrackingEventEnum } from 'src/constants';
import { useEventTracking } from 'src/hooks/useEventTracking';

export const DocumentCompetenceDateFilter = () => {
  const { tableQueryParams, setTableQueryParams } = useDocumentTableContext();
  const { trackEvent } = useEventTracking();

  const competenceDateRange = tableQueryParams.competenceDateRange?.split(',');

  return (
    <TagDateFilter
      filterLabel="Dia de competência"
      variant="secondary"
      hasLeftIcon={false}
      onSubmit={({ from, to }) => {
        const startOfFromDay = from
          ? `${dayjs(from).startOf('day').unix()}`
          : null;
        const endOfToDay = to ? `${dayjs(to).endOf('day').unix()}` : null;
        const value =
          startOfFromDay && endOfToDay
            ? `${startOfFromDay},${endOfToDay}`
            : undefined;
        if (value) {
          trackEvent({
            name: ActionTrackingEventEnum.TABLE_FILTER_USED,
            params: {
              filterName: 'Dia de competência',
              value,
            },
          });
        }
        setTableQueryParams({
          competenceDateRange: value,
        });
      }}
      initialDateRange={
        competenceDateRange?.length
          ? {
              from: dayjs.unix(Number(competenceDateRange[0])).toDate(),
              to: dayjs.unix(Number(competenceDateRange[1])).toDate(),
            }
          : undefined
      }
    />
  );
};
