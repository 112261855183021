export const EmptyStateImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="190"
      height="156"
      viewBox="0 0 190 156"
      fill="none"
    >
      <path
        d="M6.64099 71.6875V73.2919"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M3.03784 72.5566L4.34885 73.9062"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M1.50452 76.1484H3.35826"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M2.88794 79.8046L4.19895 78.4551"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M6.64099 80.2431V78.6387"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M9.31495 79.1731L8.24536 78.1035"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M11.4541 76.5H9.84973"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M10.3845 72.7559L9.31494 73.8254"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M92.7427 1.62891V2.69849"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M89.5339 4.83789H90.6035"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M92.7427 8.04615V6.97656"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M95.4166 4.83789H94.347"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M30.7067 46.5508V48.1552"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M25.8934 51.3652H27.4978"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M30.7067 55.6434V54.0391"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M34.985 51.3652H33.3806"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        d="M145.824 104.865C147.102 104.865 148.139 105.902 148.139 107.18C148.139 108.459 147.102 109.496 145.824 109.496C144.546 109.496 143.509 108.459 143.509 107.18"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.417 83.8612C157.791 83.4325 159.898 81.7517 160.386 79.9434C160.828 81.5419 162.682 83.4325 164.354 83.5755C162.471 84.2422 160.671 86.2227 160.386 87.7786C160.191 86.1927 157.701 84.0923 156.417 83.8612Z"
        fill="#4A4E57"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3444 104.152C16.0317 103.938 17.0846 103.098 17.3284 102.193C17.5495 102.992 18.4762 103.938 19.3125 104.009C18.3713 104.342 17.4714 105.333 17.3284 106.111C17.2313 105.318 15.9863 104.267 15.3444 104.152Z"
        fill="#4A4E57"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1006 29.7581C28.1006 30.7499 27.297 31.5533 26.3058 31.5533C25.3143 31.5533 24.5111 30.7499 24.5111 29.7581C24.5111 28.7667 25.3143 27.9629 26.3058 27.9629C27.297 27.9629 28.1006 28.7667 28.1006 29.7581Z"
        fill="#4A4E57"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.945 34.5786C147.945 36.2357 146.601 37.5791 144.945 37.5791C143.288 37.5791 141.945 36.2357 141.945 34.5786C141.945 32.9215 143.288 31.5781 144.945 31.5781C146.601 31.5781 147.945 32.9215 147.945 34.5786Z"
        fill="#4A4E57"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.496 26.4485C189.496 27.7632 188.43 28.8286 187.117 28.8286C185.802 28.8286 184.737 27.7632 184.737 26.4485C184.737 25.1338 185.802 24.0684 187.117 24.0684C188.43 24.0684 189.496 25.1338 189.496 26.4485Z"
        fill="#4A4E57"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.6419 137.61C61.6419 138.439 60.9704 139.11 60.1418 139.11C59.3137 139.11 58.6422 138.439 58.6422 137.61C58.6422 136.781 59.3137 136.109 60.1418 136.109C60.9704 136.109 61.6419 136.781 61.6419 137.61Z"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.7495 18.2249C60.7495 19.2167 59.9459 20.0201 58.9548 20.0201C57.9636 20.0201 57.16 19.2167 57.16 18.2249C57.16 17.2335 57.9636 16.4297 58.9548 16.4297C59.9459 16.4297 60.7495 17.2335 60.7495 18.2249Z"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.53 154.226C105.383 154.662 104.099 154.086 103.663 152.939C103.227 151.792 103.803 150.508 104.95 150.072C106.096 149.635 107.38 150.211 107.816 151.358C108.253 152.505 107.677 153.789 106.53 154.226Z"
        stroke="#4A4E57"
        strokeOpacity="0.3"
        strokeWidth="1.56213"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.846 111.796H61.9908C58.904 111.796 56.3773 109.261 56.3773 106.164V43.6242C56.3773 40.527 58.904 37.9941 61.9908 37.9941H105.846C108.934 37.9941 111.461 40.527 111.461 43.6242V106.164C111.461 109.261 108.934 111.796 105.846 111.796Z"
        fill="#DBDCDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.103 114.469H58.2472C55.1605 114.469 52.6338 111.935 52.6338 108.838V46.298C52.6338 43.2009 55.1605 40.668 58.2472 40.668H102.103C105.191 40.668 107.718 43.2009 107.718 46.298V108.838C107.718 111.935 105.191 114.469 102.103 114.469Z"
        fill="#4A4E57"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.4615 116.58H54.5153C51.4222 116.58 48.8903 114.056 48.8903 110.972V48.6907C48.8903 45.6064 51.4222 43.084 54.5153 43.084H98.4615C101.556 43.084 104.088 45.6064 104.088 48.6907V110.972C104.088 114.056 101.556 116.58 98.4615 116.58Z"
        fill="#4A4E57"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.4615 116.58H54.5153C51.4222 116.58 48.8903 114.056 48.8903 110.972V48.6907C48.8903 45.6064 51.4222 43.084 54.5153 43.084H98.4615C101.556 43.084 104.088 45.6064 104.088 48.6907V110.972C104.088 114.056 101.556 116.58 98.4615 116.58Z"
        fill="white"
      />
      <g clip-path="url(#clip0_4750_13223)">
        <path
          d="M68.2926 63.7617C70.7779 63.7617 72.7926 61.747 72.7926 59.2617C72.7926 56.7764 70.7779 54.7617 68.2926 54.7617C65.8073 54.7617 63.7926 56.7764 63.7926 59.2617C63.7926 61.747 65.8073 63.7617 68.2926 63.7617Z"
          fill="#4A4E57"
          fillOpacity="0.5"
        />
        <path
          d="M68.2926 66.0117C65.2866 66.0117 59.2926 67.5237 59.2926 70.5117V71.6367C59.2926 71.9351 59.4111 72.2212 59.6221 72.4322C59.8331 72.6432 60.1192 72.7617 60.4176 72.7617H76.1676C76.466 72.7617 76.7521 72.6432 76.9631 72.4322C77.1741 72.2212 77.2926 71.9351 77.2926 71.6367V70.5117C77.2926 67.5237 71.2986 66.0117 68.2926 66.0117Z"
          fill="#4A4E57"
          fillOpacity="0.5"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.1271 81.3123H59.6675C58.7405 81.3123 57.9817 80.5904 57.9817 79.7085C57.9817 78.8266 58.7405 78.1035 59.6675 78.1035H92.1271C93.0541 78.1035 93.8128 78.8266 93.8128 79.7085C93.8128 80.5904 93.0541 81.3123 92.1271 81.3123Z"
        fill="#DBDCDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.1271 90.9392H59.6675C58.7405 90.9392 57.9817 90.2173 57.9817 89.3355C57.9817 88.4523 58.7405 87.7305 59.6675 87.7305H92.1271C93.0541 87.7305 93.8128 88.4523 93.8128 89.3355C93.8128 90.2173 93.0541 90.9392 92.1271 90.9392Z"
        fill="#DBDCDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.6109 100.564H59.6747C58.7437 100.564 57.9817 99.8423 57.9817 98.9605C57.9817 98.0773 58.7437 97.3555 59.6747 97.3555H76.6109C77.5418 97.3555 78.3038 98.0773 78.3038 98.9605C78.3038 99.8423 77.5418 100.564 76.6109 100.564Z"
        fill="#DBDCDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.795 88.1246C104.305 94.6141 94.2564 95.3462 86.9614 90.3358C86.0291 89.684 85.1417 88.9519 84.3142 88.1246C83.8883 87.7 83.4868 87.2618 83.1084 86.8005C82.3517 85.8793 81.6889 84.8968 81.1458 83.8817C80.189 82.1671 79.5385 80.3477 79.1833 78.4672C78.0373 72.5696 79.7508 66.2216 84.3142 61.6603C88.8886 57.0854 95.2377 55.383 101.136 56.5179C103.016 56.884 104.837 57.5344 106.552 58.4801C107.568 59.0353 108.537 59.698 109.46 60.4546C109.92 60.8316 110.358 61.2344 110.783 61.6603C111.612 62.4876 112.355 63.3735 112.995 64.307C118.007 71.5993 117.274 81.6473 110.795 88.1246Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.98 86.8459C102.227 93.5997 91.2838 93.5985 84.5299 86.8459C77.7849 80.1008 77.7849 69.1563 84.5387 62.4037C91.2838 55.6586 102.227 55.6586 108.972 62.4037C115.725 69.1563 115.725 80.1008 108.98 86.8459ZM112.445 58.9343C103.778 50.2667 89.732 50.2667 81.0644 58.9343C72.3981 67.6006 72.3906 81.655 81.0569 90.3225C88.9454 98.2085 101.31 98.9197 110.006 92.4448C110.856 91.8115 111.674 91.1016 112.454 90.3225C113.233 89.5422 113.943 88.7242 114.575 87.8748C121.05 79.1771 120.332 66.8216 112.445 58.9343Z"
        fill="#DBDCDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.555 122.995L146.192 123.359C143.784 125.767 139.842 125.767 137.433 123.359L115.205 101.13L124.327 92.0078L146.555 114.236C148.964 116.645 148.964 120.586 146.555 122.995Z"
        fill="#DBDCDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.054 87.1953L121.622 93.7639L116.959 98.426L110.392 91.8573C111.258 91.2111 112.092 90.4867 112.889 89.6917C113.684 88.8968 114.409 88.0621 115.054 87.1953Z"
        fill="#DBDCDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.223 113.963L137.159 123.026L135.527 121.393L144.59 112.33L146.223 113.963Z"
        fill="#C9CACD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.831 92.3229L115.519 101.635L114.135 100.252L123.447 90.9395L124.831 92.3229Z"
        fill="#C9CACD"
      />
      <defs>
        <clipPath id="clip0_4750_13223">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(56.2926 51.7617)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
