import {
  dayjs,
  GenericProfilePicture,
  Icons,
  Tag,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledCustomButton,
  StyledInfoContainer,
  StyledInfoTopContainer,
} from './styled';
import { useTheme } from 'styled-components';
import { useState } from 'react';
import { ResendDocumentSignatureEmailConfirmationModal } from './components/ResendDocumentSignatureEmailConfirmationModal';
import {
  DocumentActionEnum,
  useDocumentTableContext,
} from 'src/components/DocumentTable/context/DocumentTableContext';

type SignerDisplayProps = {
  employeeId: string;
  name: string;
  signature?: {
    ip: string;
    signedAt: string;
  };
  requestedAt: string;
  profilePictureSignedUrl?: string;
};

export const SignerDisplay = ({
  employeeId,
  name,
  requestedAt,
  profilePictureSignedUrl,
  signature,
}: SignerDisplayProps) => {
  const theme = useTheme();
  const [
    isResendDocumentSignatureEmailConfirmationModalVisible,
    setIsResendDocumentSignatureEmailConfirmationModalVisible,
  ] = useState(false);
  const { findDocumentByIdData, checkDocumentActionPermission } =
    useDocumentTableContext();

  if (!findDocumentByIdData) return <></>;

  const signedAtDate = dayjs(signature?.signedAt);
  const requestedAtDate = dayjs(requestedAt);

  const signatureStatusTagVariant = !!signature ? 'green' : 'yellow';
  const signatureStatusTagText = !!signature
    ? 'Assinado'
    : 'Aguardando assinatura';
  const signatureInfoText = !!signature
    ? `Assinado dia ${signedAtDate.format(
        'DD/MM/YYYY',
      )} às ${signedAtDate.format('HH:mm')}, através do IP ${signature.ip}`
    : `Solicitação enviada dia ${requestedAtDate.format(
        'DD/MM/YYYY',
      )} às ${requestedAtDate.format('HH:mm')}`;

  const canResendDocumentSignatureEmail =
    checkDocumentActionPermission(
      findDocumentByIdData,
      DocumentActionEnum.RESEND_DOCUMENT_SIGNATURE_EMAIL,
    ) && !signature;

  return (
    <StyledContainer>
      <GenericProfilePicture
        source={profilePictureSignedUrl}
        name={name}
        enableEdit={false}
        size={40}
      />
      <StyledInfoContainer>
        <StyledInfoTopContainer>
          <Typography
            variant="body4"
            weight={700}
            variantColor={theme.colors.neutral[30]}
          >
            {name}
          </Typography>
          <Tag
            disabled={false}
            as="span"
            size="extra-small"
            hasLeftDotIcon={true}
            variant={signatureStatusTagVariant}
          >
            {signatureStatusTagText}
          </Tag>
        </StyledInfoTopContainer>
        <Typography
          style={{ width: '100%' }}
          variant="caption"
          weight={600}
          variantColor={theme.colors.neutral[70]}
        >
          {signatureInfoText}
        </Typography>
        {canResendDocumentSignatureEmail && (
          <StyledButtonContainer>
            <StyledCustomButton
              onClick={() => {
                setIsResendDocumentSignatureEmailConfirmationModalVisible(true);
              }}
            >
              <Icons
                name="IconBellRinging"
                color={theme.colors.primary}
                size={16}
              />
              <Typography
                variant="caption"
                weight={700}
                variantColor={theme.colors.secondary[50]}
              >
                Reenviar solicitação de assinatura
              </Typography>
            </StyledCustomButton>
          </StyledButtonContainer>
        )}
      </StyledInfoContainer>
      {isResendDocumentSignatureEmailConfirmationModalVisible && (
        <ResendDocumentSignatureEmailConfirmationModal
          signerEmployeeId={employeeId}
          onClose={() => {
            setIsResendDocumentSignatureEmailConfirmationModalVisible(false);
          }}
          onSuccess={() => {
            setIsResendDocumentSignatureEmailConfirmationModalVisible(false);
          }}
        />
      )}
    </StyledContainer>
  );
};
