import styled from 'styled-components';

export const StyledContainer = styled.div`
  flex-grow: 1;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 37px;
  display: flex;
  flex-direction: column;
`;
