import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledFileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFileImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borders.radius.xs};
  image-rendering: pixelated;
`;

export const StyledFileImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  width: 100%;
`;

export const StyledTrashIcon = styled(Icons)`
  cursor: pointer;
`;

export const StyledFileName = styled(Typography)`
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
  padding-right: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledFileNameWrapper = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;
