import {
  BigNumberProps,
  Skeleton,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StyledBigNumber, StyledContainer } from './styled';
import { TableViewEnum } from '../../constants';
import {
  ActionTrackingEventEnum,
  DocumentStatusEnum,
} from '../../../../constants';
import { useTableQueryParams } from '../../../../hooks/useTableQueryParams';
import { DocumentTableQueryParams } from 'src/components/DocumentTable/context/DocumentTableContext';
import { useBigNumbersContext } from '../../context/BigNumbersContext';
import { useEventTracking } from 'src/hooks/useEventTracking';

export const BigNumbers = () => {
  const bigNumbersContext = useBigNumbersContext();
  const { setTableView } = useTableQueryParams<DocumentTableQueryParams>();
  const { trackEvent } = useEventTracking();

  if (!bigNumbersContext) return <></>;

  const { getBigNumbersData, isGetBigNumbersLoading } = bigNumbersContext;

  const bigNumberData: BigNumberProps[] = [
    {
      orientation: 'horizontal',
      title: 'Documentos a vencer',
      iconColors: { primary: '#F20D7A', secondary: '#FFE0EF' },
      icon: 'IconAlertTriangle',
      value:
        getBigNumbersData?.totalDocumentsCloseToExpiring === undefined
          ? '-'
          : getBigNumbersData.totalDocumentsCloseToExpiring,
      buttonLabel: 'Ver detalhes',
      onSelect: () => {
        trackEvent({
          name: ActionTrackingEventEnum.DOCUMENTS_ABOUT_TO_EXPIRE_BIG_NUMBERS_FILTER_CLICKED,
        });
        const now = dayjs().startOf('day').unix();
        const fifteenDaysInTheFuture = dayjs()
          .add(15, 'day')
          .endOf('day')
          .unix();
        setTableView(TableViewEnum.DOCUMENT, {
          expirationDateRange: `${now},${fifteenDaysInTheFuture}`,
        });
      },
      buttonVariant: 'link',
      tooltip:
        'Documentos que estão próximos da data de vencimento. Recomendamos revisar e tomar as ações necessárias antes que expirem.',
    },
    {
      orientation: 'horizontal',
      title: 'Documentos vencidos',
      iconColors: { primary: '#F20D7A', secondary: '#FFE0EF' },
      icon: 'IconCalendarOff',
      value:
        getBigNumbersData?.totalExpiredDocuments === undefined
          ? '-'
          : getBigNumbersData.totalExpiredDocuments,
      buttonLabel: 'Ver detalhes',
      onSelect: () => {
        trackEvent({
          name: ActionTrackingEventEnum.EXPIRED_DOCUMENTS_BIG_NUMBERS_FILTER_CLICKED,
        });
        setTableView(TableViewEnum.DOCUMENT, {
          documentStatuses: DocumentStatusEnum.EXPIRED,
        });
      },
      buttonVariant: 'link',
      tooltip:
        'Documentos cuja data de vencimento já passou. É necessário verificar e atualizar ou excluir, conforme necessário.',
    },
    {
      orientation: 'horizontal',
      title: 'Aguardando assinatura',
      iconColors: { primary: '#F20D7A', secondary: '#FFE0EF' },
      icon: 'IconIdOff',
      value:
        getBigNumbersData?.totalDocumentsAwatingSignature === undefined
          ? '-'
          : getBigNumbersData.totalDocumentsAwatingSignature,
      buttonLabel: 'Ver detalhes',
      onSelect: () => {
        trackEvent({
          name: ActionTrackingEventEnum.DOCUMENTS_AWAITING_SIGNATURE_BIG_NUMBERS_FILTER_CLICKED,
        });
        setTableView(TableViewEnum.DOCUMENT, {
          documentStatuses: DocumentStatusEnum.AWAITING_SIGNATURE,
        });
      },
      buttonVariant: 'link',
      tooltip:
        'Documentos que ainda precisam ser assinados pela pessoa titular para verificar sua autenticidade.',
    },
  ];

  const loadingSkeletonKeys = ['1', '2', '3'];

  return (
    <StyledContainer>
      {isGetBigNumbersLoading
        ? loadingSkeletonKeys.map((key) => (
            <Skeleton key={key} animation="pulse" height={200} width={'100%'} />
          ))
        : bigNumberData.map((props, idx) => (
            <StyledBigNumber
              data-pendo="document-management-big-number"
              key={idx}
              {...props}
            />
          ))}
    </StyledContainer>
  );
};
