import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import {
  StyledAside,
  StyledContentContainer,
  StyledFooter,
  StyledMainContent,
  StyledTitleContainer,
} from './styled';
import { Container } from 'src/components/Container';

type PageStructureProps = {
  AsideContent: ReactNode;
  MainContent: ReactNode;
  FooterContent: ReactNode;
};

export const PageStructure = ({
  AsideContent,
  MainContent,
  FooterContent,
}: PageStructureProps) => {
  const theme = useTheme();

  return (
    <>
      <Container>
        <StyledTitleContainer>
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
            weight={700}
          >
            Adicionar vários documentos
          </Typography>
        </StyledTitleContainer>
        <StyledContentContainer>
          <StyledAside>{AsideContent}</StyledAside>
          <StyledMainContent>{MainContent}</StyledMainContent>
        </StyledContentContainer>
      </Container>
      <StyledFooter>{FooterContent}</StyledFooter>
    </>
  );
};
