import {
  GenericProfilePicture,
  Icons,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledContainer,
  StyledEmailContainer,
  StyledEmailTypography,
  StyledNameTypography,
  StyledTextContainer,
} from './styled';
import { useTheme } from 'styled-components';

type EmployeeIdentityDisplayProps = {
  name: string;
  email?: string;
  profilePictureSignedUrl?: string;
};

export const EmployeeIdentityDisplay = ({
  name,
  email,
  profilePictureSignedUrl,
}: EmployeeIdentityDisplayProps) => {
  const theme = useTheme();
  return (
    <StyledContainer>
      <GenericProfilePicture
        source={profilePictureSignedUrl}
        name={name}
        enableEdit={false}
        size={40}
      />
      <StyledTextContainer>
        <StyledNameTypography
          variant="body3"
          variantColor={theme.colors.neutral[40]}
          weight={600}
        >
          <Tooltip title={name} arrow={false}>
            <span>{name}</span>
          </Tooltip>
        </StyledNameTypography>
        <StyledEmailContainer>
          <Icons name="IconMail" color={theme.colors.neutral[70]} size={18} />
          <StyledEmailTypography
            variant="body4"
            variantColor={theme.colors.neutral[60]}
            weight={400}
          >
            <Tooltip title={email} arrow={false}>
              <span>{email || '-'}</span>
            </Tooltip>
          </StyledEmailTypography>
        </StyledEmailContainer>
      </StyledTextContainer>
    </StyledContainer>
  );
};
