import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { createContext, useContext, ReactNode, useState } from 'react';
import { trpc } from 'src/api/client';
import {
  CustomExceptionCodeEnum,
  DocumentBundleStatusEnum,
} from 'src/constants';
import { showToast } from 'src/utils/showToast';

const defaultToastError = {
  type: 'error' as const,
  title: 'Erro ao baixar documentos',
  description:
    'Ocorreu um erro ao baixar os documentos. Por favor, tente novamente.',
};

const useMultiDocumentDownloadContextValue = () => {
  const { selectedCompany } = useSelectedCompany();
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const { data: findDocumentBundlesData, refetch: refetchFindDocumentBundles } =
    trpc.findDocumentBundles.useQuery(
      {
        companyId: selectedCompany.id,
      },
      {
        refetchOnWindowFocus: false,
        refetchInterval: (data) => {
          const documentBundle = data?.documentBundles[0];
          if (documentBundle?.status !== DocumentBundleStatusEnum.PROCESSING)
            return false;
          return 5000;
        },
      },
    );

  const { mutate: createDocumentBundle } =
    trpc.createDocumentBundle.useMutation({
      onError: ({ data }) => {
        showToast({
          ...defaultToastError,
          description:
            data?.customCode ===
            CustomExceptionCodeEnum.DOCUMENT_BUNDLE_ALREADY_PROCESSING
              ? 'Já existem arquivos em processamento, por favor aguarde.'
              : defaultToastError.description,
        });
      },
      onSuccess: () => {
        refetchFindDocumentBundles();
        setIsMenuOpen(true);
      },
    });

  const { mutate: reprocessDocumentBundle } =
    trpc.reprocessDocumentBundle.useMutation({
      onError: () => showToast(defaultToastError),
      onSuccess: () => {
        refetchFindDocumentBundles();
        setIsMenuOpen(true);
      },
    });

  const { mutate: downloadDocumentBundle } =
    trpc.downloadDocumentBundle.useMutation({
      onError: () => showToast(defaultToastError),
      onSuccess: ({ url }) => {
        refetchFindDocumentBundles();
        open(url, '_blank');
      },
    });

  const documentBundle = findDocumentBundlesData?.documentBundles[0];

  const isMultiDocumentDownloadMenuVisible = !!documentBundle;

  const isDocumentBundleProcessing =
    findDocumentBundlesData?.documentBundles[0]?.status ===
    DocumentBundleStatusEnum.PROCESSING;

  return {
    createDocumentBundle,
    reprocessDocumentBundle,
    downloadDocumentBundle,
    documentBundle,
    isMultiDocumentDownloadMenuVisible,
    isMenuOpen,
    setIsMenuOpen,
    isDocumentBundleProcessing,
  };
};

const MultiDocumentDownloadContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <MultiDocumentDownloadContext.Provider
    value={useMultiDocumentDownloadContextValue()}
  >
    {children}
  </MultiDocumentDownloadContext.Provider>
);

const MultiDocumentDownloadContext = createContext<
  ReturnType<typeof useMultiDocumentDownloadContextValue> | undefined
>(undefined);

const useMultiDocumentDownloadContext = () => {
  const context = useContext(MultiDocumentDownloadContext);
  if (context === undefined) {
    throw new Error(
      'useMultiDocumentDownloadContext must be used within an MultiDocumentDownloadContextProvider',
    );
  }
  return context;
};

export {
  MultiDocumentDownloadContextProvider,
  useMultiDocumentDownloadContext,
};
