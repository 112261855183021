import styled from 'styled-components';

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledRequiredSymbol = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error['50']};
`;
