import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledDescriptionTypography = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.s};

  @media (max-width: 1366px) {
    gap: ${({ theme }) => theme.spacings.xs};
    flex-direction: column;
  }
`;

export const StyledFileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const StyledHorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border-top-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;
