import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xl3};
`;

export const StyledLinkButton = styled(LinkButton)`
  align-self: center !important;
`;
