import { Header } from './components/Header';
import { BigNumbers } from './components/BigNumbers';
import { Table } from './components/Table';
import { StyledPageContainer } from '../../components/StyledPageContainer';
import { BigNumbersContextProvider } from './context/BigNumbersContext';
import { MultiDocumentDownloadMenu } from 'src/components/MultiDocumentDownloadMenu';
import { MultiDocumentDownloadContextProvider } from 'src/components/MultiDocumentDownloadMenu/context/MultiDocumentDownloadContext';
import { useEffect } from 'react';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { PageTrackingNameEnum } from 'src/constants';
import { useIsAdmin } from 'src/hooks/useIsAdmin';
import { useNavigate } from 'react-router-dom';
import { getAccessTokenPayloadSync } from '@flash-tecnologia/hros-web-utility';

export const DocumentsPage = () => {
  const { trackPage } = useEventTracking();
  const navigate = useNavigate();
  const { employeeId: userEmployeeId } = getAccessTokenPayloadSync();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (isAdmin) {
      trackPage({ name: PageTrackingNameEnum.DOCUMENTS });
    } else {
      navigate(`/document-management/employee/${userEmployeeId}`);
    }
  }, []);

  return (
    <StyledPageContainer>
      <Header />
      <MultiDocumentDownloadContextProvider>
        <BigNumbersContextProvider>
          <BigNumbers />
          <Table />
        </BigNumbersContextProvider>
        <MultiDocumentDownloadMenu />
      </MultiDocumentDownloadContextProvider>
    </StyledPageContainer>
  );
};
