import { memo, useEffect, useMemo } from 'react';
import {
  StyledFileImage,
  StyledFileImageWrapper,
  StyledFileItem,
  StyledTrashIcon,
  StyledFileName,
  StyledFileNameWrapper,
} from './styled';
import { useTheme } from 'styled-components';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { DocumentThumb } from '../DocumentThumb';
import { useImageResize } from 'src/hooks/useImageResize';

type FileItemProps = {
  file: File;
  onRemove: () => void;
};

const FileItem = memo(({ file, onRemove }: FileItemProps) => {
  const theme = useTheme();
  const { resizeImage, resizedImageUrl } = useImageResize();

  useEffect(() => {
    if (file && file.type.startsWith('image/')) {
      resizeImage(file, 50, 50, 1);
    }
  }, [file]);

  const thumb = useMemo(() => {
    if (file.type.startsWith('image/')) {
      return resizedImageUrl ? (
        <StyledFileImage
          loading="lazy"
          decoding="async"
          src={resizedImageUrl}
        />
      ) : (
        <Skeleton
          style={{ minHeight: '32px', minWidth: '32px' }}
          animation="pulse"
          variant="rounded"
          height={32}
          width={32}
        />
      );
    }

    return <DocumentThumb type={file.type} />;
  }, [file, resizedImageUrl]);

  return (
    <StyledFileItem>
      <StyledFileImageWrapper>
        {thumb}
        <StyledFileNameWrapper>
          <StyledFileName
            variant="body3"
            weight={400}
            variantColor={theme.colors.neutral[30]}
          >
            {file.name}
          </StyledFileName>
        </StyledFileNameWrapper>
      </StyledFileImageWrapper>
      <StyledTrashIcon
        onClick={onRemove}
        name="IconTrash"
        size={24}
        color={theme.colors.secondary[50]}
      />
    </StyledFileItem>
  );
});

export default FileItem;
