import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';

export const AsideContent = () => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="headline7"
        variantColor={theme.colors.secondary[50]}
        weight={700}
      >
        Status do envio dos documentos
      </Typography>
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral[50]}
        weight={400}
      >
        O processo finalizado com sucesso, porém alguns documentos não puderam
        ser vinculados às pessoas da sua empresa. Confira na lista de erros
        quais documentos não puderam ser associadas às pessoas da sua empresa.
      </Typography>
    </>
  );
};
