import styled from 'styled-components';

export const StyledTitleContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacings.m}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs};
`;

export const StyledAside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 330px;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.s};
  padding-bottom: ${({ theme }) => theme.spacings.xl2};
`;

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledFooter = styled.footer`
  padding: ${({ theme }) => theme.spacings.xs2}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs};
  border-top: solid ${({ theme }) => theme.borders.width.xs}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  position: fixed;
  bottom: 0;
  width: fill-available;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  z-index: 99;
`;
