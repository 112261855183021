import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledContainer } from './styled';

export const LoadingSkeleton = () => (
  <StyledContainer>
    <Skeleton animation="pulse" height={25} width={'250px'} />
    <Skeleton animation="pulse" height={120} width={'100%'} />
    <Skeleton animation="pulse" height={350} width={'100%'} />
  </StyledContainer>
);
