import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledDocumentThumb = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.xs};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
