import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledContainer } from './styled';
import { useTheme } from 'styled-components';

type CustomFiltersProps = {
  filters: JSX.Element[];
};

export const CustomFilters = ({ filters }: CustomFiltersProps) => {
  const theme = useTheme();
  return (
    <StyledContainer data-pendo="document-management-filter">
      <Typography
        variant="body3"
        variantColor={theme.colors.neutral[40]}
        weight={600}
      >
        Filtrar por
      </Typography>
      {filters.map((Filter) => Filter)}
    </StyledContainer>
  );
};
