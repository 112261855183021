import {
  getAccessTokenPayloadSync,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { trpc } from 'src/api/client';
import { useTableQueryParams } from 'src/hooks/useTableQueryParams';
import { showToast } from 'src/utils/showToast';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import {
  ActionTrackingEventEnum,
  DocumentContentTypeEnum,
  DocumentStatusEnum,
  SEARCH_INPUT_DELAY,
} from 'src/constants';
import { useBigNumbersContext } from 'src/pages/DocumentsPage/context/BigNumbersContext';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { useIsAdmin } from 'src/hooks/useIsAdmin';

export type DocumentTableQueryParams = {
  searchTerm?: string;
  documentTypeIds?: string;
  documentStatuses?: string;
  documentOwners?: string;
  creationDateRange?: string;
  updateDateRange?: string;
  competenceDateRange?: string;
  expirationDateRange?: string;
  contentTypes?: string;
  documentIdToShow?: string;
  documentIdToSign?: string;
  signerEmployeeIds?: string;
  tab?: string;
};

export enum DocumentActionEnum {
  SIGN_DOCUMENT = 'SIGN_DOCUMENT',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  REQUEST_DOCUMENT_SIGNATURE = 'REQUEST_DOCUMENT_SIGNATURE',
  RESEND_DOCUMENT_SIGNATURE_EMAIL = 'RESEND_DOCUMENT_SIGNATURE_EMAIL',
}

const useDocumentTableContextValue = () => {
  const bigNumbersContext = useBigNumbersContext();
  const { employeeId: urlParamEmployeeId } = useParams();
  const { employeeId: userEmployeeId } = getAccessTokenPayloadSync();
  const { selectedCompany } = useSelectedCompany();
  const isAdmin = useIsAdmin();
  const { tableQueryParams, setTableQueryParams } =
    useTableQueryParams<DocumentTableQueryParams>();
  const [selectedDocumentId, setSelectedDocumentId] = useState<
    string | undefined
  >();
  const { trackEvent } = useEventTracking();

  const searchTerm = useDebouncedValue<string | undefined>(
    tableQueryParams.searchTerm,
    SEARCH_INPUT_DELAY,
  );

  useEffect(() => {
    if (searchTerm) {
      trackEvent({
        name: ActionTrackingEventEnum.TABLE_SEARCH_USED,
        params: { value: searchTerm },
      });
    }
  }, [searchTerm]);

  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (tableQueryParams.documentIdToShow) {
      setSelectedDocumentId(tableQueryParams.documentIdToShow);
      setIsDocumentDrawerVisible(true);
      setTableQueryParams({ documentIdToShow: undefined });
    }
  });

  const [isDocumentDrawerVisible, setIsDocumentDrawerVisible] = useState(false);

  const {
    data: findDocumentsData,
    isFetching: isFindDocumentsLoading,
    refetch: refetchDocuments,
  } = trpc.findDocuments.useQuery(
    {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      companyId: selectedCompany.id,
      employeeId: tableQueryParams.tab === '2' ? undefined : urlParamEmployeeId,
      ...tableQueryParams,
      searchTerm,
    },
    { refetchOnWindowFocus: false, cacheTime: 0 },
  );

  const {
    data: findDocumentByIdData,
    isFetching: isFindDocumentByIdLoading,
    refetch: refetchFindDocumentById,
    error: findDocumentByIdError,
  } = trpc.findDocumentById.useQuery(
    {
      companyId: selectedCompany.id,
      documentId: selectedDocumentId!,
    },
    {
      onError: () => {
        showToast({
          type: 'error',
          title: 'Documento não encontrado',
        });
      },
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!selectedDocumentId,
      retry: 2,
    },
  );

  useEffect(() => {
    if (!!findDocumentByIdError) {
      setSelectedDocumentId(undefined);
      setIsDocumentDrawerVisible(false);
    }
  }, [findDocumentByIdError]);

  const { mutate: downloadDocument } = trpc.downloadDocument.useMutation({
    onError: () => {
      showToast({
        type: 'error',
        title: 'Erro ao baixar documento',
        description:
          'Ocorreu um erro ao baixar o documento. Por favor, tente novamente.',
      });
    },
    onSuccess: ({ signedUrl }) => open(signedUrl, '_blank'),
  });

  const isEmployeeDocumentsTable = !!urlParamEmployeeId;

  const checkDocumentActionPermission = (
    document: {
      employeeId: string;
      status: DocumentStatusEnum;
      isSigned: boolean;
      contentType: DocumentContentTypeEnum;
      signers?: { employeeId: string; signature?: unknown }[];
      uploadedBy?: string;
      permissions: {
        sign: boolean;
      };
    },
    action: DocumentActionEnum,
  ) => {
    const signer = document.signers?.find(
      (signer) => signer.employeeId === userEmployeeId,
    );
    const hasSignedDocument = !!signer?.signature;

    return {
      [DocumentActionEnum.DELETE_DOCUMENT]: isAdmin,
      [DocumentActionEnum.REQUEST_DOCUMENT_SIGNATURE]:
        isAdmin &&
        document.contentType === DocumentContentTypeEnum.PDF &&
        document.status === DocumentStatusEnum.ACTIVE &&
        !document.isSigned,
      [DocumentActionEnum.RESEND_DOCUMENT_SIGNATURE_EMAIL]:
        isAdmin && document.status === DocumentStatusEnum.AWAITING_SIGNATURE,
      [DocumentActionEnum.SIGN_DOCUMENT]:
        document.permissions.sign &&
        !hasSignedDocument &&
        document.status === DocumentStatusEnum.AWAITING_SIGNATURE,
    }[action];
  };

  const refetchGetBigNumbers = () => {
    if (bigNumbersContext) {
      bigNumbersContext.refetchGetBigNumbers();
    }
  };

  return {
    tableQueryParams,
    setTableQueryParams,
    downloadDocument,
    findDocumentsData,
    isFindDocumentsLoading,
    refetchDocuments,
    pagination,
    setPagination,
    selectedDocumentId,
    setSelectedDocumentId,
    selectedCompany,
    isEmployeeDocumentsTable,
    isDocumentDrawerVisible,
    setIsDocumentDrawerVisible,
    findDocumentByIdData,
    isFindDocumentByIdLoading,
    findDocumentByIdError,
    refetchFindDocumentById,
    refetchGetBigNumbers,
    checkDocumentActionPermission,
  };
};

const DocumentTableContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <DocumentTableContext.Provider value={useDocumentTableContextValue()}>
    {children}
  </DocumentTableContext.Provider>
);

const DocumentTableContext = createContext<
  ReturnType<typeof useDocumentTableContextValue> | undefined
>(undefined);

const useDocumentTableContext = () => {
  const context = useContext(DocumentTableContext);
  if (context === undefined) {
    throw new Error(
      'useDocumentTableContext must be used within an DocumentTableContextProvider',
    );
  }
  return context;
};

export { DocumentTableContextProvider, useDocumentTableContext };
