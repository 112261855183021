import { DocumentContentTypeEnum } from 'src/constants';
import { PDFPreviewDisplay } from './components/PDFPreviewDisplay';
import { DocumentImageDisplay } from 'src/components/DocumentImageDisplay';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';

export const DocumentFileDisplay = () => {
  const { findDocumentByIdData } = useDocumentTableContext();

  if (!findDocumentByIdData) return <></>;

  if (findDocumentByIdData.contentType === DocumentContentTypeEnum.PDF)
    return (
      <PDFPreviewDisplay
        fileSignedUrl={findDocumentByIdData.documentFileSignedUrl}
      />
    );
  return (
    <DocumentImageDisplay
      fileSignedUrl={findDocumentByIdData.documentFileSignedUrl}
    />
  );
};
