import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { FileType } from 'src/components/Dropzone';
import { DocumentOwnerEnum } from 'src/constants';
import { z, ZodError } from 'zod';

const addDocumentSchema = z.object({
  employeeId: z.string().nonempty({
    message:
      'É obrigátorio selecionar a pessoa que este documento está associado.',
  }),
  documentOwner: z
    .string()
    .nonempty({ message: 'O titular do documento deve ser informado.' }),
  canBeSigned: z.boolean(),
  documentTypeId: z
    .string()
    .nonempty({ message: 'O tipo do documento deve ser informado.' }),
  files: z.custom<FileType[]>((val) => !!(val as FileType[]).length, {
    message: 'Pelo menos um arquivo deve ser anexado.',
  }),
  expirationDate: z
    .string()
    .optional()
    .refine(
      (_expirationDate) => {
        return !!_expirationDate
          ? dayjs(_expirationDate as string).isValid() &&
              !dayjs(_expirationDate as string).isBefore(dayjs(), 'day')
          : true;
      },
      {
        message: 'A data de vencimento não pode ser no passado',
      },
    ),
  competenceDate: z.string().optional(),
  description: z.string().optional(),
  additionalSignerEmployees: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
    }),
  ),
});

type AddDocumentState = z.infer<typeof addDocumentSchema>;

interface AddDocumentContextProps {
  document: AddDocumentState;
  setField: (field: string, value: any) => void;
  validateFields: () => boolean;
  errors: Record<string, string | undefined>;
}

const AddDocumentContext = createContext<AddDocumentContextProps | undefined>(
  undefined,
);

const AddDocumentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [document, setDocument] = useState<AddDocumentState>({
    employeeId: '',
    documentOwner: '' as DocumentOwnerEnum,
    files: [],
    canBeSigned: false,
    additionalSignerEmployees: [],
    documentTypeId: '',
  } as AddDocumentState);

  const [errors, setErrors] = useState<Record<string, string | undefined>>({});

  const validateFields = useCallback(() => {
    const { success, error } = addDocumentSchema.safeParse(document) as {
      success: boolean;
      error: ZodError;
    };

    if (!success && error) {
      const { fieldErrors } = error.flatten();
      setErrors(
        Object.entries(fieldErrors).reduce((err, [field, message]) => {
          if (message) {
            err[field] = message[0];
          }
          return err;
        }, {} as Record<string, string>),
      );
    } else {
      setErrors({});
    }

    return success;
  }, [document]);

  const setField = (field: string, value: any) => {
    setErrors((prevError) => ({
      ...prevError,
      [field]: undefined,
    }));
    setDocument((prevDocument) => ({
      ...prevDocument,
      [field]: value,
    }));
  };

  const values = useMemo(
    () => ({ document, setField, validateFields, errors }),
    [document, errors, validateFields],
  );

  return (
    <AddDocumentContext.Provider value={values}>
      {children}
    </AddDocumentContext.Provider>
  );
};

const useAddDocument = (): AddDocumentContextProps => {
  const context = useContext(AddDocumentContext);
  if (context === undefined) {
    throw new Error('useDocument must be used within an AddDocumentProvider');
  }
  return context;
};

export { AddDocumentProvider, useAddDocument };
