import { MAX_FILE_SIZE } from 'src/constants';
import { showToast } from 'src/utils/showToast';
import { PDFDocument } from 'pdf-lib';

export const PDF_FILE_EXTENSION = 'pdf';

export const checkIfAllInputFilesAreValid = (files: File[]) => {
  const filesThatExceedTheMaxSize = files.filter(
    ({ size }) => size > MAX_FILE_SIZE,
  );

  if (!!filesThatExceedTheMaxSize.length) {
    showToast({
      type: 'error',
      title: 'Tamanho de arquivo inválido!',
      description: 'Ao menos um dos arquivos excede o limite de 10 MB.',
    });
    return false;
  }
  return true;
};

export const checkFilesForPasswordEncryptedPdf = async (files: File[]) => {
  const pdfFiles = files.filter(
    (file) => file.name.split('.').at(-1) === PDF_FILE_EXTENSION,
  );

  if (pdfFiles.length) {
    const totalPasswordEncryptedPdfs = (
      await Promise.all(
        pdfFiles.map(async (file) => checkIfPdfIsPasswordEncrypted(file)),
      )
    ).filter((isEncrypted) => isEncrypted).length;

    return !!totalPasswordEncryptedPdfs;
  }

  return false;
};

export const checkIfPdfIsPasswordEncrypted = async (file: File) => {
  try {
    const pdfFileBuffer = await file.arrayBuffer();
    await PDFDocument.load(pdfFileBuffer);
    return false;
  } catch (error: any) {
    if (error?.message?.includes('is encrypted')) return true;
    throw error;
  }
};
