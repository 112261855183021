import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs2}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs1};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
