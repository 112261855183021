import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AddDocumentPage } from 'src/pages/AddDocumentPage';
import { DocumentsPage } from '../pages/DocumentsPage';
import { EmployeeDocumentsPage } from '../pages/EmployeeDocumentsPage';
import { AddMultipleDocumentsPage } from 'src/pages/AddMultipleDocumentsPage';
import { ScrollToTop } from 'src/components/ScrollToTop';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/_document-management" element={<DocumentsPage />} />
        <Route path="/document-management" element={<DocumentsPage />} />
        <Route
          path="/_document-management/add-document"
          element={<AddDocumentPage />}
        />
        <Route
          path="/document-management/add-document"
          element={<AddDocumentPage />}
        />
        <Route
          path="/_document-management/add-multiple-documents"
          element={<AddMultipleDocumentsPage />}
        />
        <Route
          path="/document-management/add-multiple-documents"
          element={<AddMultipleDocumentsPage />}
        />
        <Route
          path="/_document-management/employee/:employeeId"
          element={<EmployeeDocumentsPage />}
        />
        <Route
          path="/document-management/employee/:employeeId"
          element={<EmployeeDocumentsPage />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
