import {
  Button,
  Icons,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ContentCard } from 'src/pages/AddMultipleDocumentsPage/components/ContentCard';
import { useTheme } from 'styled-components';
import { FileList } from '../../../FileList';
import {
  StyledContentContainer,
  StyledDescriptionTypography,
  StyledHeaderContainer,
} from './styled';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';
import { useEffect } from 'react';
import { showToast } from 'src/utils/showToast';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const MainContent = () => {
  const theme = useTheme();
  const { resetSteps, documentList, validUniqueDocumentNumbers } =
    useMultipleDocumentUploadContext();
  const { trackEvent } = useEventTracking();

  useEffect(() => {
    showToast({
      type: 'success',
      title: 'Documentos enviados com sucesso!',
      description: `${documentList.length} documentos enviados e associados a ${validUniqueDocumentNumbers.length} pessoas com sucesso.`,
    });
  }, []);

  return (
    <ContentCard>
      <StyledContentContainer>
        <StyledHeaderContainer>
          <ShapeIcon
            variant="success"
            name="IconCheck"
            color={theme.colors.feedback.success[40]}
            size={48}
          />
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
            weight={700}
          >
            Documentos enviados com sucesso!
          </Typography>
          <StyledDescriptionTypography
            variant="body4"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            <Icons name="IconPhotoUp" size={16} /> {documentList.length}{' '}
            documentos enviados e associados a{' '}
            {validUniqueDocumentNumbers.length} pessoas com sucesso.
          </StyledDescriptionTypography>
        </StyledHeaderContainer>

        <FileList />

        <Button
          onClick={() => {
            trackEvent({
              name: ActionTrackingEventEnum.RESET_MULTIPLE_DOCUMENT_FLOW_BUTTON_CLICKED,
            });
            resetSteps();
          }}
          size="large"
          variant="primary"
          variantType="default"
        >
          Novo envio de documentos <Icons name="IconPlus" />
        </Button>
      </StyledContentContainer>
    </ContentCard>
  );
};
