import { Button, Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { ContentCard } from 'src/pages/AddMultipleDocumentsPage/components/ContentCard';
import { useTheme } from 'styled-components';
import { FileList } from '../../../FileList';
import {
  StyledContentContainer,
  StyledHeaderContainer,
  StyledShapeIcon,
} from './styled';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';

export const MainContent = () => {
  const theme = useTheme();
  const { setIsCancelUploadConfirmationModalVisible, documentList } =
    useMultipleDocumentUploadContext();

  return (
    <ContentCard>
      <StyledContentContainer>
        <StyledHeaderContainer>
          <StyledShapeIcon
            variant="default"
            name="IconRefresh"
            color={theme.colors.primary}
            size={48}
          />
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
            weight={700}
          >
            Enviando{' '}
            {documentList.filter(({ progress }) => progress === 100).length} de{' '}
            {documentList.length} documentos
          </Typography>
        </StyledHeaderContainer>

        <FileList />

        <Button
          onClick={() => setIsCancelUploadConfirmationModalVisible(true)}
          size="large"
          variant="secondary"
          variantType="default"
        >
          <Icons name="IconX" /> Interromper envio dos documentos
        </Button>
      </StyledContentContainer>
    </ContentCard>
  );
};
