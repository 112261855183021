import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const FileTypeIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  justify-content: flex-start;
`;

export const OpenFileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  justify-content: flex-end;
`;

export const StyledIconButton = styled(IconButton)`
  align-self: center !important;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
  flex: auto;
  justify-content: center;
`;
