import { NotificationCard, dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledNotificationCardRootWrapper } from './styled';
import { trpc } from 'src/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useMemo } from 'react';

type DocumentAlreadySignedAlertProps = {
  employeeId: string;
  signatureDate: string;
};

export const DocumentAlreadySignedAlert = ({
  employeeId,
  signatureDate,
}: DocumentAlreadySignedAlertProps) => {
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.findEmployeeById.useQuery(
    {
      companyId: selectedCompany.id,
      employeeId: employeeId,
    },
    { refetchOnWindowFocus: false },
  );

  const signedOnDate = useMemo(
    () => dayjs(signatureDate).format('DD/MM/YYYY'),
    [signatureDate],
  );

  const signedAtTime = useMemo(
    () => dayjs(signatureDate).format('HH:mm'),
    [signatureDate],
  );

  return (
    <StyledNotificationCardRootWrapper>
      <NotificationCard.Root
        type="info"
        variant="outlined"
        autoClose
        showCloseButton
      >
        <NotificationCard.Icon iconName="IconAlertCircle" />
        <NotificationCard.WrapperTexts>
          <NotificationCard.Title>Documento já assinado</NotificationCard.Title>
          <NotificationCard.Subtitle>
            Este documento já foi assinado por {isLoading ? '...' : data?.name},
            dia {signedOnDate}, às {signedAtTime}
          </NotificationCard.Subtitle>
        </NotificationCard.WrapperTexts>
      </NotificationCard.Root>
    </StyledNotificationCardRootWrapper>
  );
};
