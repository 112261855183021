import { TagFilter } from '@flash-tecnologia/hros-web-ui-v2';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';
import {
  ActionTrackingEventEnum,
  DocumentContentTypeEnum,
} from 'src/constants';
import { useEventTracking } from 'src/hooks/useEventTracking';

export const DocumentContentTypeFilter = () => {
  const { tableQueryParams, setTableQueryParams } = useDocumentTableContext();
  const { trackEvent } = useEventTracking();

  const contentTypes = tableQueryParams.contentTypes?.split(',');

  return (
    <TagFilter
      variant="secondary"
      filterLabel="Formato do arquivo"
      hasLeftIcon={false}
      options={Object.entries(DocumentContentTypeEnum).map(
        ([label, value]) => ({
          label,
          value,
        }),
      )}
      onApply={(values) => {
        const value = values.length ? values.join(',') : undefined;
        if (value) {
          trackEvent({
            name: ActionTrackingEventEnum.TABLE_FILTER_USED,
            params: {
              filterName: 'Formato do arquivo',
              value,
            },
          });
        }
        setTableQueryParams({
          contentTypes: value,
        });
      }}
      onClear={() => setTableQueryParams({ contentTypes: undefined })}
      selectedOptions={contentTypes}
      badgeNumber={contentTypes?.length}
      optionIconType="checkbox"
    />
  );
};
