import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';

export const AsideContent = () => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="headline7"
        variantColor={theme.colors.secondary[50]}
        weight={700}
      >
        Status do envio dos documentos
      </Typography>
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral[50]}
        weight={400}
      >
        Todos os documentos foram enviados e vinculados às pessoas da sua
        empresa com sucesso! Confira ao lado a listagem de todos os documentos e
        pessoas associadas ao mesmos.
      </Typography>
    </>
  );
};
