import { useNavigate } from 'react-router-dom';
import { StyledLinkButtonWrapper } from './styled';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';

export const FooterContent = () => {
  const navigate = useNavigate();
  const { startUpload, findEmployeesByDocumentNumbersData } =
    useMultipleDocumentUploadContext();

  return (
    <>
      <StyledLinkButtonWrapper>
        <LinkButton variant="neutral" onClick={() => navigate(-1)}>
          Cancelar
        </LinkButton>
      </StyledLinkButtonWrapper>
      <Button
        onClick={() => startUpload()}
        disabled={!findEmployeesByDocumentNumbersData}
        size="large"
        variant="primary"
        variantType="default"
      >
        Enviar documentos <Icons name="IconArrowRight" />
      </Button>
    </>
  );
};
