import { Tag } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledCellContainer, StyledTagText } from './styled';

type DocumentTypeCellProps = {
  name: string;
};

export const DocumentTypeCell = ({ name }: DocumentTypeCellProps) => {
  return (
    <StyledCellContainer>
      <Tag as="span" variant="primary">
        <StyledTagText>{name}</StyledTagText>
      </Tag>
    </StyledCellContainer>
  );
};
