import {
  ActionTrackingEventEnum,
  DocumentBundleStatusEnum,
} from 'src/constants';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledIconContainer,
  StyledTypographyButtonText,
  StyledTypographyText,
} from './styled';
import { Icons, Spinner, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { ComponentProps } from 'react';
import { useTheme } from 'styled-components';
import { useMultiDocumentDownloadContext } from '../../context/MultiDocumentDownloadContext';
import { Skeleton } from '@mui/material';
import { useEventTracking } from 'src/hooks/useEventTracking';

type IconNames = ComponentProps<typeof Icons>['name'];

export const DownloadInfoRow = () => {
  const { documentBundle, downloadDocumentBundle, reprocessDocumentBundle } =
    useMultiDocumentDownloadContext();
  const theme = useTheme();
  const { trackEvent } = useEventTracking();

  const iconStatusMap = {
    [DocumentBundleStatusEnum.READY_FOR_DOWNLOAD]: (
      <Icons
        size={16}
        name="IconCircleCheck"
        color={theme.colors.feedback.success[40]}
      />
    ),
    [DocumentBundleStatusEnum.ERROR]: (
      <Icons
        size={16}
        name="IconAlertCircle"
        color={theme.colors.feedback.error[50]}
      />
    ),
    [DocumentBundleStatusEnum.PROCESSING]: <Spinner size={16} />,
  };

  const buttonEventTrackingFunctionMap = {
    [DocumentBundleStatusEnum.READY_FOR_DOWNLOAD]: () =>
      trackEvent({
        name: ActionTrackingEventEnum.DOWNLOAD_DOCUMENT_BUNDLE_BUTTON_CLICKED,
      }),
    [DocumentBundleStatusEnum.ERROR]: () =>
      trackEvent({
        name: ActionTrackingEventEnum.RETRY_DOCUMENT_BUNDLE_DOWNLOAD_BUTTON_CLICKED,
      }),
    [DocumentBundleStatusEnum.PROCESSING]: () => {},
  };

  const buttonTextStatusMap = {
    [DocumentBundleStatusEnum.READY_FOR_DOWNLOAD]: 'Baixar arquivo',
    [DocumentBundleStatusEnum.ERROR]: 'Tentar novamente',
    [DocumentBundleStatusEnum.PROCESSING]: undefined,
  };

  const buttonIconStatusMap = {
    [DocumentBundleStatusEnum.READY_FOR_DOWNLOAD]: 'IconDownload',
    [DocumentBundleStatusEnum.ERROR]: 'IconRotateClockwise',
    [DocumentBundleStatusEnum.PROCESSING]: undefined,
  };

  const buttonAction = ({
    documentId,
    status,
  }: {
    status: DocumentBundleStatusEnum;
    documentId: string;
  }) => {
    const buttonActionStatusMap = {
      [DocumentBundleStatusEnum.READY_FOR_DOWNLOAD]: () =>
        downloadDocumentBundle({ documentId }),
      [DocumentBundleStatusEnum.ERROR]: () =>
        reprocessDocumentBundle({ documentId }),
      [DocumentBundleStatusEnum.PROCESSING]: undefined,
    };

    const action = buttonActionStatusMap[status];

    if (action) action();
  };

  const getText = ({
    status,
    totalDocuments,
  }: {
    totalDocuments: number;
    status: DocumentBundleStatusEnum;
  }) => {
    return {
      [DocumentBundleStatusEnum.READY_FOR_DOWNLOAD]: `${totalDocuments} arquivos compactados`,
      [DocumentBundleStatusEnum.ERROR]: `Erro ao compactar ${totalDocuments} arquivos`,
      [DocumentBundleStatusEnum.PROCESSING]: `Compactando ${totalDocuments} arquivos`,
    }[status];
  };

  if (!documentBundle) return <Skeleton height={'20px'} width={'100%'} />;

  const { status, totalDocuments, id } = documentBundle;

  const icon = iconStatusMap[status];
  const text = getText({ status, totalDocuments });
  const buttonText = buttonTextStatusMap[status];
  const buttonIcon = buttonIconStatusMap[status];
  const trackButton = buttonEventTrackingFunctionMap[status];

  return (
    <StyledContainer>
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledTypographyText
        variant="body4"
        weight={400}
        variantColor={theme.colors.neutral[30]}
      >
        <Tooltip title={text} arrow={false}>
          <span>{text}</span>
        </Tooltip>
      </StyledTypographyText>
      {buttonText && buttonIcon && (
        <StyledButtonContainer
          onClick={() => {
            trackButton();
            buttonAction({ documentId: id, status });
          }}
        >
          <StyledTypographyButtonText
            variant="body4"
            weight={700}
            variantColor={theme.colors.secondary[50]}
          >
            {buttonText}
          </StyledTypographyButtonText>
          <Icons
            size={16}
            name={buttonIcon as IconNames}
            color={theme.colors.secondary[50]}
          />
        </StyledButtonContainer>
      )}
    </StyledContainer>
  );
};
