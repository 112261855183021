import { TagFilter } from '@flash-tecnologia/hros-web-ui-v2';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';
import { ActionTrackingEventEnum, DocumentOwnerEnum } from 'src/constants';
import { useEventTracking } from 'src/hooks/useEventTracking';

export const DocumentOwnerFilter = () => {
  const { tableQueryParams, setTableQueryParams } = useDocumentTableContext();
  const { trackEvent } = useEventTracking();

  const documentOwners = tableQueryParams.documentOwners?.split(',');

  return (
    <TagFilter
      variant="secondary"
      filterLabel="Titularidade"
      hasLeftIcon={false}
      options={[
        { label: 'Titular', value: DocumentOwnerEnum.EMPLOYEE },
        { label: 'Dependente', value: DocumentOwnerEnum.DEPENDENT },
      ]}
      onApply={(values) => {
        const value = values.length ? values.join(',') : undefined;
        if (value) {
          trackEvent({
            name: ActionTrackingEventEnum.TABLE_FILTER_USED,
            params: {
              filterName: 'Titularidade',
              value,
            },
          });
        }
        setTableQueryParams({
          documentOwners: value,
        });
      }}
      onClear={() => setTableQueryParams({ documentOwners: undefined })}
      selectedOptions={documentOwners}
      badgeNumber={documentOwners?.length}
      optionIconType="checkbox"
    />
  );
};
