import { useNavigate } from 'react-router-dom';
import { StyledLinkButtonWrapper } from './styled';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

export const FooterContent = () => {
  const navigate = useNavigate();

  return (
    <>
      <StyledLinkButtonWrapper>
        <LinkButton
          variant="neutral"
          onClick={() => navigate('/document-management')}
        >
          Sair
        </LinkButton>
      </StyledLinkButtonWrapper>
      <Button
        onClick={() => navigate('/document-management')}
        size="large"
        variant="primary"
        variantType="default"
      >
        <Icons name="IconArrowLeft" /> Voltar para a Gestão de documentos
      </Button>
    </>
  );
};
