import styled from 'styled-components';

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.m}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.m};
`;
