import { ReactNode } from 'react';
import { StyledContentCardContainer } from './styled';

type ContentCardProps = {
  children: ReactNode;
};

export const ContentCard = ({ children }: ContentCardProps) => (
  <StyledContentCardContainer>{children}</StyledContentCardContainer>
);
