import {
  Icons,
  Table,
  Tag,
  tableControllers,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ChangeEvent, ReactNode } from 'react';
import {
  StyledContainer,
  StyledSelectedHeaderContainer,
  StyledSelectedHeaderLinkButton,
} from './styled';
import { CustomFilters } from './components/CustomFilters';

const { useTableColumns } = tableControllers;

type RowData = object & {
  subRows?: Array<RowData> | undefined;
};

export type SimpleTablePagination = {
  pageSize: number;
  pageNumber: number;
  total: number;
};

export type SimpleTableColumns<T extends RowData> = Parameters<
  typeof useTableColumns<T>
>[0]['columns'];

type SimpleTableData<T extends RowData> = Parameters<
  typeof useTableColumns<T>
>[0]['data'];

type SimpleTableOptions<T extends RowData> = Parameters<
  typeof useTableColumns<T>
>[0]['options'];

type SimpleTableProps<T extends RowData> = {
  searchPlaceholder: string;
  searchValue?: string;
  isLoading?: boolean;
  onSearchChange: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  filters?: JSX.Element[];
  pagination?: SimpleTablePagination;
  onPaginationChange?: (
    pagination: Omit<SimpleTablePagination, 'total'>,
  ) => void;
  columns: SimpleTableColumns<T>;
  data?: SimpleTableData<T>;
  options?: SimpleTableOptions<T>;
  showPagination?: boolean;
  showSearch?: boolean;
  emptyState?: ReactNode;
  totalSelectedRows?: number;
  selectCheckboxHeader?: ReactNode;
  selectedTableHeaderActions?: {
    disabled?: boolean;
    iconName?: Parameters<typeof Icons>[0]['name'];
    label: string;
    onClick: () => void;
  }[];
};

export function SimpleTable<T extends RowData>({
  isLoading,
  searchValue,
  searchPlaceholder,
  onSearchChange,
  filters,
  pagination = { pageNumber: 0, pageSize: 0, total: 0 },
  onPaginationChange = () => undefined,
  columns,
  data = [],
  options,
  showPagination,
  showSearch,
  emptyState,
  selectedTableHeaderActions,
  totalSelectedRows,
  selectCheckboxHeader,
}: SimpleTableProps<T>) {
  const table = useTableColumns<T>({
    columns,
    data,
    onPaginationChange,
    pagination,
    options,
  });

  const showEmptyState =
    data.length === 0 && !isLoading && emptyState && !searchValue;

  return (
    <StyledContainer>
      <Table.Root>
        {showSearch && (
          <Table.Search
            label={searchPlaceholder}
            value={searchValue}
            onChange={onSearchChange}
          />
        )}
        {filters?.length && <CustomFilters filters={filters} />}
        {!showEmptyState ? (
          <div data-pendo="document-management-table">
            <Table.Grid.Root loading={isLoading}>
              {totalSelectedRows && selectCheckboxHeader ? (
                <tr
                  className="data-grid-table-header-bulk-actions-container"
                  role="row"
                >
                  <th className="data-grid-table-header-bulk-actions">
                    {selectCheckboxHeader}
                  </th>
                  <th className="data-grid-table-header-bulk-actions">
                    {selectedTableHeaderActions?.length && (
                      <StyledSelectedHeaderContainer>
                        <Tag as="span" variant="primary">
                          {totalSelectedRows} de {data.length} selecionados
                        </Tag>
                        {selectedTableHeaderActions.map(
                          ({ label, onClick, iconName, disabled }) => (
                            <StyledSelectedHeaderLinkButton
                              disabled={disabled}
                              variant="neutral"
                              onClick={onClick}
                            >
                              {iconName && <Icons name={iconName} />}
                              {label}
                            </StyledSelectedHeaderLinkButton>
                          ),
                        )}
                      </StyledSelectedHeaderContainer>
                    )}
                  </th>
                </tr>
              ) : (
                <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
              )}
              {table.rows.map((row, index) => (
                <Table.Grid.Row key={index} row={row} />
              ))}
            </Table.Grid.Root>
          </div>
        ) : (
          emptyState
        )}
        {showPagination && (
          <Table.Pagination
            count={pagination.total}
            onPaginationChange={onPaginationChange}
            pagination={pagination}
            pageSizeOptions={[
              {
                label: '10 itens',
                value: 10,
              },
              {
                label: '25 itens',
                value: 25,
              },
              {
                label: '50 itens',
                value: 50,
              },
              {
                label: '100 itens',
                value: 100,
              },
            ]}
          />
        )}
      </Table.Root>
    </StyledContainer>
  );
}
