import { SimpleTable } from '../../../../../../components/SimpleTable';
import { trpc } from '../../../../../../api/client';
import { DocumentTypeCell } from './components/DocumentTypeCell';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useTableQueryParams } from '../../../../../../hooks/useTableQueryParams';
import { TableViewEnum } from 'src/pages/DocumentsPage/constants';
import { ActionTrackingEventEnum, SEARCH_INPUT_DELAY } from 'src/constants';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { DocumentTableQueryParams } from 'src/components/DocumentTable/context/DocumentTableContext';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { useEffect } from 'react';

type DocumentType = {
  id: string;
  name: string;
  description?: string;
};

type DocumentTypeTableQueryParams = {
  searchTerm?: string;
};

export const DocumentTypeTable = () => {
  const { selectedCompany } = useSelectedCompany();
  const { tableQueryParams, setTableQueryParams } =
    useTableQueryParams<DocumentTypeTableQueryParams>();
  const searchTerm = useDebouncedValue<string | undefined>(
    tableQueryParams.searchTerm,
    SEARCH_INPUT_DELAY,
  );
  const { trackEvent } = useEventTracking();
  useEffect(() => {
    if (searchTerm) {
      trackEvent({
        name: ActionTrackingEventEnum.TABLE_SEARCH_USED,
        params: { value: searchTerm },
      });
    }
  }, [searchTerm]);

  const { setTableView } = useTableQueryParams<DocumentTableQueryParams>();

  const { data, isLoading } = trpc.findDocumentTypes.useQuery(
    {
      searchTerm,
      companyId: selectedCompany.id,
    },
    { refetchOnWindowFocus: false, cacheTime: 0 },
  );

  return (
    <SimpleTable<DocumentType>
      showSearch={true}
      isLoading={isLoading}
      columns={[
        {
          header: 'Tipo do documento',
          accessorKey: 'name',
          cell: (context) => (
            <DocumentTypeCell
              onClick={() =>
                setTableView(TableViewEnum.DOCUMENT, {
                  documentTypeIds: `${context.row.original.id}`,
                })
              }
              name={context.row.original.name}
              description={context.row.original.description}
            />
          ),
        },
      ]}
      options={{
        actions: (context) => [
          {
            label: 'Ver documentos',
            icon: 'IconEye',
            key: 'view',
            onClick: () =>
              setTableView(TableViewEnum.DOCUMENT, {
                documentTypeIds: `${context.row.original.id}`,
              }),
          },
          // {
          //   label: 'Baixar documentos',
          //   icon: 'IconFileZip',
          //   key: 'download',
          //   onClick: () => console.log(context.row.original.id),
          // },
        ],
      }}
      onSearchChange={(e) =>
        setTableQueryParams({
          ...tableQueryParams,
          searchTerm: e?.target.value,
        })
      }
      searchPlaceholder="Buscar por tipo de documento"
      searchValue={tableQueryParams.searchTerm}
      data={data?.documentTypes}
    />
  );
};
