import {
  Icons,
  IconsProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StyledButton } from './styled';
import { useTheme } from 'styled-components';

export type ButtonProps = {
  text: string;
  onClick: () => void;
  iconName: IconsProps['name'];
  isSelected: boolean;
};

export const ButtonSelect = ({
  iconName,
  onClick,
  text,
  isSelected,
}: ButtonProps) => {
  const theme = useTheme();
  const textColor = isSelected
    ? theme.colors.secondary[40]
    : theme.colors.neutral[40];
  return (
    <StyledButton onClick={onClick} isSelected={isSelected}>
      <Icons size={16} color={textColor} name={iconName} />
      <Typography variant="body3" variantColor={textColor} weight={700}>
        {text}
      </Typography>
    </StyledButton>
  );
};
