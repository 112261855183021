import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import { StyledContainer } from './styled';
import { ButtonProps, ButtonSelect } from './components/SelectButton';

type ButtonSelectProps = {
  label: string;
  buttons: ButtonProps[];
};

export const MultiButtonSelect = ({ label, buttons }: ButtonSelectProps) => {
  const theme = useTheme();
  return (
    <StyledContainer data-pendo="document-management-document-types">
      <Typography
        variant="body3"
        variantColor={theme.colors.neutral[40]}
        weight={600}
      >
        {label}
      </Typography>
      {buttons.map((props, idx) => (
        <ButtonSelect {...props} key={idx} />
      ))}
    </StyledContainer>
  );
};
