import { MultipleDocumentUploadContextProvider } from './context/MultipleDocumentUploadContext';
import { Steps } from './components/Steps';
import { useEffect } from 'react';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { PageTrackingNameEnum } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { getAccessTokenPayloadSync } from '@flash-tecnologia/hros-web-utility';
import { useIsAdmin } from 'src/hooks/useIsAdmin';

export const AddMultipleDocumentsPage = () => {
  const { trackPage } = useEventTracking();
  const navigate = useNavigate();
  const { employeeId: userEmployeeId } = getAccessTokenPayloadSync();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (isAdmin) {
      trackPage({ name: PageTrackingNameEnum.ADD_MULTIPLE_DOCUMENTS });
    } else {
      navigate(`/document-management/employee/${userEmployeeId}`);
    }
  }, []);

  return (
    <MultipleDocumentUploadContextProvider>
      <Steps />
    </MultipleDocumentUploadContextProvider>
  );
};
