import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import { StyledAside } from './styled';

export const Aside = () => {
  const theme = useTheme();

  return (
    <StyledAside>
      <Typography
        variant="headline7"
        variantColor={theme.colors.secondary[50]}
        weight={700}
      >
        Informações do documento
      </Typography>
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral[50]}
        weight={400}
      >
        Preencha as informações para associar o documento a uma pessoa, além das
        informações complementares, que são opcionais.
      </Typography>
    </StyledAside>
  );
};
