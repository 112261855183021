import { useNavigate, useParams } from 'react-router-dom';
import { StyledPageContainer } from '../../components/StyledPageContainer';
import { trpc } from '../../api/client';
import { EmployeeHeader } from './components/EmployeeHeader';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { Table } from './components/Table';
import { LoadingSkeleton } from './components/LoadingSkeleton';
import { MultiDocumentDownloadMenu } from 'src/components/MultiDocumentDownloadMenu';
import { MultiDocumentDownloadContextProvider } from 'src/components/MultiDocumentDownloadMenu/context/MultiDocumentDownloadContext';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { useEffect } from 'react';
import { PageTrackingNameEnum } from 'src/constants';

export const EmployeeDocumentsPage = () => {
  const { employeeId } = useParams();
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const { trackPage } = useEventTracking();

  useEffect(() => {
    trackPage({
      name: PageTrackingNameEnum.ADD_MULTIPLE_DOCUMENTS,
      params: {
        ...(employeeId && { employeeId }),
      },
    });
  }, []);

  const {
    data: employeeData,
    isLoading: isFindEmployeeByIdLoading,
    isError,
  } = trpc.findEmployeeById.useQuery(
    {
      employeeId: employeeId || '',
      companyId: selectedCompany.id,
    },
    { refetchOnWindowFocus: false, cacheTime: 0 },
  );

  if (isError) {
    navigate('/');
    return <></>;
  }

  return (
    <StyledPageContainer>
      {!isFindEmployeeByIdLoading && employeeData ? (
        <>
          <MultiDocumentDownloadContextProvider>
            <EmployeeHeader
              employeeId={employeeData.id}
              employeeName={employeeData.name}
              employeeProfilePictureSignedUrl={
                employeeData.profilePictureSignedUrl
              }
              employeeRoleName={employeeData.roleName}
              employeeStatus={employeeData.status}
            />
            <Table />
            <MultiDocumentDownloadMenu />
          </MultiDocumentDownloadContextProvider>
        </>
      ) : (
        <LoadingSkeleton />
      )}
    </StyledPageContainer>
  );
};
