import {
  Button,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledContentContainer,
  StyledContentHeaderContainer,
  StyledFooterContainer,
  StyledFooterLeftContainer,
  StyledFooterRightContainer,
  StyledLinkButton,
} from './styled';
import { trpc } from '../../../../../../api/client';
import { showToast } from 'src/utils/showToast';
import { useDocumentTableContext } from '../../../../context/DocumentTableContext';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';
import { useState } from 'react';
import { SignatureInputGroup } from 'src/components/SignatureInputGroup';

type RequestDocumentSignatureModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  employeeId: string;
};

export const RequestDocumentSignatureModal = ({
  onClose,
  employeeId,
  onSuccess,
}: RequestDocumentSignatureModalProps) => {
  const theme = useTheme();
  const { selectedDocumentId } = useDocumentTableContext();
  const { trackEvent } = useEventTracking();

  const [selectedAdditionalEmployees, setSelectedAdditionalEmployees] =
    useState<{ id: string; label: string }[]>([]);

  const {
    mutate: requestDocumentSignature,
    isLoading: isRequestDocumentSignatureLoading,
  } = trpc.requestDocumentSignature.useMutation({
    onError: () => {
      showToast({
        type: 'error',
        title: 'Erro ao enviar documento',
        description:
          'Ocorreu um erro ao enviar o documento para Assinatura. Por favor, tente novamente.',
      });
    },
    onSuccess: () => {
      const toastTitle = selectedAdditionalEmployees.length
        ? 'Solicitações de assinatura enviadas!'
        : 'Solicitação de assinatura enviada!';

      const toastDescription = selectedAdditionalEmployees.length
        ? 'As pessoas selecionadas receberão um link por e-mail ou SMS com a solicitação para assinatura.'
        : `A pessoa receberá um link por e-mail ou SMS com a solicitação para assinatura.`;

      showToast({
        type: 'success',
        title: toastTitle,
        description: toastDescription,
      });
      onClose();
      onSuccess();
    },
  });

  const isActionsDisabled = isRequestDocumentSignatureLoading;

  return (
    <Modal.Root size="md" open={true} onClose={onClose}>
      <>
        <Modal.Content>
          <StyledContentContainer>
            <StyledContentHeaderContainer>
              <ShapeIcon
                variant={'default'}
                size={48}
                color={theme.colors.secondary[50]}
                name={'IconSignature'}
              />
              <Typography
                variant="headline8"
                weight={700}
                variantColor={theme.colors.neutral[10]}
              >
                Enviar documento para assinatura
              </Typography>
              <Typography
                variant="body4"
                weight={400}
                variantColor={theme.colors.neutral[40]}
              >
                A pessoa titular do documento e outras pessoas relacionadas que
                você selecionar, receberão e-mail solicitando a realização da
                assinatura para garantir a autenticidade do documento.
              </Typography>
            </StyledContentHeaderContainer>

            <SignatureInputGroup
              employeeId={employeeId}
              selectedAdditionalEmployees={selectedAdditionalEmployees}
              onSelectedAdditionalEmployeesChange={(
                updatedSelectedAdditionalEmployees,
              ) =>
                setSelectedAdditionalEmployees(
                  updatedSelectedAdditionalEmployees,
                )
              }
            />
          </StyledContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <StyledFooterContainer>
            <StyledFooterLeftContainer>
              <StyledLinkButton
                disabled={isActionsDisabled}
                variant="default"
                onClick={onClose}
              >
                Cancelar
              </StyledLinkButton>
            </StyledFooterLeftContainer>
            <StyledFooterRightContainer>
              <Button
                disabled={isActionsDisabled}
                size="medium"
                variant="primary"
                onClick={() => {
                  trackEvent({
                    name: ActionTrackingEventEnum.REQUEST_DOCUMENT_SIGNATURE_CONFIRMATION_MODAL_CTA_CLICKED,
                  });
                  requestDocumentSignature({
                    documentId: selectedDocumentId!,
                    signerEmployeeIds: [
                      employeeId,
                      ...selectedAdditionalEmployees.map(({ id }) => id),
                    ],
                  });
                }}
                loading={isRequestDocumentSignatureLoading}
              >
                Solicitar assinatura
                {selectedAdditionalEmployees.length ? 's' : ''}
              </Button>
            </StyledFooterRightContainer>
          </StyledFooterContainer>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
