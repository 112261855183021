import {
  LinkButton,
  NotificationCard,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useState } from 'react';
import { useDocumentTableContext } from 'src/components/DocumentTable/context/DocumentTableContext';
import { DocumentSignatureModal } from '../../../DocumentSignatureModal';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const DocumentSignatureWarningCard = () => {
  const {
    findDocumentByIdData,
    refetchDocuments,
    refetchFindDocumentById,
    refetchGetBigNumbers,
  } = useDocumentTableContext();
  const [isVisible, setIsVisible] = useState(true);
  const [isSignDocumentModalVisible, setIsSignDocumentModalVisible] =
    useState(false);
  const { trackEvent } = useEventTracking();

  if (!isVisible || !findDocumentByIdData) return <></>;
  return (
    <>
      <NotificationCard.Root
        variant="outlined"
        type="error"
        isVertical={true}
        showCloseButton={true}
        onClose={() => setIsVisible(false)}
      >
        <NotificationCard.Icon iconName="IconSignature" size={48} />
        <NotificationCard.Title>
          Documento aguardando assinatura
        </NotificationCard.Title>
        <NotificationCard.Subtitle>
          Este documento aguarda sua assinatura para verificar a autenticidade.{' '}
          {findDocumentByIdData.expirationDate
            ? `O prazo para assinatura é ${dayjs(
                findDocumentByIdData.expirationDate,
              ).format('DD/MM/YYYY')}.`
            : ''}
        </NotificationCard.Subtitle>
        <LinkButton
          variant="error"
          onClick={() => {
            trackEvent({
              name: ActionTrackingEventEnum.DRAWER_SIGNATURE_WARNING_BUTTON_CLICKED,
            });
            setIsSignDocumentModalVisible(true);
          }}
        >
          Assinar documento
        </LinkButton>
      </NotificationCard.Root>

      {isSignDocumentModalVisible && (
        <DocumentSignatureModal
          onSuccess={() => {
            refetchDocuments();
            refetchGetBigNumbers();
            refetchFindDocumentById();
            setIsSignDocumentModalVisible(false);
          }}
          onClose={() => {
            setIsSignDocumentModalVisible(false);
          }}
        />
      )}
    </>
  );
};
