import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledContentHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledSignatureTitleContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledSignatureTitleLeftContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledSignatureTitleRightContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: end;
  justify-content: end;
`;

export const StyledFooterContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledFooterLeftContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledFooterRightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledLinkButton = styled(LinkButton)`
  align-self: center !important;
`;

export const StyledExtraSignersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
`;
