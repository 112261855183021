import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.s};
  padding: ${({ theme }) => theme.spacings.m};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[80]};
`;

export const StyledCardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledCardHeaderContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 9;
  gap: ${({ theme }) => theme.spacings.xs2};
  max-width: 70%;
`;

export const StyledCardHeaderContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1;
`;

export const StyledDocumentOwnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledIcon = styled(Icons)`
  border-color: ${({ theme }) => theme.colors.neutral[40]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  padding: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledCardInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.s};
  width: 100%;
`;

export const StyledDocumentTagContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledDocumentSignersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledFileName = styled(Typography)`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: 100%;
`;
