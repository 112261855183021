import { useMemo } from 'react';
import { StyledDocumentThumb, StyledIcon } from './styled';
import { iconsOptions } from '@flash-tecnologia/hros-web-ui-v2';

const Icons: { [key: string]: string } = {
  'application/pdf': 'IconFileTypePdf',
  default: 'IconFileDefault',
};

type DocumentThumbProps = {
  type: Blob['type'];
};

export const DocumentThumb = ({ type }: DocumentThumbProps) => {
  const iconType = useMemo(
    () => Icons[type] || Icons['default'],
    [type],
  ) as (typeof iconsOptions)[number];

  return (
    <StyledDocumentThumb>
      <StyledIcon name={iconType} size={16} />
    </StyledDocumentThumb>
  );
};
