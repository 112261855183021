import { BigNumber } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledBigNumber = styled(BigNumber)`
  width: 100%;
  max-width: 100%;
`;
