import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledHeaderContentContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 5;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledHeaderContentContainerRight = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 5;
`;

export const StyledEmployeeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledEmployeeNameAndStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledEmployeeRoleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledHeaderActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledIconButton = styled(IconButton)`
  align-self: center !important;
`;
