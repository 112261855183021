import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  FileTypeIconContainer,
  OpenFileButtonContainer,
  StyledContainer,
  StyledIconButton,
  TextContainer,
} from './styled';
import { useTheme } from 'styled-components';

type PDFPreviewDisplayArgs = {
  fileSignedUrl: string;
};

export const PDFPreviewDisplay = ({ fileSignedUrl }: PDFPreviewDisplayArgs) => {
  const theme = useTheme();
  return (
    <StyledContainer>
      <FileTypeIconContainer>
        <ShapeIcon
          variant="default"
          color={theme.colors.primary}
          size={48}
          name={'IconFileTypePdf'}
        />
      </FileTypeIconContainer>

      <TextContainer>
        <Typography
          variant="headline8"
          weight={700}
          variantColor={theme.colors.neutral[30]}
        >
          Ver arquivo
        </Typography>
        <Typography
          variant="body4"
          weight={400}
          variantColor={theme.colors.neutral[50]}
        >
          Arquivo em formato PDF. Clique no botão ao lado para visualizar.
        </Typography>
      </TextContainer>

      <OpenFileButtonContainer>
        <StyledIconButton
          onClick={() => open(fileSignedUrl, '_blank')}
          size="medium"
          variant="line"
          icon="IconEye"
        />
      </OpenFileButtonContainer>
    </StyledContainer>
  );
};
