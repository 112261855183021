import styled from 'styled-components';

export const StyledDropzoneWrapper = styled.div<{ error?: boolean }>`
  padding: ${({ theme }) => theme.spacings.xs};
  box-shadow: 0 0 0.1px 1px
    ${({ theme, error }) =>
      error ? theme.colors.feedback.error[50] : theme.colors.secondary[70]};
  width: 100%;
  min-height: 262px;
  border-radius: ${({ theme }) => theme.borders.radius.m};
  background-color: ${({ theme, error }) =>
    error ? theme.colors.feedback.error[90] : theme.colors.secondary[99]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};

  &:hover {
    box-shadow: 0 0 0.1px 3px
      ${({ theme, error }) =>
        error ? theme.colors.feedback.error[50] : theme.colors.primary};
    background-color: ${({ theme, error }) =>
      error ? theme.colors.feedback.error[90] : theme.colors.secondary[95]};
  }
`;

export const StyledDropzone = styled.label<{ error?: boolean }>`
  display: block;
  padding: ${({ theme }) => theme.spacings.xs} 0px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.feedback.error[70] : theme.colors.secondary[90]};
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const StyledDropzoneInput = styled.input`
  display: none;
`;

export const StyledDropzoneIconWrapper = styled.div<{ error?: boolean }>`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, error }) =>
    error ? theme.colors.feedback.error[70] : theme.colors.secondary[90]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
`;

export const StyledDropzoneTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledDropzoneSelectFilesWrapper = styled.div<{ error?: boolean }>`
  border-bottom: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors.feedback.error[40] : theme.colors.primary};
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 2px;
`;

export const StyledFileListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledFileListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledFileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
  max-height: 208px;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-right: ${({ theme }) => theme.spacings.xs};
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0 0 16px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
