import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs3};
  overflow: auto;
  white-space: nowrap;
  padding-bottom: ${({ theme }) => theme.spacings.xs5};
`;
