import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledCard,
  StyledExampleFileNameContainer,
  StyledFileIconContainer,
  StyledHorizontalLine,
  StyledInfoSection,
  StyledInstructionsContainer,
  StyledInstructionsTitleContainer,
  StyledListContainer,
} from './styled';
import { ListItem } from './components/ListItem';

export const AsideContent = () => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="headline7"
        variantColor={theme.colors.secondary[50]}
        weight={700}
      >
        Informações dos documentos
      </Typography>
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral[50]}
        weight={400}
      >
        Preencha o formulário ao lado para associar os documentos às pessoas da
        sua empresa e fazer o upload das fotos.
      </Typography>

      <StyledCard>
        <StyledInfoSection>
          <Typography
            variant="headline9"
            variantColor={theme.colors.secondary[50]}
            weight={700}
          >
            Como associar os arquivos às pessoas da empresa?
          </Typography>
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            Para associar os documentos às pessoas corretas, carregue as imagens
            com o CPF do membro da equipe no início do nome do arquivo, separado
            por um underline.
          </Typography>
        </StyledInfoSection>
        <StyledHorizontalLine />
        <Typography
          variant="headline10"
          variantColor={theme.colors.neutral[30]}
          weight={700}
        >
          Exemplo:
        </Typography>
        <StyledExampleFileNameContainer>
          <StyledFileIconContainer>
            <Icons
              name="IconPhoto"
              color={theme.colors.neutral[40]}
              width={14}
              height={14}
            />
          </StyledFileIconContainer>
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral[30]}
            weight={400}
          >
            01234567890_NomeArquivo.png
          </Typography>
        </StyledExampleFileNameContainer>
        <StyledHorizontalLine />
        <StyledInstructionsContainer>
          <StyledInstructionsTitleContainer>
            <Icons
              width={16}
              name="IconWritingSign"
              color={theme.colors.neutral[30]}
            />
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[30]}
              weight={700}
            >
              Formatação do nome do arquivo
            </Typography>
          </StyledInstructionsTitleContainer>
          <StyledListContainer>
            <ListItem>
              O nome do arquivo sempre deve ser iniciado com o CPF do membro da
              equipe;
            </ListItem>
            <ListItem>
              O formato do número do CPF não deve conter pontos, traços ou
              hífens;
            </ListItem>
            <ListItem>
              Caso queira adicionar mais algum termo ao nome do arquivo, utilize
              o underline _ como separador entre o número do CPF e o texto
              adicional.
            </ListItem>
            <ListItem>
              Caso o documento seja de um dependente, é só selecionar esta opção
              no campo de Titularidade ao lado;
            </ListItem>
          </StyledListContainer>
        </StyledInstructionsContainer>
      </StyledCard>
    </>
  );
};
