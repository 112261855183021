import { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { StyledContainer } from './styled';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

type ListItemProps = {
  children: ReactNode;
};

export const ListItem = ({ children }: ListItemProps) => {
  const theme = useTheme();

  return (
    <StyledContainer>
      <Typography
        variant="caption"
        variantColor={theme.colors.neutral[50]}
        weight={600}
      >
        ●
      </Typography>
      <Typography
        variant="caption"
        variantColor={theme.colors.neutral[50]}
        weight={600}
      >
        {children}
      </Typography>
    </StyledContainer>
  );
};
