import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';

import { useTheme } from 'styled-components';
import { StyledCellContainer } from './styled';
import { DocumentContentTypeEnum } from 'src/constants';

type DocumentContentTypeCellProps = {
  contentType: DocumentContentTypeEnum;
};

const contentTypeIconMap = {
  [DocumentContentTypeEnum.PDF]: 'IconFileTypePdf',
  [DocumentContentTypeEnum.PNG]: 'IconFileTypePng',
  [DocumentContentTypeEnum.JPEG]: 'IconFileTypeJpg',
  [DocumentContentTypeEnum.JPG]: 'IconFileTypeJpg',
} as const;

const contentTypeNameMap = {
  [DocumentContentTypeEnum.PDF]: 'PDF',
  [DocumentContentTypeEnum.PNG]: 'PNG',
  [DocumentContentTypeEnum.JPEG]: 'JPEG',
  [DocumentContentTypeEnum.JPG]: 'JPG',
} as const;

export const DocumentContentTypeCell = ({
  contentType,
}: DocumentContentTypeCellProps) => {
  const theme = useTheme();
  return (
    <StyledCellContainer>
      <ShapeIcon
        variant="default"
        name={contentTypeIconMap[contentType]}
        size={40}
        color={theme.colors.secondary[50]}
      />
      <Typography variant="body3" weight={600}>
        {contentTypeNameMap[contentType]}
      </Typography>
    </StyledCellContainer>
  );
};
