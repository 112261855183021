import { ReactNode } from 'react';
import { StyledContainer } from './styled';

type ContainerType = {
  children: ReactNode;
};

export const Container = ({ children }: ContainerType) => {
  return <StyledContainer>{children}</StyledContainer>;
};
