import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledDescriptionTypography = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
