import { Container } from 'src/components/Container';
import { Aside } from './components/Aside';
import { Footer } from './components/Footer';
import { Form } from './components/Form';
import { AddDocumentProvider } from './context/add-document-form-context';
import { FormContainer, StyledTitleSection } from './styled';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { useEffect } from 'react';
import { PageTrackingNameEnum } from 'src/constants';

export const AddDocumentPage = () => {
  const theme = useTheme();
  const { trackPage } = useEventTracking();

  useEffect(() => {
    trackPage({ name: PageTrackingNameEnum.ADD_DOCUMENT });
  }, []);

  return (
    <>
      <AddDocumentProvider>
        <Container>
          <StyledTitleSection>
            <Typography
              variant="headline6"
              variantColor={theme.colors.neutral[20]}
              weight={700}
            >
              Adicionar documento
            </Typography>
          </StyledTitleSection>
          <FormContainer>
            <Aside />
            <Form />
          </FormContainer>
        </Container>
        <Footer />
      </AddDocumentProvider>
    </>
  );
};
