import { useTheme } from 'styled-components';
import { StyledRequiredSymbol, StyledTextContainer } from './styled';
import { SelectField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { trpc } from 'src/api/client';
import { DocumentOwnerEnum } from 'src/constants';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';
import { useCallback, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { ContentCard } from 'src/pages/AddMultipleDocumentsPage/components/ContentCard';
import { Dropzone } from 'src/components/Dropzone';
import { showToast } from 'src/utils/showToast';
import { checkIfAllInputFilesAreValid } from 'src/components/Dropzone/utils';

export const DocumentsForm = () => {
  const { selectedCompany } = useSelectedCompany();
  const {
    documentInfo,
    setDocumentInfo,
    setDocuments,
    documentList,
    inputValidationErrors,
  } = useMultipleDocumentUploadContext();
  const theme = useTheme();

  const { data: findDocumentTypesData } = trpc.findDocumentTypes.useQuery({
    companyId: selectedCompany.id,
  });

  const documentTypeSelectOptions = useMemo(
    () =>
      findDocumentTypesData?.documentTypes.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [findDocumentTypesData],
  );

  const documentTypeSelectValue = useMemo(
    () =>
      documentTypeSelectOptions?.find(
        ({ value }) => value === documentInfo.documentTypeId,
      ),
    [findDocumentTypesData, documentInfo.documentTypeId],
  );

  const documentOwnerSelectOptions = useMemo(
    () => [
      { label: 'Titular', value: DocumentOwnerEnum.EMPLOYEE },
      { label: 'Dependente', value: DocumentOwnerEnum.DEPENDENT },
    ],
    [],
  );

  const documentOwnerSelectValue = useMemo(
    () =>
      documentOwnerSelectOptions?.find(
        ({ value }) => value === documentInfo.documentOwner,
      ),
    [documentInfo.documentOwner],
  );

  const extractDocumentNumberFromFileName = (fileName: string) =>
    fileName.split('_')[0];

  const onChangeHandler = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const inputFiles = Array.from(e?.target?.files || []);
      if (!checkIfAllInputFilesAreValid(inputFiles)) return;
      if (inputFiles?.length) {
        setDocuments((prev) => ({
          ...prev,
          ...inputFiles.reduce((acc, file) => {
            const documentFileId = nanoid();
            return {
              ...acc,
              [documentFileId]: {
                file,
                progress: 0,
                documentNumber: extractDocumentNumberFromFileName(file.name),
                id: documentFileId,
              },
            };
          }, {}),
        }));
      }
    },
    [documentList],
  );

  const onRemovedDropzoneFile = (id: string) => {
    setDocuments((prev) => {
      const updatedDocuments = Object.fromEntries(
        Object.entries(prev).filter(([key]) => key !== id),
      );
      return updatedDocuments;
    });

    showToast({
      type: 'success',
      title: 'Documento removido da lista com sucesso.',
    });
  };

  return (
    <ContentCard>
      <StyledTextContainer>
        <Typography
          variant="headline8"
          variantColor={theme.colors.neutral[30]}
          weight={700}
        >
          Fotos dos documentos<StyledRequiredSymbol>*</StyledRequiredSymbol>
        </Typography>
        <Typography
          variant="body4"
          variantColor={theme.colors.neutral[50]}
          weight={400}
        >
          Selecione o tipo do documento e carregue uma ou mais imagens
        </Typography>
      </StyledTextContainer>
      <SelectField
        label="Tipo do documento"
        searchable
        fullWidth
        helperText={
          !!inputValidationErrors.documentTypeId
            ? inputValidationErrors.documentTypeId
            : ''
        }
        error={!!inputValidationErrors.documentTypeId}
        value={documentTypeSelectValue}
        onSelectChange={(_, e) =>
          setDocumentInfo((prev) => ({ ...prev, documentTypeId: e?.value }))
        }
        options={documentTypeSelectOptions}
      />
      <SelectField
        label="Titularidade"
        helperText={
          !!inputValidationErrors.documentOwner
            ? inputValidationErrors.documentOwner
            : 'Identifique se esses documentos são de titulares ou dependentes'
        }
        searchable
        fullWidth
        error={!!inputValidationErrors.documentOwner}
        value={documentOwnerSelectValue}
        onSelectChange={(_, e) =>
          setDocumentInfo((prev) => ({ ...prev, documentOwner: e?.value }))
        }
        options={documentOwnerSelectOptions}
      />
      <Dropzone
        onChange={onChangeHandler}
        accept={['jpeg', 'pdf', 'png', 'jpg']}
        files={documentList}
        onRemove={onRemovedDropzoneFile}
        errorMessage={inputValidationErrors.documents}
      />
    </ContentCard>
  );
};
