import { StyledLinkButtonWrapper } from './styled';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';

export const FooterContent = () => {
  const { setIsCancelUploadConfirmationModalVisible } =
    useMultipleDocumentUploadContext();

  return (
    <>
      <StyledLinkButtonWrapper>
        <LinkButton
          variant="neutral"
          onClick={() => setIsCancelUploadConfirmationModalVisible(true)}
        >
          Cancelar
        </LinkButton>
      </StyledLinkButtonWrapper>
      <Button disabled size="large" variant="primary" variantType="default">
        <Icons name="IconArrowLeft" /> Voltar para a Gestão de documentos
      </Button>
    </>
  );
};
