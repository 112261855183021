import { useTheme } from 'styled-components';
import {
  StyledContainer,
  StyledFileImage,
  StyledFileInfoContainer,
  StyledFileNameTypography,
  StyledFileRow,
  StyledProgressContainer,
  StyledProgressNumberTypography,
  StyledSpinnerWrapper,
  StyledStatusContainer,
  StyledStatusIconShapeIcon,
  StyledStatusTextTypography,
  StyledTextWrapper,
} from './styled';
import {
  ProgressBar,
  Skeleton,
  Spinner,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';
import { memo, useEffect, useMemo } from 'react';
import { usePagination } from 'src/hooks/usePagination';
import { DocumentThumb } from 'src/components/Dropzone/components/DocumentThumb';
import { useImageResize } from 'src/hooks/useImageResize';

type GetStatusIconArgs = {
  progress: number;
  errorMessage?: string;
};

type FileRowProps = {
  file: File;
  progress: number;
  errorMessage?: string;
  statusText?: string;
};

const FileRow = memo(
  ({ file, progress, errorMessage, statusText }: FileRowProps) => {
    const theme = useTheme();
    const { resizeImage, resizedImageUrl } = useImageResize();

    useEffect(() => {
      if (file && file.type.startsWith('image/')) {
        resizeImage(file, 50, 50, 1);
      }
    }, [file]);

    const thumb = useMemo(() => {
      if (file.type.startsWith('image/')) {
        return resizedImageUrl ? (
          <StyledFileImage
            loading="lazy"
            decoding="async"
            src={resizedImageUrl}
          />
        ) : (
          <Skeleton
            style={{ minHeight: '32px', minWidth: '32px' }}
            animation="pulse"
            variant="rounded"
            height={32}
            width={32}
          />
        );
      }

      return <DocumentThumb type={file.type} />;
    }, [file, resizedImageUrl]);

    const progressBar = useMemo(() => {
      if (errorMessage) return 'error';
      if (progress === 100) return 'success';
      return 'default';
    }, [progress, errorMessage]);

    const getStatusIcon = ({ progress, errorMessage }: GetStatusIconArgs) => {
      if (errorMessage)
        return (
          <StyledStatusIconShapeIcon
            name="IconAlertTriangle"
            variant="error"
            size={24}
            color={theme.colors.feedback.error[40]}
          />
        );
      if (progress === 100)
        return (
          <StyledStatusIconShapeIcon
            name="IconCircleCheck"
            variant="success"
            size={24}
            color={theme.colors.feedback.success[40]}
          />
        );
      return (
        <StyledSpinnerWrapper>
          <Spinner size={24} />
        </StyledSpinnerWrapper>
      );
    };

    return (
      <StyledFileRow>
        <StyledFileInfoContainer>
          {thumb}
          <StyledTextWrapper>
            <StyledFileNameTypography
              variant="body3"
              variantColor={theme.colors.neutral[30]}
              weight={400}
            >
              <Tooltip title={file.name}>
                <span>{file.name}</span>
              </Tooltip>
            </StyledFileNameTypography>
          </StyledTextWrapper>
        </StyledFileInfoContainer>

        <StyledProgressContainer>
          <ProgressBar hideLabel progress={progress} variant={progressBar} />
          <StyledProgressNumberTypography
            variant="caption"
            variantColor={theme.colors.neutral[20]}
            weight={400}
          >
            {progress}%
          </StyledProgressNumberTypography>
        </StyledProgressContainer>

        <StyledStatusContainer>
          {progress !== 0 && (
            <>
              {getStatusIcon({ progress, errorMessage })}
              {statusText && (
                <StyledTextWrapper>
                  <StyledStatusTextTypography
                    variant="body4"
                    variantColor={theme.colors.neutral[30]}
                    weight={600}
                  >
                    <Tooltip title={statusText}>
                      <span>{statusText}</span>
                    </Tooltip>
                  </StyledStatusTextTypography>
                </StyledTextWrapper>
              )}
            </>
          )}
        </StyledStatusContainer>
      </StyledFileRow>
    );
  },
);

export const FileList = () => {
  const { documentList } = useMultipleDocumentUploadContext();
  const { listRef, visibleItems } = usePagination({
    items: documentList,
    itemsPerPage: 10,
  });

  return (
    <StyledContainer ref={listRef}>
      {visibleItems.map(
        ({ id, file, progress, errorMessage, successMessage }) => {
          const statusText = errorMessage ? errorMessage : successMessage;

          return (
            <FileRow
              key={id}
              file={file}
              progress={progress}
              statusText={statusText}
              errorMessage={errorMessage}
            />
          );
        },
      )}
    </StyledContainer>
  );
};
