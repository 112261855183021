import styled from 'styled-components';

export const StyledContentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.s};
  padding: ${({ theme }) => theme.spacings.m};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;
