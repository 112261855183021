import { SimpleTable } from '../../../../../../components/SimpleTable';
import { useTableQueryParams } from '../../../../../../hooks/useTableQueryParams';
import { EmployeeStatusFilter } from './components/tableFilters/EmployeeStatusFilter';
import { useEffect, useState } from 'react';
import { trpc } from '../../../../../../api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { TagListCell } from './components/tableCells/TagListCell';
import { EmployeeRoleFilter } from './components/tableFilters/EmployeeRoleFilter';
import { EmployeeDepartmentFilter } from './components/tableFilters/EmployeeDepartmentFilter';
import { useNavigate } from 'react-router-dom';
import {
  ActionTrackingEventEnum,
  EmployeeStatusEnum,
  SEARCH_INPUT_DELAY,
} from '../../../../../../constants';
import { EmployeeStatusTag } from 'src/components/EmployeeStatusTag';
import { EmployeeIdentityDisplay } from '../../../../../../components/EmployeeIdentityDisplay';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { useMultiDocumentDownloadContext } from 'src/components/MultiDocumentDownloadMenu/context/MultiDocumentDownloadContext';
import { showToast } from 'src/utils/showToast';
import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import { useEventTracking } from 'src/hooks/useEventTracking';

type FormattedEmployee = {
  id: string;
  name: string;
  email?: string;
  status?: EmployeeStatusEnum;
  employeeProfilePictureSignedUrl?: string;
  totalDocuments: number;
  departmentNames: string[];
  roleName: string;
};

export type EmployeeTableQueryParams = {
  searchTerm?: string;
  employeeStatuses?: string;
  employeeRoleIds?: string;
  employeeDepartmentIds?: string;
};

export const EmployeeTable = () => {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const { tableQueryParams, setTableQueryParams } =
    useTableQueryParams<EmployeeTableQueryParams>();
  const searchTerm = useDebouncedValue<string | undefined>(
    tableQueryParams.searchTerm,
    SEARCH_INPUT_DELAY,
  );
  const { trackEvent } = useEventTracking();
  useEffect(() => {
    if (searchTerm) {
      trackEvent({
        name: ActionTrackingEventEnum.TABLE_SEARCH_USED,
        params: { value: searchTerm },
      });
    }
  }, [searchTerm]);
  const { createDocumentBundle, isDocumentBundleProcessing } =
    useMultiDocumentDownloadContext();
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1,
    pageSize: 10,
  });

  const { data: findEmployeesData, isLoading: isFindEmployeesLoading } =
    trpc.findEmployees.useQuery(
      {
        pageNumber: pagination.pageNumber,
        pageSize: pagination.pageSize,
        companyId: selectedCompany.id,
        ...tableQueryParams,
        searchTerm,
      },
      { refetchOnWindowFocus: false, cacheTime: 0 },
    );

  return (
    <SimpleTable<FormattedEmployee>
      showSearch={true}
      isLoading={isFindEmployeesLoading}
      filters={[
        <EmployeeStatusFilter />,
        <EmployeeDepartmentFilter />,
        <EmployeeRoleFilter />,
      ]}
      columns={[
        {
          header: 'Pessoa',
          accessorKey: 'name',
          cell: (context) => (
            <EmployeeIdentityDisplay
              email={context.row.original.email}
              name={context.row.original.name}
              profilePictureSignedUrl={
                context.row.original.employeeProfilePictureSignedUrl
              }
            />
          ),
        },
        {
          header: 'Quantidade de documentos',
          minSize: 280,
          accessorKey: 'totalDocuments',
        },
        {
          header: 'Status da pessoa',
          minSize: 200,
          accessorKey: 'status',
          cell: (context) => {
            const status = context.row.original.status;
            return status ? <EmployeeStatusTag status={status} /> : '-';
          },
        },
        {
          header: 'Departamento',
          minSize: 200,
          accessorKey: 'departmentNames',
          cell: (context) => (
            <TagListCell tagNames={context.row.original.departmentNames} />
          ),
        },
        {
          header: 'Cargo',
          minSize: 200,
          accessorKey: 'roleName',
          accessorFn: ({ roleName }) => roleName || '-',
        },
      ]}
      options={{
        actions: (context) => [
          {
            label: 'Ver documentos da pessoa',
            icon: 'IconEye',
            key: 'view',
            onClick: () => {
              trackEvent({
                name: ActionTrackingEventEnum.VIEW_EMPLOYEE_DOCUMENTS_CLICKED,
              });
              navigate(
                `/document-management/employee/${context.row.original.id}`,
              );
            },
          },
          {
            label: 'Adicionar documento a esta pessoa',
            icon: 'IconCloudUpload',
            key: 'upload',
            onClick: () => {
              trackEvent({
                name: ActionTrackingEventEnum.ADD_DOCUMENTS_TO_EMPLOYEE_CLICKED,
              });
              navigate(
                `/document-management/add-document?employeeId=${context.row.original.id}`,
              );
            },
          },
          ...(context.row.original.totalDocuments > 0
            ? [
                {
                  label: 'Baixar todos os documentos',
                  icon: 'IconFileZip' as IconsProps['name'],
                  key: 'download',
                  onClick: () => {
                    if (isDocumentBundleProcessing) {
                      showToast({
                        type: 'error',
                        title: 'Não foi possível baixar os documentos',
                        description:
                          'Já existem documentos em processamento, por favor aguarde e tente novamente.',
                      });
                    } else {
                      createDocumentBundle({
                        employeeId: context.row.original.id,
                      });
                    }
                  },
                },
              ]
            : []),
        ],
      }}
      onSearchChange={(e) =>
        setTableQueryParams({
          ...tableQueryParams,
          searchTerm: e?.target.value,
        })
      }
      searchPlaceholder="Buscar por pessoa"
      searchValue={tableQueryParams.searchTerm}
      data={findEmployeesData?.employees}
      pagination={{ ...pagination, total: findEmployeesData?.total || 0 }}
      onPaginationChange={({ pageNumber, pageSize }) =>
        setPagination((prev) => ({ ...prev, pageNumber, pageSize }))
      }
      showPagination={true}
    />
  );
};
