import {
  Icons,
  MultiSelect,
  SelectField,
  Skeleton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useMemo, useState } from 'react';
import { trpc } from 'src/api/client';
import {
  ADDITIONAL_DOCUMENT_SIGNERS_LIMIT,
  SEARCH_EMPLOYEE_LIMIT,
  SEARCH_INPUT_DELAY,
} from 'src/constants';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { showToast } from 'src/utils/showToast';
import { useTheme } from 'styled-components';
import {
  StyledContainer,
  StyledExtraSignersContainer,
  StyledLinkButton,
  StyledSignatureTitleContainer,
  StyledSignatureTitleLeftContainer,
  StyledSignatureTitleRightContainer,
} from './styled';

type SignatureInputGroupProps = {
  employeeId: string;
  selectedAdditionalEmployees: { id: string; label: string }[];
  onSelectedAdditionalEmployeesChange: (
    updatedSelectedAdditionalEmployees: { id: string; label: string }[],
  ) => void;
};

export const SignatureInputGroup = ({
  employeeId,
  selectedAdditionalEmployees,
  onSelectedAdditionalEmployeesChange,
}: SignatureInputGroupProps) => {
  const { selectedCompany } = useSelectedCompany();
  const theme = useTheme();

  const [
    isAdditionalSignersSelectVisible,
    setIsAdditionalSignersSelectVisible,
  ] = useState(false);

  const [multiSelectSearchValue, setMultiSelectSearchValue] = useState<
    string | undefined
  >();

  const searchTerm = useDebouncedValue<string | undefined>(
    multiSelectSearchValue,
    SEARCH_INPUT_DELAY,
  );

  const { data: employee, isLoading: isFindEmployeeByIdLoading } =
    trpc.findEmployeeById.useQuery(
      { companyId: selectedCompany.id, employeeId },
      { cacheTime: 0, refetchOnWindowFocus: false },
    );

  const { data: findEmployeesBasicInfoData } =
    trpc.findEmployeesBasicInfo.useQuery(
      {
        companyId: selectedCompany.id,
        query: searchTerm,
        limit: SEARCH_EMPLOYEE_LIMIT,
      },
      { cacheTime: 0, refetchOnWindowFocus: false },
    );

  const multiSelectOptions = useMemo(() => {
    const employees = findEmployeesBasicInfoData?.employees || [];

    return employees
      .map(({ id, name }) => ({ id, label: name }))
      .filter(({ id }) => id !== employeeId);
  }, [findEmployeesBasicInfoData, employeeId]);

  return (
    <StyledContainer>
      <StyledSignatureTitleContainer>
        <StyledSignatureTitleLeftContainer>
          <Typography
            variant="headline9"
            weight={700}
            variantColor={theme.colors.neutral[30]}
          >
            Assinaturas necessárias
          </Typography>
        </StyledSignatureTitleLeftContainer>
        <StyledSignatureTitleRightContainer>
          <Typography
            variant="body4"
            weight={400}
            variantColor={theme.colors.neutral[30]}
          >
            <span style={{ color: 'red' }}>*</span> campo obrigatório
          </Typography>
        </StyledSignatureTitleRightContainer>
      </StyledSignatureTitleContainer>
      {employee && !isFindEmployeeByIdLoading ? (
        <>
          <SelectField
            label="Nome do titular do documento"
            disabled
            fullWidth
            helperText={'A seleção do titular do documento é obrigatória'}
            value={{ value: employee.name, label: employee.name }}
            options={[{ value: employee.name, label: employee.name }]}
          />

          {isAdditionalSignersSelectVisible ? (
            <StyledExtraSignersContainer>
              <MultiSelect
                renderInput={() => <></>}
                onBlur={() => setMultiSelectSearchValue('')}
                onChange={(e) => setMultiSelectSearchValue(e.target.value)}
                value={selectedAdditionalEmployees}
                isOptionEqualToValue={(option) =>
                  !!selectedAdditionalEmployees.find(
                    ({ id }) => id === option.id,
                  )
                }
                onSelectChange={(_, selectedOptions) => {
                  if (
                    selectedOptions.length > ADDITIONAL_DOCUMENT_SIGNERS_LIMIT
                  ) {
                    showToast({
                      type: 'error',
                      title: `Limite de ${
                        ADDITIONAL_DOCUMENT_SIGNERS_LIMIT + 1
                      } assinaturas atingido`,
                      description: `É possível solicitar até ${
                        ADDITIONAL_DOCUMENT_SIGNERS_LIMIT + 1
                      } assinaturas em documentos PDF.`,
                    });
                    return;
                  }

                  onSelectedAdditionalEmployeesChange(selectedOptions);
                  setMultiSelectSearchValue('');
                }}
                fullWidth
                label={'Selecione outras pessoas para assinar o documento'}
                options={multiSelectOptions}
              />
              <Icons
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsAdditionalSignersSelectVisible(false);
                  onSelectedAdditionalEmployeesChange([]);
                }}
                color={theme.colors.primary}
                name={'IconTrash'}
                height={24}
                width={24}
              />
            </StyledExtraSignersContainer>
          ) : (
            <StyledLinkButton
              style={{ marginTop: '57px' }}
              onClick={() => setIsAdditionalSignersSelectVisible(true)}
              variant="default"
            >
              <Icons color={theme.colors.primary} name={'IconPlus'} /> Adicionar
              assinaturas
            </StyledLinkButton>
          )}
        </>
      ) : (
        <Skeleton height={135} width={'100%'} />
      )}
    </StyledContainer>
  );
};
