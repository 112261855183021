import { PageStructure } from '../../../PageStructure';
import { AsideContent } from './components/AsideContent';
import { FooterContent } from './components/FooterContent';
import { MainContent } from './components/MainContent';

export const SuccessStep = () => (
  <PageStructure
    AsideContent={<AsideContent />}
    MainContent={<MainContent />}
    FooterContent={<FooterContent />}
  />
);
