import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledFormGroup,
  StyledRequiredSymbol,
  StyledTitleWrapper,
  StyledRequiredLabelWrapper,
} from './styled';
import { useTheme } from 'styled-components';

type FormGroupType = {
  children: React.ReactNode;
  title: string;
  description: string;
  required?: boolean;
  showRequiredLabel?: boolean;
};

export const FormGroup = ({
  children,
  title,
  description,
  required,
  showRequiredLabel,
}: FormGroupType) => {
  const theme = useTheme();

  return (
    <StyledFormGroup>
      <StyledHeaderWrapper>
        <StyledHeader>
          <StyledTitleWrapper>
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral[30]}
              weight={700}
            >
              {title}
            </Typography>
            {required && <StyledRequiredSymbol>*</StyledRequiredSymbol>}
          </StyledTitleWrapper>
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            {description}
          </Typography>
        </StyledHeader>

        {showRequiredLabel && required && (
          <StyledRequiredLabelWrapper>
            <StyledRequiredSymbol>*</StyledRequiredSymbol>
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[30]}
              weight={400}
            >
              campo obrigatório
            </Typography>
          </StyledRequiredLabelWrapper>
        )}
      </StyledHeaderWrapper>
      {children}
    </StyledFormGroup>
  );
};
