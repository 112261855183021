export enum DocumentBundleStatusEnum {
  PROCESSING = 'PROCESSING',
  READY_FOR_DOWNLOAD = 'READY_FOR_DOWNLOAD',
  ERROR = 'ERROR',
}

export enum DocumentStatusEnum {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
}

export enum DocumentOwnerEnum {
  EMPLOYEE = 'EMPLOYEE',
  DEPENDENT = 'DEPENDENT',
}

export enum DocumentContentTypeEnum {
  PNG = 'image/png',
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}

export const documentStatusNameMap = {
  [DocumentStatusEnum.ACTIVE]: 'Ativo',
  [DocumentStatusEnum.EXPIRED]: 'Vencido',
  [DocumentStatusEnum.AWAITING_SIGNATURE]: 'Aguardando assinatura',
} as const;

export const documentOwnerNameMap = {
  [DocumentOwnerEnum.EMPLOYEE]: 'Titular',
  [DocumentOwnerEnum.DEPENDENT]: 'Dependente',
};

export const dayjsMonthNumberMap = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
} as const;

export enum EmployeeStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  INVITATION_SENT = 'INVITATION_SENT',
  INVITATION_PENDING = 'INVITATION_PENDING',
  INVITATION_SCHEDULED = 'INVITATION_SCHEDULED',
}

export const employeeStatusNameMap = {
  [EmployeeStatusEnum.ACTIVE]: 'Ativo',
  [EmployeeStatusEnum.INACTIVE]: 'Inativo',
  [EmployeeStatusEnum.DELETED]: 'Deletado',
  [EmployeeStatusEnum.INVITATION_EXPIRED]: 'Convite expirado',
  [EmployeeStatusEnum.INVITATION_PENDING]: 'Convite pendente',
  [EmployeeStatusEnum.INVITATION_SCHEDULED]: 'Convite agendado',
  [EmployeeStatusEnum.INVITATION_SENT]: 'Convite enviado',
};

export const employeeStatusTagVariantMap = {
  [EmployeeStatusEnum.ACTIVE]: 'success',
  [EmployeeStatusEnum.INACTIVE]: 'error',
  [EmployeeStatusEnum.DELETED]: 'gray',
  [EmployeeStatusEnum.INVITATION_EXPIRED]: 'gray',
  [EmployeeStatusEnum.INVITATION_PENDING]: 'gray',
  [EmployeeStatusEnum.INVITATION_SCHEDULED]: 'gray',
  [EmployeeStatusEnum.INVITATION_SENT]: 'gray',
};

export const SEARCH_INPUT_DELAY = 700;

export const SEARCH_EMPLOYEE_LIMIT = 250;

export const ADDITIONAL_DOCUMENT_SIGNERS_LIMIT = 4;

export enum CustomExceptionCodeEnum {
  DOCUMENT_BUNDLE_ALREADY_PROCESSING = 'DOCUMENT_BUNDLE_ALREADY_PROCESSING',
}

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

export enum PageTrackingNameEnum {
  ADD_DOCUMENT = 'add_document_page',
  ADD_MULTIPLE_DOCUMENTS = 'add_multiple_documents_page',
  DOCUMENTS = 'documents_page',
  EMPLOYEE_DOCUMENTS = 'employee_documents_page',
}

export enum ActionTrackingEventEnum {
  GO_TO_ADD_DOCUMENT_PAGE_BUTTON_CLICKED = 'go_to_add_document_page_button_clicked',
  GO_TO_ADD_MULTIPLE_DOCUMENTS_PAGE_BUTTON_CLICKED = 'go_to_add_multiple_documents_page_button_clicked',

  EXPIRED_DOCUMENTS_BIG_NUMBERS_FILTER_CLICKED = 'expired_documents_big_numbers_filter_clicked',
  DOCUMENTS_ABOUT_TO_EXPIRE_BIG_NUMBERS_FILTER_CLICKED = 'documents_about_to_expire_big_numbers_filter_clicked',
  DOCUMENTS_AWAITING_SIGNATURE_BIG_NUMBERS_FILTER_CLICKED = 'documents_awaiting_signature_big_numbers_filter_clicked',

  EMPLOYEE_TABLE_VIEW_CLICKED = 'employee_table_view_clicked',
  DOCUMENT_TYPE_TABLE_VIEW_CLICKED = 'document_type_table_view_clicked',
  DOCUMENTS_TABLE_VIEW_CLICKED = 'documents_table_view_clicked',

  TABLE_SEARCH_USED = 'table_search_used',
  TABLE_FILTER_USED = 'table_filter_used',
  TABLE_DELETE_SELECTED_DOCUMENTS_CONFIRMATION_MODAL_CTA_CLICKED = 'table_delete_selected_documents_confirmation_modal_cta_clicked',
  TABLE_DOWNLOAD_SELECTED_DOCUMENTS_BUTTON_CLICKED = 'table_download_selected_documents_button_clicked',

  EMPTY_STATE_ADD_DOCUMENT_BUTTON_CLICKED = 'empty_state_add_document_button_clicked',

  VIEW_EMPLOYEE_DOCUMENTS_CLICKED = 'view_employee_documents_clicked',
  ADD_DOCUMENTS_TO_EMPLOYEE_CLICKED = 'add_documents_to_employee_clicked',

  VIEW_DOCUMENT_CLICKED = 'view_document_clicked',
  DOWNLOAD_DOCUMENT_CLICKED = 'download_document_clicked',
  REQUEST_DOCUMENT_SIGNATURE_CLICKED = 'request_document_signature_clicked',
  DELETE_DOCUMENT_CLICKED = 'delete_document_clicked',
  SIGN_DOCUMENT_CLICKED = 'sign_document_clicked',

  DRAWER_DOWNLOAD_DOCUMENT_CLICKED = 'drawer_download_document_clicked',
  DRAWER_REQUEST_DOCUMENT_SIGNATURE_CLICKED = 'drawer_request_document_signature_clicked',
  DRAWER_DELETE_DOCUMENT_CLICKED = 'drawer_delete_document_clicked',
  DRAWER_SIGNATURE_WARNING_BUTTON_CLICKED = 'drawer_signature_warning_button_clicked',
  DRAWER_SIGN_DOCUMENT_BUTTON_CLICKED = 'drawer_sign_document_button_clicked',

  REQUEST_DOCUMENT_SIGNATURE_CONFIRMATION_MODAL_CTA_CLICKED = 'request_document_signature_confirmation_modal_cta_clicked',
  DOCUMENT_SIGNATURE_MODAL_CTA_CLICKED = 'document_signature_modal_cta_clicked',

  DOWNLOAD_ALL_EMPLOYEE_DOCUMENTS_BUTTON_CLICKED = 'download_all_employee_documents_button_clicked',

  ADD_DOCUMENT_REQUEST_SIGNATURE_SWITCH_ACTIVATED = 'add_document_request_signature_switch_activated',
  ADD_MULTIPLE_DOCUMENTS_REQUEST_SIGNATURE_SWITCH_ACTIVATED = 'add_multiple_documents_request_signature_switch_activated',

  RETRY_DOCUMENT_BUNDLE_DOWNLOAD_BUTTON_CLICKED = 'retry_document_bundle_download_button_clicked',
  DOWNLOAD_DOCUMENT_BUNDLE_BUTTON_CLICKED = 'download_document_bundle_button_clicked',
  HIDE_DOCUMENT_BUNDLE_MENU_BUTTON_CLICKED = 'hide_document_bundle_menu_button_clicked',

  INTERRUPT_MULTIPLE_DOCUMENT_UPLOAD_MODAL_CTA_CLICKED = 'interrupt_multiple_document_upload_modal_cta_clicked',
  RESET_MULTIPLE_DOCUMENT_FLOW_BUTTON_CLICKED = 'reset_multiple_document_flow_button_clicked',
  DOWNLOAD_MULTIPLE_DOCUMENT_UPLOAD_ERROR_FILE_BUTTON_CLICKED = 'download_multiple_document_upload_error_file_button_clicked',
}
