import {
  Button,
  Icons,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledContentContainer,
  StyledFooterContainer,
  StyledLinkButton,
} from './styled';
import { trpc } from '../../../../../../api/client';
import { showToast } from 'src/utils/showToast';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

type DeleteMultipleDocumentsConfirmationModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  selectedDocumentIds: string[];
};

export const DeleteMultipleDocumentsConfirmationModal = ({
  onClose,
  onSuccess,
  selectedDocumentIds,
}: DeleteMultipleDocumentsConfirmationModalProps) => {
  const theme = useTheme();
  const { trackEvent } = useEventTracking();
  const {
    mutate: deleteMultipleDocuments,
    isLoading: isDeleteMultipleDocumentsLoading,
  } = trpc.deleteMultipleDocuments.useMutation({
    onError: () => {
      showToast({
        type: 'error',
        title: 'Não foi possível excluir os documentos!',
        description:
          'Ocorreu um erro ao excluir os documentos. Por favor, tente novamente.',
      });
    },
    onSuccess: () => {
      showToast({
        type: 'success',
        title: 'Documentos excluídos com sucesso!',
      });
      onSuccess();
    },
  });

  return (
    <Modal.Root open={true} onClose={onClose}>
      <>
        <Modal.Content>
          <StyledContentContainer>
            <ShapeIcon
              style={{ marginBottom: theme.spacings.xs2 }}
              variant={'error'}
              size={72}
              color={theme.colors.feedback.error[40]}
              name={'IconAlertCircle'}
            />
            <Typography
              style={{ marginBottom: theme.spacings.xs5 }}
              variant="body3"
              weight={700}
              variantColor={theme.colors.feedback.error[40]}
            >
              Atenção!
            </Typography>
            <Typography
              style={{ marginBottom: theme.spacings.xs2 }}
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              Tem certeza que deseja excluir estes {selectedDocumentIds.length}{' '}
              documentos?
            </Typography>
            <Typography
              variant="body3"
              weight={400}
              variantColor={theme.colors.neutral[50]}
            >
              Esta ação afetará o cadastro desse pessoa em todos os módulos que
              este documento aparece. Você não poderá desfazer esta ação.
            </Typography>
          </StyledContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <StyledFooterContainer>
            <StyledLinkButton
              disabled={isDeleteMultipleDocumentsLoading}
              variant="neutral"
              onClick={onClose}
            >
              Cancelar
            </StyledLinkButton>
            <Button
              size="medium"
              variant="primary"
              variantType={'error'}
              loading={isDeleteMultipleDocumentsLoading}
              onClick={() => {
                trackEvent({
                  name: ActionTrackingEventEnum.TABLE_DELETE_SELECTED_DOCUMENTS_CONFIRMATION_MODAL_CTA_CLICKED,
                });
                deleteMultipleDocuments({
                  documentIds: selectedDocumentIds,
                });
              }}
            >
              Confirmar exclusão{' '}
              <Icons
                color={theme.colors.feedback.error[40]}
                name={'IconTrash'}
              />
            </Button>
          </StyledFooterContainer>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
