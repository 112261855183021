import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledTypographyText = styled(Typography)`
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const StyledTypographyButtonText = styled(Typography)`
  white-space: nowrap;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledIconContainer = styled.div`
  min-width: 16px;
`;
