import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import {
  ContentContainer,
  HeaderContainer,
  StyledButtonContainer,
  StyledContainer,
  StyledSpace,
  StyledTypography,
} from './styled';
import { useTheme } from 'styled-components';
import { DownloadInfoRow } from './components/DownloadInfoRow';
import { useMultiDocumentDownloadContext } from './context/MultiDocumentDownloadContext';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const MultiDocumentDownloadMenu = () => {
  const { isMultiDocumentDownloadMenuVisible, isMenuOpen, setIsMenuOpen } =
    useMultiDocumentDownloadContext();
  const theme = useTheme();
  const { trackEvent } = useEventTracking();

  if (!isMultiDocumentDownloadMenuVisible) return <></>;

  return (
    <StyledContainer style={{ marginBottom: isMenuOpen ? undefined : '-52px' }}>
      <HeaderContainer>
        <StyledTypography
          variant="headline9"
          variantColor={theme.colors.neutral[20]}
          weight={700}
        >
          Arquivos para download
        </StyledTypography>
        <StyledSpace />
        <StyledButtonContainer>
          <PillButton
            onClick={() =>
              setIsMenuOpen((prev) => {
                const value = !prev;
                if (!value) {
                  trackEvent({
                    name: ActionTrackingEventEnum.HIDE_DOCUMENT_BUNDLE_MENU_BUTTON_CLICKED,
                  });
                }
                return value;
              })
            }
            size="extra-small"
            variant="default"
            icon={isMenuOpen ? 'IconChevronDown' : 'IconChevronUp'}
          />
        </StyledButtonContainer>
      </HeaderContainer>
      <ContentContainer>
        <DownloadInfoRow />
      </ContentContainer>
    </StyledContainer>
  );
};
