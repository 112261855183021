import { useEffect, useRef, useState } from 'react';

type UsePaginationProps<T> = {
  items: T[];
  itemsPerPage: number;
};

export function usePagination<T>({
  items,
  itemsPerPage,
}: UsePaginationProps<T>) {
  const [visibleItems, setVisibleItems] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const startIndex = 0;
    const endIndex = Math.min((currentPage + 1) * itemsPerPage, items.length);
    const newVisibleItems = items.slice(startIndex, endIndex);
    setVisibleItems(newVisibleItems);
  }, [items, itemsPerPage, currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = listRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }
    };

    const divElement = listRef.current;
    divElement?.addEventListener('scroll', handleScroll);

    return () => {
      divElement?.removeEventListener('scroll', handleScroll);
    };
  }, [items, itemsPerPage]);

  return { visibleItems, listRef };
}
