import styled from 'styled-components';

export const StyledCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
