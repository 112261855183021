import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';
import { PageStructure } from '../../../PageStructure';
import { AsideContent } from './components/AsideContent';
import { FooterContent } from './components/FooterContent';
import { MainContent } from './components/MainContent';
import { CancelUploadConfirmationModal } from './components/CancelUploadConfirmationModal';

export const ProcessingStep = () => {
  const {
    isCancelUploadConfirmationModalVisible,
    setIsCancelUploadConfirmationModalVisible,
  } = useMultipleDocumentUploadContext();

  return (
    <>
      <PageStructure
        AsideContent={<AsideContent />}
        MainContent={<MainContent />}
        FooterContent={<FooterContent />}
      />

      {isCancelUploadConfirmationModalVisible && (
        <CancelUploadConfirmationModal
          onClose={() => setIsCancelUploadConfirmationModalVisible(false)}
        />
      )}
    </>
  );
};
