import { Tag } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledCellContainer } from './styled';

type TagListCellProps = {
  tagNames: string[];
};

export const TagListCell = ({ tagNames }: TagListCellProps) => (
  <StyledCellContainer>
    {tagNames.map((tagName) => (
      <Tag as="span" variant="primary">
        {tagName}
      </Tag>
    ))}
  </StyledCellContainer>
);
