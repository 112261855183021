import styled from 'styled-components';

export const StyledCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;
