import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledDropzoneInput,
  StyledDropzone,
  StyledDropzoneWrapper,
  StyledDropzoneIconWrapper,
  StyledDropzoneTextWrapper,
  StyledDropzoneSelectFilesWrapper,
  StyledFileListWrapper,
  StyledFileListTitleWrapper,
  StyledFileList,
  StyledErrorWrapper,
  StyledContainer,
} from './styled';
import { useTheme } from 'styled-components';
import FileItem from './components/FileItem';
import { useCallback, useMemo, useRef } from 'react';
import { usePagination } from 'src/hooks/usePagination';
import { showToast } from 'src/utils/showToast';

export type Extensions = 'jpg' | 'jpeg' | 'png' | 'gif' | 'bmp' | 'pdf' | 'doc';

export type FileType = {
  id: string;
  file: File;
  errorMessage?: string;
  successMessage?: string;
};

type DropzoneProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  accept: Extensions[];
  files: FileType[];
  onRemove: (id: string) => void;
  errorMessage?: string;
};

export const Dropzone = ({
  onChange,
  accept,
  files,
  onRemove,
  errorMessage,
}: DropzoneProps) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const { visibleItems, listRef } = usePagination({
    items: files,
    itemsPerPage: 10,
  });

  const acceptFiles = useMemo(
    () => accept.map((ext) => `.${ext}`).join(),
    [accept],
  );

  const handleOnRemove = useCallback(
    (id: string) => {
      if (inputRef?.current?.files) {
        inputRef.current.files = new DataTransfer().files;
      }
      onRemove(id);
    },
    [files],
  );

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!inputRef.current) return;

    const files = Array.from(event.dataTransfer.files);
    const acceptedExtensions = accept.map((ext) => `.${ext}`);

    const hasInvalidFile = files.some((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return (
        !fileExtension || !acceptedExtensions.includes(`.${fileExtension}`)
      );
    });

    if (hasInvalidFile) {
      showToast({
        type: 'warning',
        title: 'Detectamos tipos de arquivo inválidos.',
        description: `Pelo menos um dos arquivos tem um tipo inválido. Os tipos de arquivo aceitos são: ${acceptedExtensions.join(
          ', ',
        )}.`,
      });
    }

    const filteredFiles = files.filter((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return fileExtension && acceptedExtensions.includes(`.${fileExtension}`);
    });

    const dataTransfer = new DataTransfer();
    filteredFiles.forEach((file) => dataTransfer.items.add(file));

    inputRef.current.files = dataTransfer.files;

    const changeEvent = new Event('change', { bubbles: true });
    inputRef.current.dispatchEvent(changeEvent);
  };

  return (
    <StyledContainer>
      <StyledDropzoneWrapper
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        error={!!errorMessage}
      >
        <StyledDropzone htmlFor="file-upload" error={!!errorMessage}>
          <StyledDropzoneIconWrapper error={!!errorMessage}>
            <Icons
              name="IconPhotoPlus"
              size={24}
              color={
                !!errorMessage
                  ? theme.colors.feedback.error[90]
                  : theme.colors.secondary[50]
              }
            />
          </StyledDropzoneIconWrapper>
          <StyledDropzoneTextWrapper>
            <Typography
              variant="body3"
              weight={700}
              variantColor={
                !!errorMessage
                  ? theme.colors.feedback.error[40]
                  : theme.colors.primary
              }
            >
              Fotos dos documentos
            </Typography>
            <Typography
              variant="body3"
              weight={600}
              variantColor={theme.colors.neutral[60]}
            >
              Formatos permitidos: {accept.join(', ').toUpperCase()}.<br />{' '}
              Arquivos protegidos por senha ou criptografados não são
              permitidos.
            </Typography>
          </StyledDropzoneTextWrapper>
          <StyledDropzoneTextWrapper>
            <Typography
              variant="body3"
              weight={600}
              variantColor={theme.colors.neutral[50]}
            >
              Arraste e solte aqui, ou
            </Typography>
            <StyledDropzoneSelectFilesWrapper error={!!errorMessage}>
              <Icons
                name="IconFilePlus"
                size={24}
                color={
                  !!errorMessage
                    ? theme.colors.feedback.error[40]
                    : theme.colors.primary
                }
              />
              <Typography
                variant="body3"
                weight={700}
                variantColor={
                  !!errorMessage
                    ? theme.colors.feedback.error[40]
                    : theme.colors.primary
                }
              >
                Selecione arquivo(s)
              </Typography>
            </StyledDropzoneSelectFilesWrapper>
          </StyledDropzoneTextWrapper>
        </StyledDropzone>
        {files.length > 0 && (
          <StyledFileListWrapper>
            <StyledFileListTitleWrapper>
              <Icons
                name="IconFileUpload"
                size={24}
                color={theme.colors.primary}
              />
              <Typography
                variant="body3"
                weight={600}
                variantColor={theme.colors.neutral[30]}
              >
                {files.length} arquivos prontos para serem enviados
              </Typography>
            </StyledFileListTitleWrapper>
            <StyledFileList ref={listRef}>
              {visibleItems.map(({ file, id }) => (
                <FileItem
                  key={id}
                  file={file}
                  onRemove={() => handleOnRemove(id)}
                />
              ))}
            </StyledFileList>
          </StyledFileListWrapper>
        )}
        <StyledDropzoneInput
          type="file"
          id="file-upload"
          name="files"
          ref={inputRef}
          multiple
          accept={acceptFiles}
          onChange={onChange}
        />
      </StyledDropzoneWrapper>
      {!!errorMessage && (
        <StyledErrorWrapper>
          <Icons
            color={theme.colors.feedback.error[50]}
            name="IconAlertCircle"
            size={18}
          />
          <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
            {errorMessage}
          </Typography>
        </StyledErrorWrapper>
      )}
    </StyledContainer>
  );
};
