import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs4}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs4} ${({ theme }) => theme.spacings.xs4}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs2};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;
