import { Icons, Typography, dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledCellContainer, StyledDateContainer } from './styled';
import { useTheme } from 'styled-components';
import { ComponentProps } from 'react';
import { DocumentStatusEnum } from 'src/constants';
import { DocumentStatusTag } from 'src/components/DocumentStatusTag';

type DocumentStatusCellProps = {
  expirationDate?: string;
  signatureRequestedAt?: string;
  status: DocumentStatusEnum;
};

const documentStatusDateIconMap = {
  [DocumentStatusEnum.ACTIVE]: 'IconCalendarStats',
  [DocumentStatusEnum.EXPIRED]: 'IconCalendarOff',
  [DocumentStatusEnum.AWAITING_SIGNATURE]: 'IconCalendarTime',
};

type IconNames = ComponentProps<typeof Icons>['name'];

export const DocumentStatusCell = ({
  status,
  expirationDate,
  signatureRequestedAt,
}: DocumentStatusCellProps) => {
  const theme = useTheme();
  const isDateVisible =
    !!expirationDate &&
    (status === DocumentStatusEnum.ACTIVE || DocumentStatusEnum.EXPIRED);

  const getDocumentStatusDateText = () => {
    const formattedExpirationDate = dayjs(expirationDate).format('DD/MM/YYYY');
    const formattedSignatureRequestedAtDate =
      dayjs(signatureRequestedAt).format('DD/MM/YYYY');
    if (status === DocumentStatusEnum.EXPIRED)
      return `Venceu em ${formattedExpirationDate}`;
    if (status === DocumentStatusEnum.ACTIVE)
      return `Vence em ${formattedExpirationDate}`;
    if (status === DocumentStatusEnum.AWAITING_SIGNATURE)
      return `Solicitada em ${formattedSignatureRequestedAtDate}`;
  };

  return (
    <StyledCellContainer>
      <DocumentStatusTag status={status} />
      {isDateVisible && (
        <StyledDateContainer>
          <Icons
            name={documentStatusDateIconMap[status] as IconNames}
            color={theme.colors.neutral[70]}
            size={18}
          />
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral[40]}
            weight={400}
          >
            {getDocumentStatusDateText()}
          </Typography>
        </StyledDateContainer>
      )}
    </StyledCellContainer>
  );
};
