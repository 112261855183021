import { Toggle, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledContainer, StyledTextContainer } from './styled';
import { useTheme } from 'styled-components';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const RequestSignatureSwitch = () => {
  const theme = useTheme();
  const { documentInfo, setDocumentInfo } = useMultipleDocumentUploadContext();
  const { trackEvent } = useEventTracking();

  return (
    <StyledContainer>
      <Toggle
        checked={documentInfo.canBeSigned}
        onChange={() =>
          setDocumentInfo((prev) => {
            const value = !prev.canBeSigned;
            if (value) {
              trackEvent({
                name: ActionTrackingEventEnum.ADD_MULTIPLE_DOCUMENTS_REQUEST_SIGNATURE_SWITCH_ACTIVATED,
              });
            }
            return {
              ...prev,
              canBeSigned: value,
            };
          })
        }
      />
      <StyledTextContainer>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[30]}
          weight={600}
        >
          Solicitar assinatura de documentos PDF aos titulares
        </Typography>
        <Typography
          variant="body4"
          variantColor={theme.colors.neutral[50]}
          weight={400}
        >
          Habilite essa opção para enviar uma solicitação de assinatura de
          documentos PDF para os titulares do mesmo, assim que o fluxo de envio
          for finalizado.
        </Typography>
      </StyledTextContainer>
    </StyledContainer>
  );
};
