import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { useMemo } from 'react';

enum PermissionEnum {
  MANAGE_DOCUMENTS = 'core_manage_documents',
  SUPER_ADMIN = '*',
}

export const useIsAdmin = () => {
  const { companies } = usePermissions();
  const { selectedCompany } = useSelectedCompany();

  const isAdmin = useMemo(() => {
    const companyPermissions =
      companies.find(({ id }) => id === selectedCompany.id)?.permissions || [];

    return (
      companyPermissions.includes(PermissionEnum.SUPER_ADMIN) ||
      companyPermissions.includes(PermissionEnum.MANAGE_DOCUMENTS)
    );
  }, [companies, selectedCompany]);

  return isAdmin;
};
