import {
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StyledEmptyState, StyledLinkWrapper } from './styled';
import { useNavigate, useParams } from 'react-router-dom';
import { EmptyStateImage } from './components/EmptyStateImage';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const DocumentTableEmptyState = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const { trackEvent } = useEventTracking();
  return (
    <StyledEmptyState>
      <EmptyStateImage />
      <Typography variant="body3">
        Você ainda não criou nenhum documento
      </Typography>
      <StyledLinkWrapper>
        <LinkButton
          variant="default"
          onClick={() => {
            trackEvent({
              name: ActionTrackingEventEnum.EMPTY_STATE_ADD_DOCUMENT_BUTTON_CLICKED,
            });
            navigate(
              `/document-management/add-document${
                employeeId ? `?employeeId=${employeeId}` : ''
              }`,
            );
          }}
        >
          Criar novo documento <Icons name="Add" />
        </LinkButton>
      </StyledLinkWrapper>
    </StyledEmptyState>
  );
};
