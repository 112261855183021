import { TagFilter } from '@flash-tecnologia/hros-web-ui-v2';
import { trpc } from '../../../../../../../../../api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useTableQueryParams } from '../../../../../../../../../hooks/useTableQueryParams';
import { EmployeeTableQueryParams } from '../../..';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const EmployeeRoleFilter = () => {
  const { selectedCompany } = useSelectedCompany();
  const { tableQueryParams, setTableQueryParams } =
    useTableQueryParams<EmployeeTableQueryParams>();
  const { trackEvent } = useEventTracking();

  const selectedEmployeeRoleIds = tableQueryParams.employeeRoleIds?.split(',');

  const { data: companyRoles, isLoading: isGetAllCompanyRolesLoading } =
    trpc.getAllCompanyRoles.useQuery(
      { companyId: selectedCompany.id },
      { refetchOnWindowFocus: false, cacheTime: 0 },
    );

  return (
    <TagFilter
      variant="secondary"
      filterLabel="Cargo"
      hasLeftIcon={false}
      options={companyRoles?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))}
      onApply={(values) => {
        const value = values.length ? values.join(',') : undefined;
        if (value) {
          trackEvent({
            name: ActionTrackingEventEnum.TABLE_FILTER_USED,
            params: {
              filterName: 'Cargo',
              value,
            },
          });
        }
        setTableQueryParams({
          employeeRoleIds: value,
        });
      }}
      onClear={() => setTableQueryParams({ employeeRoleIds: undefined })}
      selectedOptions={selectedEmployeeRoleIds}
      badgeNumber={selectedEmployeeRoleIds?.length}
      loading={isGetAllCompanyRolesLoading}
      optionIconType="checkbox"
    />
  );
};
