import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';

export const Title = () => {
  const theme = useTheme();
  return (
    <Typography
      variant="headline7"
      variantColor={theme.colors.neutral[20]}
      weight={700}
    >
      Buscar documentos
    </Typography>
  );
};
