import {
  Icons,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import {
  StyledConfirmButton,
  StyledContentContainer,
  StyledFooterContainer,
  StyledLinkButton,
} from './styled';
import { useMultipleDocumentUploadContext } from 'src/pages/AddMultipleDocumentsPage/context/MultipleDocumentUploadContext';
import { useNavigate } from 'react-router-dom';
import { showToast } from 'src/utils/showToast';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

type CancelUploadConfirmationModalProps = {
  onClose: () => void;
};

export const CancelUploadConfirmationModal = ({
  onClose,
}: CancelUploadConfirmationModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { cancelUpload } = useMultipleDocumentUploadContext();
  const { trackEvent } = useEventTracking();

  return (
    <Modal.Root open={true} onClose={onClose}>
      <>
        <Modal.Content>
          <StyledContentContainer>
            <ShapeIcon
              style={{ marginBottom: theme.spacings.xs2 }}
              variant={'error'}
              size={72}
              color={theme.colors.feedback.error[40]}
              name={'IconAlertCircle'}
            />
            <Typography
              style={{ marginBottom: theme.spacings.xs5 }}
              variant="body3"
              weight={700}
              variantColor={theme.colors.feedback.error[40]}
            >
              Atenção!
            </Typography>
            <Typography
              style={{ marginBottom: theme.spacings.xs2 }}
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              Tem certeza que deseja interromper o envio dos documentos?
            </Typography>
            <Typography
              variant="body3"
              weight={400}
              variantColor={theme.colors.neutral[50]}
            >
              Se você interromper o envio, os documentos da fila que já foram
              enviados não serão excluídos e você deve excluí-los manualmente.
            </Typography>
          </StyledContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <StyledFooterContainer>
            <StyledLinkButton variant="neutral" onClick={onClose}>
              Cancelar
            </StyledLinkButton>
            <StyledConfirmButton
              size="medium"
              variant="primary"
              variantType={'error'}
              onClick={() => {
                trackEvent({
                  name: ActionTrackingEventEnum.INTERRUPT_MULTIPLE_DOCUMENT_UPLOAD_MODAL_CTA_CLICKED,
                });
                cancelUpload();
                showToast({
                  type: 'success',
                  title: 'Envio de documentos interrompido com sucesso',
                  description:
                    'Os documentos da fila que já foram enviados não serão excluídos e você deve excluí-los manualmente.',
                });
                navigate('/document-management');
              }}
            >
              Sim, interromper o envio{' '}
              <Icons
                color={theme.colors.feedback.error[40]}
                name={'IconHandStop'}
              />
            </StyledConfirmButton>
          </StyledFooterContainer>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
