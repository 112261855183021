import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TableViewEnum } from '../pages/DocumentsPage/constants';

type DefaultTableQueryParams = {
  tableView: TableViewEnum;
  company: string;
};

export function useTableQueryParams<T>() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );

  const setTableView = (tableView: TableViewEnum, queryParams?: T) => {
    setSearchParams(
      {
        company: params.company,
        tableView,
        ...(queryParams || {}),
      },
      { replace: true },
    );
  };

  const setTableQueryParams = (inputQueryParams: T) => {
    const updatedQueryParams = Object.entries({
      ...params,
      ...inputQueryParams,
    }).reduce(
      (acc, [fieldName, value]) => ({
        ...acc,
        ...(value ? { [fieldName]: value } : {}),
      }),
      {} as T & DefaultTableQueryParams,
    );
    setSearchParams(updatedQueryParams, { replace: true });
  };

  return {
    tableQueryParams: params as T & DefaultTableQueryParams,
    setTableQueryParams,
    setTableView,
  };
}
