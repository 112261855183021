import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;

export const StyledHorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border-top-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;

export const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledFileIconContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledExampleFileNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledInstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledInstructionsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
