import {
  ShapeIcon,
  Tag,
  Toggle,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StyledAccordion } from './styled';
import { useTheme } from 'styled-components';
import { useAddDocument } from 'src/pages/AddDocumentPage/context/add-document-form-context';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';
import { SignatureInputGroup } from 'src/components/SignatureInputGroup';
import { showToast } from 'src/utils/showToast';
import { useEffect } from 'react';

export const SignatureForm = () => {
  const { document, setField } = useAddDocument();
  const theme = useTheme();
  const { trackEvent } = useEventTracking();

  useEffect(() => {
    if (!document.employeeId && document.canBeSigned) {
      setField('canBeSigned', false);
      setField('additionalSignerEmployees', []);
    }
  }, [document.employeeId]);

  return (
    <StyledAccordion
      actions={[
        {
          children: (
            <Toggle
              checked={document?.canBeSigned}
              onChange={() => {
                const value = !document?.canBeSigned;
                if (value && !document.employeeId) {
                  showToast({
                    type: 'error',
                    title:
                      'Não é possivel solicitar a assinatura do documento!',
                    description:
                      'Por favor, selecione um titular para solicitar a assinatura do documento.',
                  });
                  return;
                }
                if (value) {
                  trackEvent({
                    name: ActionTrackingEventEnum.ADD_DOCUMENT_REQUEST_SIGNATURE_SWITCH_ACTIVATED,
                  });
                }
                if (!value && document.additionalSignerEmployees.length) {
                  setField('additionalSignerEmployees', []);
                }
                setField('canBeSigned', value);
              }}
            />
          ),
          onClick: () => {},
        },
      ]}
      expanded={document?.canBeSigned}
      variant="default"
      tag={
        <Tag as="span" size="small" variant="gray">
          Opcional
        </Tag>
      }
      leftIcon={
        <ShapeIcon
          name="IconSignature"
          size={42}
          stroke="default"
          style={{ flex: '1 0 auto' }}
          variant="default"
        />
      }
      title={
        <Typography
          variant="headline8"
          weight={700}
          variantColor={theme.colors.neutral[30]}
        >
          Solicitar assinatura para validação do documento
        </Typography>
      }
      description={
        <Typography
          variant="body4"
          weight={700}
          variantColor={theme.colors.neutral[50]}
        >
          Ative esta opção para enviar uma solicitação de assinatura em
          documentos PDF ao titular ou a outras pessoas cadastradas nas empresas
          do grupo. A solicitação de validação será enviada por e-mail assim que
          o fluxo de envio do documento for concluído.
        </Typography>
      }
    >
      {document.employeeId && (
        <SignatureInputGroup
          employeeId={document.employeeId}
          selectedAdditionalEmployees={document.additionalSignerEmployees}
          onSelectedAdditionalEmployeesChange={(
            updatedSelectedAdditionalEmployees,
          ) =>
            setField(
              'additionalSignerEmployees',
              updatedSelectedAdditionalEmployees,
            )
          }
        />
      )}
    </StyledAccordion>
  );
};
