import { Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledContainer } from './styled';
import { useTheme } from 'styled-components';
import { ReactNode } from 'react';

type LabeledFieldProps = {
  label: string;
  value: string | ReactNode;
  maxWidth?: number;
};

export const CaptionField = ({ label, value, maxWidth }: LabeledFieldProps) => {
  const theme = useTheme();
  return (
    <StyledContainer>
      <Typography
        variant="caption"
        variantColor={theme.colors.neutral[50]}
        weight={700}
        style={{ whiteSpace: 'nowrap' }}
      >
        {label}
      </Typography>
      {typeof value === 'string' ? (
        <Typography
          variant="body4"
          variantColor={theme.colors.neutral[40]}
          weight={600}
        >
          {maxWidth ? (
            <Tooltip title={value} arrow={false}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            value
          )}
        </Typography>
      ) : (
        value
      )}
    </StyledContainer>
  );
};
