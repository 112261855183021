import { uploadFileToS3 } from '@flash-tecnologia/hros-web-utility';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { trpc } from 'src/api/client';
import { showToast } from 'src/utils/showToast';
import { FileType } from 'src/components/Dropzone';
import BluebirdPromise from 'bluebird';

type UploadInputType = {
  employeeId: string;
  documentOwner: string;
  canBeSigned: boolean;
  documentTypeId: string;
  companyId: string;
  files: FileType[];
  additionalSignerEmployees: { id: string; label: string }[];
  expirationDate?: string;
  competenceDate?: string;
  description?: string;
};

export const useUploadDocument = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: createDocumentMutate } =
    trpc.createDocument.useMutation();
  const { mutateAsync: validateDocumentMutate } =
    trpc.validateDocument.useMutation();

  const [searchParams] = useSearchParams();
  const params = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );

  const documentManagementRoute = useMemo(() => {
    if (!!params.employeeId) {
      return `/document-management/employee/${params.employeeId}`;
    }
    return `/document-management?tableView=document`;
  }, [params.employeeId]);

  const upload = async (params: UploadInputType) => {
    setLoading(true);
    try {
      const { files, ...documentOptions } = params;

      await BluebirdPromise.map(files, async ({ file }) => {
        const fileName = file.name;

        const { url, documentId } = await createDocumentMutate({
          ...documentOptions,
          ...(documentOptions.canBeSigned && {
            signerEmployeeIds: [
              documentOptions.employeeId,
              ...documentOptions.additionalSignerEmployees.map(({ id }) => id),
            ],
          }),
          fileName,
        });

        await uploadFileToS3({
          file,
          url,
        });

        await validateDocumentMutate({ documentId });
      });

      navigate(documentManagementRoute);

      showToast({
        type: 'success',
        title: `${
          files.length > 1 ? 'Documentos carregados' : 'Documento carregado'
        } com sucesso!`,
      });
    } catch {
      showToast({
        type: 'error',
        title: 'Não foi possível carregar o documento!',
        description:
          'Ocorreu um erro ao carregar o documento. Por favor, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  };

  return { upload, loading };
};
