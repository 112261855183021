import { TagFilter } from '@flash-tecnologia/hros-web-ui-v2';
import { trpc } from '../../../../../../../../../api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useTableQueryParams } from '../../../../../../../../../hooks/useTableQueryParams';
import { EmployeeTableQueryParams } from '../../..';
import { useEventTracking } from 'src/hooks/useEventTracking';
import { ActionTrackingEventEnum } from 'src/constants';

export const EmployeeDepartmentFilter = () => {
  const { selectedCompany } = useSelectedCompany();
  const { tableQueryParams, setTableQueryParams } =
    useTableQueryParams<EmployeeTableQueryParams>();
  const { trackEvent } = useEventTracking();

  const selectedEmployeeDepartmentIds =
    tableQueryParams.employeeDepartmentIds?.split(',');

  const {
    data: companyDepartments,
    isLoading: isGetAllCompanyDepartmentsLoading,
  } = trpc.getAllCompanyDepartments.useQuery(
    { companyId: selectedCompany.id },
    { refetchOnWindowFocus: false, cacheTime: 0 },
  );

  return (
    <TagFilter
      variant="secondary"
      filterLabel="Departamento"
      hasLeftIcon={false}
      options={companyDepartments?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))}
      onApply={(values) => {
        const value = values.length ? values.join(',') : undefined;
        if (value) {
          trackEvent({
            name: ActionTrackingEventEnum.TABLE_FILTER_USED,
            params: {
              filterName: 'Departamento',
              value,
            },
          });
        }
        setTableQueryParams({
          employeeDepartmentIds: value,
        });
      }}
      onClear={() => setTableQueryParams({ employeeDepartmentIds: undefined })}
      selectedOptions={selectedEmployeeDepartmentIds}
      badgeNumber={selectedEmployeeDepartmentIds?.length}
      loading={isGetAllCompanyDepartmentsLoading}
      optionIconType="checkbox"
    />
  );
};
