import styled from 'styled-components';

export const StyledImg = styled.img`
  width: 100%;
  height: 350px;
  object-fit: contain;
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;
