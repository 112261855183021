import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  padding: 0 ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.s};
  padding-bottom: ${({ theme }) => theme.spacings.xl2};
`;

export const StyledTitleSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacings.m}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs};
`;
