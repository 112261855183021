import styled from 'styled-components';

export const StyledEmptyState = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  width: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs};
  align-items: center;
`;

export const StyledLinkWrapper = styled.div`
  align-self: center;
`;
