import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs3};
  max-height: 210px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: ${({ theme }) => theme.spacings.xs4};
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.neutral[90]};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.neutral[80]};
  }
`;

export const StyledFileRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.s};
  padding-right: ${({ theme }) => theme.spacings.s};
  height: 24px !important;
`;

export const StyledFileNameTypography = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
`;

export const StyledFileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  width: 33%;
`;

export const StyledProgressContainer = styled.div`
  display: flex;
  align-items: center;
  width: 33%;
  gap: ${({ theme }) => theme.spacings.xs3};
  div {
    width: 100%;
  }
  progress::-webkit-progress-value {
    transition: width 1s;
  }
`;

export const StyledProgressNumberTypography = styled(Typography)`
  min-width: 35px;
  width: 35px;
`;

export const StyledStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  width: 33%;
`;

export const StyledStatusTextTypography = styled(Typography)`
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
`;

export const StyledStatusIconShapeIcon = styled(ShapeIcon)<{ size: number }>`
  min-width: ${(p) => p.size}px;
  padding: 4px;
`;

export const StyledTextWrapper = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

export const StyledSpinnerWrapper = styled.div`
  div {
    width: 17px !important;
    height: 17px !important;
  }
`;

export const StyledFileImage = styled.img`
  border-radius: ${({ theme }) => theme.borders.radius.xs};
  width: 24px;
  height: 24px;
  object-fit: cover;
`;
