import {
  Button,
  GenericProfilePicture,
  Icons,
  LinkButton,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  ActionTrackingEventEnum,
  EmployeeStatusEnum,
} from '../../../../constants';
import {
  StyledEmployeeInfoContainer,
  StyledEmployeeNameAndStatusContainer,
  StyledEmployeeRoleContainer,
  StyledHeaderActionsContainer,
  StyledHeaderContainer,
  StyledHeaderContentContainerLeft,
  StyledHeaderContentContainerRight,
  StyledIconButton,
} from './styled';
import { EmployeeStatusTag } from '../../../../components/EmployeeStatusTag';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useMultiDocumentDownloadContext } from 'src/components/MultiDocumentDownloadMenu/context/MultiDocumentDownloadContext';
import { useEventTracking } from 'src/hooks/useEventTracking';

type EmployeeHeaderProps = {
  employeeId: string;
  employeeName: string;
  employeeStatus?: EmployeeStatusEnum;
  employeeRoleName?: string;
  employeeProfilePictureSignedUrl?: string;
};

export const EmployeeHeader = ({
  employeeId,
  employeeName,
  employeeStatus,
  employeeProfilePictureSignedUrl,
  employeeRoleName,
}: EmployeeHeaderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { createDocumentBundle, isDocumentBundleProcessing } =
    useMultiDocumentDownloadContext();
  const { trackPage } = useEventTracking();
  return (
    <StyledHeaderContainer>
      <StyledHeaderContentContainerLeft>
        <GenericProfilePicture
          name={employeeName}
          size={120}
          source={employeeProfilePictureSignedUrl}
        />
        <StyledEmployeeInfoContainer>
          <StyledEmployeeNameAndStatusContainer>
            <Typography
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              {employeeName}
            </Typography>
            {employeeStatus && (
              <EmployeeStatusTag
                hasLeftDotIcon={true}
                status={employeeStatus}
              />
            )}
          </StyledEmployeeNameAndStatusContainer>
          {employeeRoleName && (
            <StyledEmployeeRoleContainer>
              <Icons
                name="IconBriefcase"
                size={16}
                color={theme.colors.neutral[40]}
              />
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[40]}
                weight={600}
              >
                {employeeRoleName}
              </Typography>
            </StyledEmployeeRoleContainer>
          )}
          <LinkButton
            style={{ width: 'fit-content' }}
            variant="default"
            onClick={() => navigate(`/employees/${employeeId}/profile`)}
          >
            Ver perfil <Icons name="IconArrowRight" />
          </LinkButton>
        </StyledEmployeeInfoContainer>
      </StyledHeaderContentContainerLeft>

      <StyledHeaderContentContainerRight>
        <StyledHeaderActionsContainer>
          <Tooltip
            title={'Baixar todos os documentos desta pessoa'}
            arrow={true}
          >
            <div>
              <StyledIconButton
                size={'medium'}
                variant="line"
                icon="IconDownload"
                disabled={isDocumentBundleProcessing}
                onClick={() => {
                  trackPage({
                    name: ActionTrackingEventEnum.DOWNLOAD_ALL_EMPLOYEE_DOCUMENTS_BUTTON_CLICKED,
                  });
                  createDocumentBundle({ employeeId });
                }}
              />
            </div>
          </Tooltip>
          <Button
            variant="primary"
            size="large"
            onClick={() =>
              navigate(
                `/document-management/add-document?employeeId=${employeeId}`,
              )
            }
          >
            <Icons name="IconPlus" /> Adicionar documento
          </Button>
        </StyledHeaderActionsContainer>
      </StyledHeaderContentContainerRight>
    </StyledHeaderContainer>
  );
};
