import { useSearchParams } from 'react-router-dom';
import { TableViews } from './components/TableViews';
import { Title } from './components/Title';
import { StyledContainer } from './styled';
import { useMemo } from 'react';
import { TableViewEnum } from '../../constants';
import { DocumentTypeTable } from './components/DocumentTypeTable';
import { EmployeeTable } from './components/EmployeeTable';
import { DocumentTable } from 'src/components/DocumentTable';

export const Table = () => {
  const [searchParams] = useSearchParams();
  const params = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );
  const selectedTable = {
    [TableViewEnum.DOCUMENT_TYPE]: <DocumentTypeTable />,
    [TableViewEnum.DOCUMENT]: <DocumentTable />,
    [TableViewEnum.EMPLOYEE]: <EmployeeTable />,
  }[params.tableView as TableViewEnum];

  return (
    <StyledContainer>
      <Title />
      <TableViews />
      {selectedTable}
    </StyledContainer>
  );
};
